export default `Id,Price,Year,Mileage,City,State,Vin,Make,Model
1,16472,2015,18681,Jefferson City, MO,KL4CJBSBXFB267643,Buick,EncoreConvenience
2,15749,2015,27592,Highland, IN,KL4CJASB5FB245057,Buick,EncoreFWD
3,16998,2015,13650,Boone, NC,KL4CJCSB0FB264921,Buick,EncoreLeather
4,15777,2015,25195,New Orleans, LA,KL4CJASB4FB217542,Buick,EncoreFWD
5,16784,2015,22800,Las Vegas, NV,KL4CJBSB3FB166881,Buick,EncoreConvenience
6,17020,2016,16877,Grand Island, NY,KL4CJASB7GB536760,Buick,EncoreFWD
7,15950,2015,27885,West Covina, CA,KL4CJASB3FB241802,Buick,EncoreFWD
8,17091,2016,24008,Little Rock, AR,KL4CJASBXGB565542,Buick,EncoreFWD
9,16995,2015,8624,Punta Gorda, FL,KL4CJFSB7FB173565,Buick,EncoreConvenience
10,17700,2015,13807,Jacksonville, NC,KL4CJBSB8FB068543,Buick,EncoreConvenience
11,14555,2014,29535,Phoenix, AZ,KL4CJASB3EB786106,Buick,EncoreFWD
12,18988,2017,4705,Bowling Green, KY,KL4CJASB7HB035630,Buick,EncoreFWD
13,17491,2016,11123,Memphis, TN,KL4CJASBXGB720588,Buick,EncoreFWD
14,17432,2015,11330,St. Peters, MO,KL4CJBSB0FB117931,Buick,EncoreConvenience
15,20959,2017,36981,Taylor, MI,1G4PR5SK9H4108021,Buick,VeranoSport
16,16699,2015,21323,Clearwater, FL,KL4CJASB2FB127080,Buick,EncoreFWD
17,15995,2015,18887,Metairie, LA,KL4CJASB8FB267196,Buick,EncoreFWD
18,17950,2016,10925,Lafayette, IN,KL4CJASB4GB519690,Buick,EncoreFWD
19,15239,2015,20681,Dallas, TX,KL4CJBSB2FB179802,Buick,EncoreConvenience
20,16814,2015,11541,Gainesville, FL,KL4CJASB0FB248111,Buick,EncoreFWD
21,15972,2015,28408,Trevose, PA,KL4CJASB4FB176443,Buick,EncoreFWD
22,17222,2015,10652,Oklahoma City, OK,KL4CJBSB9FB270758,Buick,EncoreConvenience
23,18366,2016,3104,Lakewood, NJ,KL4CJASB3GB650982,Buick,EncoreFWD
24,13488,2014,33215,Fallston, MD,KL4CJASB9EB608748,Buick,EncoreFWD
25,16400,2015,21694,Columbus, OH,KL4CJASB1FB241331,Buick,EncoreFWD
26,18902,2017,5,Dublin, CA,KL4CJASB1HB027202,Buick,EncoreFWD
27,18902,2017,5,Dublin, CA,KL4CJASB5HB028496,Buick,EncoreFWD
28,16476,2015,21962,Flemming, GA,KL4CJBSB4FB231253,Buick,EncoreConvenience
29,16450,2014,12760,Columbus, OH,KL4CJASBXEB752616,Buick,EncoreFWD
30,16000,2015,19446,Monroe, MI,KL4CJBSB2FB036929,Buick,EncoreConvenience
31,16217,2015,23837,Raleigh, NC,KL4CJASB9FB235535,Buick,EncoreFWD
32,16895,2015,21026,Souderton, PA,KL4CJASB9FB190502,Buick,EncoreFWD
33,16900,2015,10369,Mechanicsburg, PA,KL4CJASB9FB270902,Buick,EncoreFWD
34,17500,2015,7166,Williamstown, NJ,KL4CJBSB9FB222354,Buick,EncoreConvenience
35,18988,2017,6111,Bowling Green, KY,KL4CJASB9HB048427,Buick,EncoreFWD
36,15600,2015,33423,Virginia Beach, Va,KL4CJASB4FB178676,Buick,EncoreFWD
37,17591,2015,10014,Tucson, AZ,KL4CJASB3FB117299,Buick,EncoreFWD
38,15995,2015,20496,McDonough, GA,KL4CJBSB2FB101567,Buick,EncoreConvenience
39,15593,2015,17440,Akron, OH,KL4CJESB7FB267195,Buick,EncoreAWD
40,17900,2017,9850,Doral, FL,KL4CJASB6HB002683,Buick,EncoreFWD
41,17000,2015,13590,Williamstown, NJ,KL4CJBSB0FB195254,Buick,EncoreConvenience
42,15495,2014,24554,Metairie, LA,KL4CJASB6EB551876,Buick,EncoreFWD
43,16988,2016,12510,Naples, FL,KL4CJASB6GB565795,Buick,EncoreFWD
44,15591,2015,29804,Jacksonville, FL,KL4CJASB0FB082415,Buick,EncoreFWD
45,16737,2015,16701,Lutz, Fl,KL4CJASB1FB220883,Buick,EncoreFWD
46,11262,2013,64997,Pompano Beach, FL,KL4CJASB9DB078277,Buick,EncoreEncore
47,13900,2014,25982,Arcadia, FL,KL4CJBSB6EB587119,Buick,EncoreConvenience
48,16799,2015,16735,Williamstown, NJ,KL4CJASB7FB234884,Buick,EncoreFWD
49,16500,2014,10478,Tampa, FL,KL4CJCSBXEB781688,Buick,EncoreLeather
50,17991,2016,14806,Tucson, AZ,KL4CJASB7GB620898,Buick,EncoreFWD
51,15499,2015,37406,Killeen, TX,KL4CJBSB5FB096686,Buick,EncoreConvenience
52,15725,2015,17005,Englewood, NJ,KL4CJASB4FB271181,Buick,EncoreFWD
53,19663,2016,3064,Wilmington, NC,KL4CJASB5GB647064,Buick,EncoreFWD
54,16445,2015,19469,Grapevine, TX,KL4CJBSBXFB166232,Buick,EncoreConvenience
55,15750,2015,26927,Grapevine, TX,KL4CJBSB6FB162873,Buick,EncoreConvenience
56,16567,2015,6156,Ocala, FL,KL4CJBSB2FB167861,Buick,EncoreConvenience
57,17479,2015,15572,San Diego, CA,KL4CJBSBXFB260336,Buick,EncoreConvenience
58,16344,2016,24241,GILBERT, AZ,KL4CJASB4GB652627,Buick,EncoreFWD
59,20500,2017,2381,Lillington, NC,KL4CJASB3HB073968,Buick,EncoreFWD
60,15795,2014,8886,White Plains, NY,KL4CJASBXEB571547,Buick,EncoreFWD
61,13500,2013,46157,Palm Harbor, FL,KL4CJBSB5DB160948,Buick,EncoreConvenience
62,18991,2017,5395,Charlotte, NC,KL4CJASB6HB002375,Buick,EncoreFWD
63,16984,2015,20433,Lone Tree, CO,KL4CJESB1FB265359,Buick,EncoreAWD
64,17300,2015,24075,Lillington, NC,KL4CJBSB3FB253521,Buick,EncoreConvenience
65,18676,2016,3448,Lutz, Fl,KL4CJBSB1GB719416,Buick,EncoreConvenience
66,16400,2015,19581,Fairless Hills, PA,KL4CJASB2FB158880,Buick,EncoreFWD
67,16947,2015,19091,Ellicott City, MD,KL4CJASB6FB125820,Buick,EncoreFWD
68,17950,2015,7895,Fresno, CA,KL4CJASB7FB266072,Buick,EncoreFWD
69,17500,2016,20157,Grapevine, TX,KL4CJBSB7GB529197,Buick,EncoreConvenience
70,14990,2015,33847,Kirkwood, MO,KL4CJASB8FB267554,Buick,EncoreFWD
71,15995,2015,24930,Grand Forks, ND,KL4CJBSB3FB265331,Buick,EncoreConvenience
72,15980,2014,16520,Rainbow City, AL,KL4CJBSB6EB746639,Buick,EncoreConvenience
73,3999,2006,132582,Joliet, IL,5GADX23L16D200063,Buick,Terraza4dr
74,16947,2015,22352,Brockton, MA,KL4CJESB1FB190453,Buick,EncoreAWD
75,17998,2016,11015,Greenville, NC,KL4CJASBXGB545422,Buick,EncoreFWD
76,13590,2014,35725,Plantation, FL,KL4CJASB3EB573589,Buick,EncoreFWD
77,14975,2014,25352,Seattle, WA,KL4CJDSB4EB723159,Buick,EncorePremium
78,15475,2016,40147,New Orleans, LA,KL4CJASB2GB577278,Buick,EncoreFWD
79,14901,2015,51091,Selma, TX,KL4CJBSB3FB254619,Buick,EncoreConvenience
80,17853,2016,13503,Tallahassee, FL,KL4CJASB3GB537873,Buick,EncoreFWD
81,18800,2016,8968,Kenosha, WI,KL4CJASB8GB602927,Buick,EncoreFWD
82,16995,2015,23174,Souderton, PA,KL4CJASB9FB029910,Buick,EncoreFWD
83,17991,2016,10059,Prince Frederick, MD,KL4CJASB5GB693316,Buick,EncoreFWD
84,15285,2014,17671,Boyertown, PA,KL4CJASB0EB785320,Buick,EncoreFWD
85,16495,2015,19495,Lynn, MA,KL4CJESB3FB233318,Buick,EncoreAWD
86,17853,2016,13625,Tallahassee, FL,KL4CJASB2GB539436,Buick,EncoreFWD
87,3950,2003,176935,Elkhart, IN,3G5DA03E93S607122,Buick,RendezvousCXL
88,17400,2016,9368,Siloam Springs, AR,KL4CJASB0GB667433,Buick,EncoreFWD
89,19327,2017,3339,Austin, TX,KL4CJASBXHB009295,Buick,EncoreFWD
90,16343,2016,30716,Grove City, OH,KL4CJASB0GB605580,Buick,EncoreFWD
91,15678,2015,19825,Noblesville, IN,KL4CJASB8FB237597,Buick,EncoreFWD
92,16875,2015,14682,Miami, FL,KL4CJBSB0FB249328,Buick,EncoreConvenience
93,16995,2015,26643,Marietta, GA,KL4CJCSB5FB098766,Buick,EncoreLeather
94,15000,2014,39648,Tarpon Springs, FL,KL4CJBSB4EB643994,Buick,EncoreConvenience
95,15994,2015,16067,Seffner, FL,KL4CJCSB2FB170538,Buick,EncoreLeather
96,14988,2014,29328,Oak Park Heights, MN,KL4CJBSB2EB738067,Buick,EncoreConvenience
97,16475,2015,15612,ORLANDO, FL,KL4CJASB9FB262847,Buick,EncoreFWD
98,16300,2015,25485,Williamstown, NJ,KL4CJASB9FB207136,Buick,EncoreFWD
99,14899,2014,31175,Red Springs, NC,KL4CJASB5EB742866,Buick,EncoreFWD
100,13977,2013,40722,Peoria, AZ,KL4CJBSB6DB121592,Buick,EncoreConvenience
101,20485,2017,5614,New Bern, NC,KL4CJASB2HB014748,Buick,EncoreFWD
102,15392,2015,39846,Madison, WI,KL4CJBSB7FB042726,Buick,EncoreConvenience
103,14484,2014,19263,Belle Glade, FL,KL4CJASB3EB548353,Buick,EncoreFWD
104,14901,2014,26624,Alachua, FL,KL4CJASB8EB544850,Buick,EncoreFWD
105,14376,2015,47383,Henderson, NV,KL4CJBSB0FB261477,Buick,EncoreConvenience
106,16992,2015,14754,Phoenix, AZ,KL4CJASB1FB155422,Buick,EncoreFWD
107,15555,2015,22058,Farmington, NY,KL4CJASB2FB269882,Buick,EncoreFWD
108,16468,2015,11219,Cincinnati, OH,KL4CJBSBXFB072173,Buick,EncoreConvenience
109,16533,2016,28871,Corbin, KY,KL4CJASB1GB696908,Buick,EncoreFWD
110,15595,2015,21745,Denton, TX,KL4CJFSB1FB219276,Buick,EncoreConvenience
111,17659,2016,16669,Lakewood, NJ,KL4CJASBXGB646248,Buick,EncoreFWD
112,16470,2015,20030,Orlando, FL,KL4CJASB9FB090528,Buick,EncoreFWD
113,15377,2015,34393,North Olmsted, OH,KL4CJASB1FB063386,Buick,EncoreFWD
114,17976,2016,17887,Aurora, MO,KL4CJASB5GB677794,Buick,EncoreFWD
115,17988,2015,1592,Fruitland Park, FL,KL4CJBSB3FB206862,Buick,EncoreConvenience
116,13991,2014,45308,Lutz, Fl,KL4CJBSB4EB662156,Buick,EncoreConvenience
117,16244,2015,22428,Anaheim, CA,KL4CJASB3FB266084,Buick,EncoreFWD
118,15699,2014,24333,New Orleans, LA,KL4CJBSB4EB760684,Buick,EncoreConvenience
119,16999,2015,12558,Port Richey, FL,KL4CJASB6FB267312,Buick,EncoreFWD
120,15599,2015,29851,Norfolk, VA,KL4CJASB8FB210237,Buick,EncoreFWD
121,16495,2015,18905,Metairie, LA,KL4CJASB3FB265968,Buick,EncoreFWD
122,15000,2014,30720,Wilmington, OH,KL4CJBSB9EB535807,Buick,EncoreConvenience
123,18988,2016,15851,Topeka, KS,KL4CJASB6GB724251,Buick,EncoreFWD
124,16991,2015,22450,Tucson, AZ,KL4CJASB7FB208107,Buick,EncoreFWD
125,16488,2015,21713,Zelienople, PA,KL4CJASB5FB253207,Buick,EncoreFWD
126,16230,2015,15010,Buford, GA,KL4CJASB4FB212826,Buick,EncoreFWD
127,14999,2015,38390,Downers Grove, IL,KL4CJBSB4FB153038,Buick,EncoreConvenience
128,15555,2014,26713,New Orleans, LA,KL4CJBSB7EB599442,Buick,EncoreConvenience
129,17466,2016,21450,St. Louis, MO,KL4CJASB1GB601795,Buick,EncoreFWD
130,12910,2014,49608,Oak Lawn, IL,KL4CJASB0EB639189,Buick,EncoreFWD
131,16200,2015,26981,Plainfield, IN,KL4CJBSB4FB147403,Buick,EncoreConvenience
132,16588,2015,21500,Red Springs, NC,KL4CJASB2FB216101,Buick,EncoreFWD
133,16791,2015,25389,Tucson, AZ,KL4CJASB8FB203305,Buick,EncoreFWD
134,17283,2015,13444,Houston, TX,KL4CJASB3FB261807,Buick,EncoreFWD
135,20300,2017,239,Hermitage, PA,KL4CJASB8HB164833,Buick,EncoreFWD
136,14308,2014,39994,Pataskala, OH,KL4CJBSB5EB715687,Buick,EncoreConvenience
137,22846,2017,27351,Delavan, WI,1G4PR5SK5H4110302,Buick,VeranoSport
138,18914,2016,8032,Carmel, IN,KL4CJASB7GB645770,Buick,EncoreFWD
139,16188,2015,19877,Fishers, IN,KL4CJASB2FB222447,Buick,EncoreFWD
140,17500,2015,19103,Cranston, RI,KL4CJESB5FB268619,Buick,EncoreAWD
141,14523,2014,44773,Indianapolis, IN,KL4CJBSB7EB731776,Buick,EncoreConvenience
142,15988,2015,24020,San Jose, CA,KL4CJASB4FB090212,Buick,EncoreFWD
143,17935,2015,14622,Sea Girt, NJ,KL4CJASBXFB137226,Buick,EncoreFWD
144,16890,2016,16702,Temple Hills, MD,KL4CJASB4GB680699,Buick,EncoreFWD
145,18500,2015,3612,Grapevine, TX,KL4CJBSB7FB169170,Buick,EncoreConvenience
146,15999,2015,27410,Conyers, GA,KL4CJASBXFB113489,Buick,EncoreFWD
147,24995,2014,5478,Elizabeth City, NC,1G4PR5SK9E4167159,Buick,VeranoConvenience
148,17512,2015,10728,Amarillo, TX,KL4CJESBXFB170833,Buick,EncoreAWD
149,18879,2017,23261,Las Vegas, NV,KL4CJCSB4HB008459,Buick,EncoreFWD
150,16500,2015,21072,Smyrna, GA,KL4CJASB3FB230489,Buick,EncoreFWD
151,18487,2016,7681,Gladstone, OR,KL4CJASB9GB736412,Buick,EncoreFWD
152,18995,2016,2815,Newnan, GA,KL4CJASB2GB734727,Buick,EncoreFWD
153,15473,2015,43366,Avon, IN,KL4CJASB5FB108216,Buick,EncoreFWD
154,16858,2015,23550,Scottsdale, AZ,KL4CJASB5FB245205,Buick,EncoreFWD
155,16995,2015,17116,Phoenix, AZ,KL4CJASB1FB184550,Buick,EncoreFWD
156,17000,2015,29353,Placerville, CA,KL4CJASB0FB256466,Buick,EncoreFWD
157,16996,2015,21920,Irwin, PA,KL4CJASB5FB230901,Buick,EncoreFWD
158,18000,2015,8235,Woonsocket, RI,KL4CJESB5FB228542,Buick,EncoreAWD
159,16493,2014,10117,Downers Grove, IL,KL4CJBSB0EB688737,Buick,EncoreConvenience
160,17990,2015,11558,Carmel, IN,KL4CJASB7FB249384,Buick,EncoreFWD
161,18000,2016,13807,Flagstaff, AZ,KL4CJASB8GB570853,Buick,EncoreFWD
162,18460,2016,6177,Matthews, NC,KL4CJASB3GB665286,Buick,EncoreFWD
163,18000,2015,7775,Birmingham, AL,KL4CJASBXFB186667,Buick,EncoreFWD
164,16241,2015,26479,Mission, TX,KL4CJASB3FB062031,Buick,EncoreFWD
165,17477,2015,11637,Ridgeland, MS,KL4CJASB6FB248890,Buick,EncoreFWD
166,15997,2015,29539,Phoenix, AZ,KL4CJBSBXFB164335,Buick,EncoreConvenience
167,16496,2015,29670,Aurora, MO,KL4CJASB7FB037133,Buick,EncoreFWD
168,16969,2015,16939,Houston, TX,KL4CJASB5FB258455,Buick,EncoreFWD
169,17977,2016,14693,Palm Coast, FL,KL4CJASB6GB546406,Buick,EncoreFWD
170,14675,2015,43174,Metairie, LA,KL4CJBSB1FB049722,Buick,EncoreConvenience
171,16988,2015,26718,Ft Walton Beach, FL,KL4CJCSB2FB169101,Buick,EncoreLeather
172,17691,2016,13366,Downers Grove, IL,KL4CJASB8GB645972,Buick,EncoreFWD
173,16995,2015,10635,Spencerport, NY,KL4CJASB6FB141693,Buick,EncoreFWD
174,21995,2015,36912,Albuquerque, NM,1G4PR5SK3F4152402,Buick,VeranoConvenience
175,13490,2014,41678,Ft. Lauderdale, FL,KL4CJASB4EB780685,Buick,EncoreFWD
176,17274,2015,14579,Branson, MO,KL4CJBSB8FB194529,Buick,EncoreConvenience
177,17904,2015,7012,Amarillo, TX,KL4CJASB6FB096044,Buick,EncoreFWD
178,18901,2016,6634,St Peters, MO,KL4CJASB7GB718801,Buick,EncoreFWD
179,17500,2015,11972,Maryville, MO,KL4CJASB4FB139098,Buick,EncoreFWD
180,18300,2015,11941,Clarksville, TN,KL4CJASB5FB270363,Buick,EncoreFWD
181,18640,2016,1737,Homosassa, FL,KL4CJASB4GB701227,Buick,EncoreFWD
182,17649,2015,17524,Lillington, NC,KL4CJASB0FB240476,Buick,EncoreFWD
183,16987,2015,18936,Houston, TX,KL4CJASB1FB193233,Buick,EncoreFWD
184,4977,2003,175975,Houston, TX,3G5DA03E83S521865,Buick,RendezvousCX
185,14500,2015,38441,Charlotte, NC,KL4CJFSB2FB046478,Buick,EncoreConvenience
186,18588,2016,7781,Tyler, TX,KL4CJASB5GB675804,Buick,EncoreFWD
187,17991,2016,15184,Bradenton, FL,KL4CJASB4GB557839,Buick,EncoreFWD
188,17404,2015,17504,Jefferson City, MO,KL4CJESB2FB098431,Buick,EncoreAWD
189,18499,2017,7655,Atlanta, GA,KL4CJCSB6HB007491,Buick,EncoreFWD
190,16995,2015,27385,Austin, TX,KL4CJBSB1FB216161,Buick,EncoreConvenience
191,17785,2015,17453,Oswego, NY,KL4CJESB0FB201197,Buick,EncoreAWD
192,18684,2017,15298,Corbin, KY,KL4CJASB9HB035306,Buick,EncoreFWD
193,16950,2015,9172,Duluth, GA,KL4CJASB1FB237408,Buick,EncoreFWD
194,16500,2014,29404,Duluth, GA,KL4CJCSB7EB565295,Buick,EncoreLeather
195,15991,2016,43864,St. Louis, MO,KL4CJASB6GB675603,Buick,EncoreFWD
196,17494,2016,21192,Newberry, SC,KL4CJBSB9GB540850,Buick,EncoreConvenience
197,14992,2014,36760,Allentown, PA,KL4CJBSB3EB782417,Buick,EncoreConvenience
198,13687,2014,49851,Florissant, MO,KL4CJASB8EB723017,Buick,EncoreFWD
199,16991,2015,22787,Houston, TX,KL4CJBSB4FB154819,Buick,EncoreConvenience
200,15997,2015,22686,Cincinnati, OH,KL4CJBSB1FB263139,Buick,EncoreConvenience
201,16000,2014,26810,Delaware, OH,KL4CJFSB8EB533201,Buick,EncoreConvenience
202,16000,2014,15771,Towanda, PA,KL4CJFSB8EB554923,Buick,EncoreConvenience
203,17880,2016,17238,Red Springs, NC,KL4CJASB3GB552728,Buick,EncoreFWD
204,17384,2015,16851,Syracuse, NY,KL4CJESB3FB219077,Buick,EncoreAWD
205,16711,2015,16895,Chicago, IL,KL4CJASB8FB258353,Buick,EncoreFWD
206,16980,2015,19353,Hartsville, SC,KL4CJBSB1FB211445,Buick,EncoreConvenience
207,18000,2016,15396,Flagstaff, AZ,KL4CJASB1GB563131,Buick,EncoreFWD
208,16294,2015,21230,Fishers, IN,KL4CJASB7FB121775,Buick,EncoreFWD
209,19900,2016,5,Simi Valley, CA,KL4CJBSB4GB663679,Buick,EncoreConvenience
210,17676,2015,10795,Ridgeland, MS,KL4CJASBXFB241019,Buick,EncoreFWD
211,17853,2016,20050,Anderson, SC,KL4CJASB1GB534695,Buick,EncoreFWD
212,18495,2016,6481,Collinsville, IL,KL4CJBSB9GB620388,Buick,EncoreConvenience
213,15940,2014,22334,Springfield, IL,KL4CJBSB6EB775705,Buick,EncoreConvenience
214,16525,2015,20325,East Dundee, IL,KL4CJASB5FB243230,Buick,EncoreFWD
215,17500,2016,21000,BROKEN ARROW, OK,KL4CJASB2GB643053,Buick,EncoreFWD
216,14980,2015,43461,Muskogee, OK,KL4CJASB5FB194479,Buick,EncoreFWD
217,17200,2015,23389,Hermitage, PA,KL4CJESB8FB249756,Buick,EncoreAWD
218,15990,2014,25889,Palmyra, NJ,KL4CJBSB6EB785599,Buick,EncoreConvenience
219,17000,2015,23977,Columbus, OH,KL4CJBSB7FB102293,Buick,EncoreConvenience
220,18990,2017,2608,Canton, MA,KL4CJASB8HB074470,Buick,EncoreFWD
221,15000,2015,33508,Baltimore, MD,KL4CJASB7FB124627,Buick,EncoreFWD
222,15495,2014,24349,St. Augustine, FL,KL4CJBSB4EB658656,Buick,EncoreConvenience
223,16995,2015,19384,Conway, SC,KL4CJASB5FB225911,Buick,EncoreFWD
224,17499,2015,13317,Bedford, OH,KL4CJBSB9FB154525,Buick,EncoreConvenience
225,14416,2014,53116,Del City, OK,KL4CJCSB6EB775743,Buick,EncoreLeather
226,19000,2016,3879,Lexington, KY,KL4CJBSB9GB708227,Buick,EncoreConvenience
227,13324,2014,68719,Redlands, CA,KL4CJASBXEB649275,Buick,EncoreFWD
228,15000,2014,35813,Plymouth, WI,KL4CJBSB5EB727712,Buick,EncoreConvenience
229,18997,2016,6871,Jonesboro, AR,KL4CJASB2GB528291,Buick,EncoreFWD
230,19842,2017,5280,Anderson, SC,KL4CJASB1HB013736,Buick,EncoreFWD
231,16901,2015,20739,Yuba City, CA,KL4CJASB3FB126763,Buick,EncoreFWD
232,15469,2015,43153,Albuquerque, NM,KL4CJBSB0FB073803,Buick,EncoreConvenience
233,16998,2015,20357,Houston, TX,KL4CJASB3FB237619,Buick,EncoreFWD
234,15713,2014,30674,Parma, OH,KL4CJBSB4EB718063,Buick,EncoreConvenience
235,16996,2015,17856,Pueblo, CO,KL4CJASBXFB057862,Buick,EncoreFWD
236,17630,2015,15619,Newport News, VA,KL4CJBSB3FB252823,Buick,EncoreConvenience
237,16998,2015,19821,Shelby, NC,KL4CJASB9FB269930,Buick,EncoreFWD
238,18684,2016,8120,Tampa, FL,KL4CJASB3GB742626,Buick,EncoreFWD
239,19849,2016,2645,Clearwater, FL,KL4CJBSB8GB718053,Buick,EncoreConvenience
240,17371,2016,24000,Edinburg, TX,KL4CJASBXGB566982,Buick,EncoreFWD
241,15300,2014,25602,Fishers, IN,KL4CJASB4EB744642,Buick,EncoreFWD
242,16995,2015,10133,Wall, NJ,KL4CJESB4FB218391,Buick,EncoreAWD
243,15500,2014,28727,Athens, AL,KL4CJASB3EB592353,Buick,EncoreFWD
244,17251,2015,13894,Houston, TX,KL4CJASB5FB211720,Buick,EncoreFWD
245,15990,2015,33212,Gurnee, IL,KL4CJESB7FB167713,Buick,EncoreAWD
246,17499,2015,16493,Johnston, RI,KL4CJFSB2FB140554,Buick,EncoreConvenience
247,15985,2015,32219,Excelsior Springs, MO,KL4CJASB9FB152784,Buick,EncoreFWD
248,17499,2015,19978,El Paso, TX,KL4CJASB5FB267897,Buick,EncoreFWD
249,17500,2015,18067,Taylor, MI,KL4CJBSB6FB219475,Buick,EncoreConvenience
250,17000,2015,21475,Plainfield, IN,KL4CJBSB2FB255583,Buick,EncoreConvenience
251,17994,2015,12296,Mesa, AZ,KL4CJBSB2FB238749,Buick,EncoreConvenience
252,16288,2015,33761,Delray Beach, FL,KL4CJBSB9FB047717,Buick,EncoreConvenience
253,18000,2016,16760,Edinburg, TX,KL4CJASB4GB618915,Buick,EncoreFWD
254,16634,2015,24636,Fort Lauderdale, FL,KL4CJASB7FB225358,Buick,EncoreFWD
255,17500,2015,14209,Lees Summit, MO,KL4CJASB4FB195154,Buick,EncoreFWD
256,16640,2015,23750,Lakeland, FL,KL4CJASB7FB234934,Buick,EncoreFWD
257,13999,2015,53359,Jacksonville, FL,KL4CJBSB3FB040262,Buick,EncoreConvenience
258,15895,2014,23091,Old Bridge, NJ,KL4CJCSB0EB740891,Buick,EncoreLeather
259,20000,2017,1406,Circleville, OH,KL4CJASB5HB082588,Buick,EncoreFWD
260,16484,2014,21483,Florence, KY,KL4CJCSB1EB625748,Buick,EncoreLeather
261,17900,2015,9529,Bradley, IL,KL4CJASB5FB234883,Buick,EncoreFWD
262,15765,2013,37771,Omaha, NE,KL4CJGSB0DB169618,Buick,EncoreLeather
263,18580,2015,11125,PLANO, TX,KL4CJASB6FB232351,Buick,EncoreFWD
264,17492,2015,18501,The Woodlands, TX,KL4CJASB2FB230600,Buick,EncoreFWD
265,16871,2015,17862,GILBERT, AZ,KL4CJASB9FB265263,Buick,EncoreFWD
266,15499,2015,45435,Des Moines, IA,KL4CJBSB9FB040010,Buick,EncoreConvenience
267,14450,2015,58501,Somersworth, NH,KL4CJESB2FB087705,Buick,EncoreAWD
268,18000,2016,13317,Charlotte, MI,KL4CJASB8GB595378,Buick,EncoreFWD
269,14655,2014,47994,WINCHESTER, VA,KL4CJBSB2EB730387,Buick,EncoreConvenience
270,18426,2015,9004,Cockeysville, MD,KL4CJESB0FB141132,Buick,EncoreAWD
271,18887,2016,14366,Dawsonville, GA,KL4CJASB4GB516028,Buick,EncoreFWD
272,18000,2016,22321,Grapevine, TX,KL4CJBSBXGB523457,Buick,EncoreConvenience
273,18500,2016,11341,Chicago, IL,KL4CJASB8GB608274,Buick,EncoreFWD
274,19430,2017,5979,Mesa, AZ,KL4CJASB3HB019697,Buick,EncoreFWD
275,16790,2015,13520,Roswell, GA,KL4CJESB4FB230900,Buick,EncoreAWD
276,18524,2016,11100,Hamler, OH,KL4CJASB0GB671000,Buick,EncoreFWD
277,18850,2015,2571,Taylor, MI,KL4CJASB8FB246557,Buick,EncoreFWD
278,17129,2015,19344,Saint Joseph, MO,KL4CJASBXFB140398,Buick,EncoreFWD
279,18893,2016,10873,Blair, NE,KL4CJASB2GB750572,Buick,EncoreFWD
280,18878,2016,5339,Lima, OH,KL4CJASB8GB626337,Buick,EncoreFWD
281,16473,2014,17809,Mechanicsburg, PA,KL4CJASB4EB770318,Buick,EncoreFWD
282,15989,2014,24613,Rainbow City, AL,KL4CJBSB5EB608414,Buick,EncoreConvenience
283,14900,2015,43214,Enterprise, AL,KL4CJASB9FB152025,Buick,EncoreFWD
284,19991,2017,2243,Prince Frederick, MD,KL4CJASB2HB083021,Buick,EncoreFWD
285,16250,2015,20306,Louisville, KY,KL4CJASB0FB153998,Buick,EncoreFWD
286,13585,2014,48694,Deerfield Beach, FL,KL4CJFSB4EB748963,Buick,EncoreConvenience
287,18411,2016,12756,Fort Lauderdale, FL,KL4CJASB4GB542161,Buick,EncoreFWD
288,16490,2015,17472,Duluth, GA,KL4CJASB3FB240536,Buick,EncoreFWD
289,16500,2015,17360,Phoenix, AZ,KL4CJASB8FB141663,Buick,EncoreFWD
290,14273,2016,55845,Russellville, KY,KL4CJASB5GB612475,Buick,EncoreFWD
291,15860,2014,26623,Daphne, AL,KL4CJASB7EB672738,Buick,EncoreFWD
292,17000,2015,16125,Rome, GA,KL4CJBSB8FB167587,Buick,EncoreConvenience
293,16000,2015,29061,Melbourne, FL,KL4CJASB7FB051291,Buick,EncoreFWD
294,15499,2015,29539,Chicago, IL,KL4CJFSB3FB216542,Buick,EncoreConvenience
295,18410,2016,10905,Coral Springs, FL,KL4CJASB1GB659275,Buick,EncoreFWD
296,16809,2014,23037,Gaithersburg, MD,KL4CJGSB4EB780978,Buick,EncoreLeather
297,14984,2014,37209,Alcoa, TN,KL4CJASB8EB727150,Buick,EncoreFWD
298,14749,2014,40049,Knoxville, TN,KL4CJFSB9EB693376,Buick,EncoreConvenience
299,3987,2007,150046,Sterling, IL,5GADV231X7D205011,Buick,Terraza4dr
300,16877,2015,30498,Paso Robles, CA,KL4CJASB3FB155325,Buick,EncoreFWD
301,16995,2014,22869,Colmar, PA,KL4CJFSB8EB775891,Buick,EncoreConvenience
302,19488,2017,8894,Tyler, TX,KL4CJASB7HB020058,Buick,EncoreFWD
303,17998,2015,13077,Newport News, VA,KL4CJASB5FB141605,Buick,EncoreFWD
304,15750,2015,26925,Louisville, KY,KL4CJASB7FB245609,Buick,EncoreFWD
305,16250,2015,20913,Louisville, KY,KL4CJASBXFB196020,Buick,EncoreFWD
306,17723,2015,16428,Newport News, VA,KL4CJASB0FB166783,Buick,EncoreFWD
307,14500,2014,33420,Charlotte, NC,KL4CJBSB1EB613982,Buick,EncoreConvenience
308,17990,2015,6848,Houston, TX,KL4CJASB5FB248329,Buick,EncoreFWD
309,19250,2015,8103,Junction City, KS,KL4CJCSB1FB178677,Buick,EncoreLeather
310,15800,2014,27707,Jackson, MI,KL4CJASB7EB780194,Buick,EncoreFWD
311,4500,2003,175813,Casselberry, FL,3G5DA03E63S579313,Buick,RendezvousCX
312,15491,2015,36951,Union City, GA,KL4CJASB2FB223064,Buick,EncoreFWD
313,16987,2015,22114,Dry Ridge, KY,KL4CJESB0FB164264,Buick,EncoreAWD
314,14618,2014,28071,Labelle, FL,KL4CJASB2EB739097,Buick,EncoreFWD
315,17995,2015,14819,Egg Harbor, NJ,KL4CJASB5FB205027,Buick,EncoreFWD
316,13674,2014,53537,Martin, TN,KL4CJASBXEB604496,Buick,EncoreFWD
317,16750,2015,15346,Louisville, KY,KL4CJASB7FB271935,Buick,EncoreFWD
318,19220,2016,4110,Edinburg, TX,KL4CJASB2GB724831,Buick,EncoreFWD
319,14725,2014,31231,Hyattsville, MD,KL4CJASB8EB543620,Buick,EncoreFWD
320,16495,2014,28457,San Antonio, TX,KL4CJBSB0EB678998,Buick,EncoreConvenience
321,16000,2015,42603,Chantilly, VA,KL4CJCSB7FB033840,Buick,EncoreLeather
322,14999,2014,27971,Schaumburg, IL,KL4CJBSB0EB647881,Buick,EncoreConvenience
323,14988,2014,37595,Morton, IL,KL4CJASB0EB591256,Buick,EncoreFWD
324,15730,2015,27787,Buford, GA,KL4CJASB6FB262899,Buick,EncoreFWD
325,15900,2014,26112,Ravenna, OH,KL4CJBSB7EB658540,Buick,EncoreConvenience
326,15991,2014,22312,West Palm Beach, FL,KL4CJBSB9EB599118,Buick,EncoreConvenience
327,3500,2006,145732,Logan, OH,5GADX33L26D145127,Buick,Terraza4dr
328,16739,2014,22760,Lebanon, NH,KL4CJESB7EB612109,Buick,EncoreAWD
329,17091,2015,21325,Purcell, OK,KL4CJBSB4FB209396,Buick,EncoreConvenience
330,18980,2015,17340,Fort Collins, CO,KL4CJESB4FB266067,Buick,EncoreAWD
331,17500,2015,16540,Bridgeton, NJ,KL4CJASB8FB184044,Buick,EncoreFWD
332,16855,2016,34926,Tallahassee, FL,KL4CJASB6GB693745,Buick,EncoreFWD
333,14500,2014,44237,Nicholasville, KY,KL4CJESB8EB527246,Buick,EncoreAWD
334,16541,2015,30564,Antioch, IL,KL4CJFSB1FB218841,Buick,EncoreConvenience
335,16698,2014,21249,Gaithersburg, MD,KL4CJBSB9EB594369,Buick,EncoreConvenience
336,16994,2016,21665,San Antonio, TX,KL4CJASB9GB564480,Buick,EncoreFWD
337,17697,2016,13211,Tallahassee, FL,KL4CJASB2GB537587,Buick,EncoreFWD
338,15950,2015,37483,Clanton, AL,KL4CJBSB0FB187025,Buick,EncoreConvenience
339,18274,2016,18290,Memphis, TN,KL4CJASB0GB711642,Buick,EncoreFWD
340,17488,2015,16998,Zelienople, PA,KL4CJESB7FB132797,Buick,EncoreAWD
341,18395,2015,6090,BLUFFTON, SC,KL4CJBSB1FB151604,Buick,EncoreConvenience
342,17777,2015,17637,Richmond, VA,KL4CJBSB0FB139704,Buick,EncoreConvenience
343,15997,2014,22902,Adrian, MI,KL4CJASBXEB778620,Buick,EncoreFWD
344,17000,2015,13096,Louisville, KY,KL4CJASB2FB188610,Buick,EncoreFWD
345,16400,2014,21546,Rhinelander, WI,KL4CJESB0EB605910,Buick,EncoreAWD
346,16996,2016,21747,West Seneca, NY,KL4CJASB8GB578967,Buick,EncoreFWD
347,15940,2015,25871,Newnan, GA,KL4CJBSB3FB190890,Buick,EncoreConvenience
348,10900,2014,78000,Austin, TX,KL4CJASB2EB555245,Buick,EncoreFWD
349,12888,2014,83550,Clarksville, TN,KL4CJBSB0EB647539,Buick,EncoreConvenience
350,15713,2014,31267,Henderson, NV,KL4CJBSB4EB684321,Buick,EncoreConvenience
351,16440,2015,44219,Mequon, WI,KL4CJBSB0FB117606,Buick,EncoreConvenience
352,18498,2016,19302,Dallas, GA,KL4CJBSBXGB557365,Buick,EncoreConvenience
353,17820,2016,11991,Moreno Valley, CA,KL4CJASB9GB669813,Buick,EncoreFWD
354,16897,2015,14536,Chattanooga, TN,KL4CJASB6FB206798,Buick,EncoreFWD
355,18990,2016,17864,Jacksonville, FL,KL4CJ1SM1GB612877,Buick,EncoreSport
356,18598,2016,14362,Tallahassee, FL,KL4CJASB9GB660920,Buick,EncoreFWD
357,17888,2015,27578,Fairfax, VA,KL4CJESB0FB216654,Buick,EncoreAWD
358,14900,2014,26112,Chicago, IL,KL4CJBSB5EB722011,Buick,EncoreConvenience
359,17497,2015,7440,Allentown, PA,KL4CJASB4FB227925,Buick,EncoreFWD
360,5993,2005,115374,Easton, PA,5GADV23L15D242706,Buick,Terraza4dr
361,17999,2016,19951,Hendersonville, NC,KL4CJASBXGB692985,Buick,EncoreFWD
362,17793,2015,19987,Whitesboro, TX,KL4CJCSB6FB211849,Buick,EncoreLeather
363,16897,2015,14865,Tulsa, OK,KL4CJASB9FB227290,Buick,EncoreFWD
364,15230,2015,31101,Philadelphia, PA,KL4CJBSB5FB178191,Buick,EncoreConvenience
365,13950,2014,41887,Ephrata, PA,KL4CJASB0EB784197,Buick,EncoreFWD
366,17880,2015,12999,Brooksville, FL,KL4CJASB4FB032181,Buick,EncoreFWD
367,16798,2015,22012,Avondale, AZ,KL4CJASB7FB268937,Buick,EncoreFWD
368,18000,2016,10389,Louisville, KY,KL4CJASB4GB576133,Buick,EncoreFWD
369,17197,2015,11528,Tampa, FL,KL4CJASB3FB216530,Buick,EncoreFWD
370,14770,2014,48390,Jacksonville, Fl,KL4CJASB5EB612313,Buick,EncoreFWD
371,19852,2017,8021,Henderson, NV,KL4CJASB6HB047008,Buick,EncoreFWD
372,14000,2014,41505,Seattle, WA,KL4CJASB1EB741746,Buick,EncoreFWD
373,15690,2015,40813,Belleville, IL,KL4CJASB6FB207207,Buick,EncoreFWD
374,16977,2015,19537,Dunn, NC,KL4CJASB2FB271213,Buick,EncoreFWD
375,18973,2016,7840,Mechanicsburg, PA,KL4CJASB2GB672486,Buick,EncoreFWD
376,17197,2015,11736,Miami, FL,KL4CJASB4FB251173,Buick,EncoreFWD
377,14997,2014,35983,Adrian, MI,KL4CJASB7EB766439,Buick,EncoreFWD
378,18900,2016,9669,Batesburg-Leesville, SC,KL4CJASB7GB550173,Buick,EncoreFWD
379,16497,2015,20196,Savannah, GA,KL4CJASB7FB188411,Buick,EncoreFWD
380,17999,2015,10588,Conyers, GA,KL4CJASB4FB267471,Buick,EncoreFWD
381,16700,2015,28577,Fairborn, OH,KL4CJBSB9FB046776,Buick,EncoreConvenience
382,17900,2016,10889,New Orleans, LA,KL4CJASB4GB530186,Buick,EncoreFWD
383,13800,2014,67496,Jacksonville, NC,KL4CJASB7EB649928,Buick,EncoreFWD
384,16698,2014,19064,Elmhurst, IL,KL4CJASB2EB762525,Buick,EncoreFWD
385,16500,2015,20239,Louisville, KY,KL4CJASB0FB265717,Buick,EncoreFWD
386,16801,2016,35063,State College, PA,KL4CJESB7GB582726,Buick,EncoreAWD
387,16500,2015,20279,Louisville, KY,KL4CJASB5FB266295,Buick,EncoreFWD
388,18780,2016,16479,Mcgregor, TX,KL4CJASB1GB545003,Buick,EncoreFWD
389,17984,2016,23537,Syracuse, NY,KL4CJESB6GB539236,Buick,EncoreAWD
390,16992,2015,15108,West Bend, WI,KL4CJASB2FB128276,Buick,EncoreFWD
391,18991,2016,13412,St. Louis, MO,KL4CJASB7GB610579,Buick,EncoreFWD
392,14874,2014,31861,Fort Worth, TX,KL4CJBSBXEB629288,Buick,EncoreConvenience
393,14990,2014,37903,Catonsville, MD,KL4CJFSB4EB663685,Buick,EncoreConvenience
394,14998,2014,29896,Oakfield, NY,KL4CJCSB4EB622326,Buick,EncoreLeather
395,17986,2015,20881,Clearwater, FL,KL4CJCSB9FB141585,Buick,EncoreLeather
396,17940,2016,16368,Homosassa, FL,KL4CJASB7GB616463,Buick,EncoreFWD
397,17000,2014,15447,Indianapolis, IN,KL4CJASB1EB690829,Buick,EncoreFWD
398,16820,2015,26418,Elmhurst, IL,KL4CJASB9FB044858,Buick,EncoreFWD
399,17277,2014,19555,Decatur, TX,KL4CJASB4EB782193,Buick,EncoreFWD
400,14995,2015,45350,Collinsville, IL,KL4CJBSB0FB173982,Buick,EncoreConvenience
401,16495,2015,20591,Bountiful, UT,KL4CJASB8FB062994,Buick,EncoreFWD
402,18214,2016,15389,Houston, TX,KL4CJASB8GB568116,Buick,EncoreFWD
403,12987,2014,65083,Franklin, TN,KL4CJASBXEB783834,Buick,EncoreFWD
404,17250,2016,20199,Grand Rapids, MI,KL4CJASB0GB560673,Buick,EncoreFWD
405,17897,2015,3854,Greensboro, NC,KL4CJASB3FB150061,Buick,EncoreFWD
406,20996,2016,4603,San Antonio, TX,KL4CJ1SMXGB600548,Buick,EncoreSport
407,18000,2015,14952,Birmingham, AL,KL4CJASB1FB208071,Buick,EncoreFWD
408,10555,2013,81208,Sunnyvale, CA,KL4CJASB7DB155129,Buick,EncoreEncore
409,18000,2016,11314,Louisville, KY,KL4CJASB0GB578011,Buick,EncoreFWD
410,15422,2015,41729,Spring, TX,KL4CJASB1FB153881,Buick,EncoreFWD
411,18495,2015,11861,Abilene, KS,KL4CJESB2FB260994,Buick,EncoreAWD
412,17995,2015,12631,Las Cruces, NM,KL4CJASB5FB185054,Buick,EncoreFWD
413,15995,2015,26906,Mchenry, IL,KL4CJASB8FB192953,Buick,EncoreFWD
414,16798,2014,18596,Morristown, TN,KL4CJASB1EB586843,Buick,EncoreFWD
415,12500,2015,69145,Van Nuys, CA,KL4CJASB8FB240788,Buick,EncoreFWD
416,16797,2015,17426,Fresno, CA,KL4CJASB3FB245140,Buick,EncoreFWD
417,17715,2015,22543,Warminster, PA,KL4CJASB5FB242336,Buick,EncoreFWD
418,16960,2015,23714,Rosenberg, TX,KL4CJASB3FB264738,Buick,EncoreFWD
419,15994,2015,27145,Seffner, FL,KL4CJCSB2FB244380,Buick,EncoreLeather
420,15299,2014,30598,Fishers, IN,KL4CJASB7EB784908,Buick,EncoreFWD
421,16995,2015,32362,Peoria, AZ,KL4CJBSB9FB242216,Buick,EncoreConvenience
422,16250,2015,24202,Louisville, KY,KL4CJASB9FB236460,Buick,EncoreFWD
423,18974,2016,8263,Lima, OH,KL4CJESB0GB715469,Buick,EncoreAWD
424,17297,2015,11625,Montgomery, AL,KL4CJASB1FB237067,Buick,EncoreFWD
425,17995,2015,13961,Chelsea, MI,KL4CJFSB0FB237266,Buick,EncoreConvenience
426,17999,2015,8492,Royal Oak, MI,KL4CJBSB2FB200986,Buick,EncoreConvenience
427,16299,2014,26647,Houston, TX,KL4CJBSB0EB735880,Buick,EncoreConvenience
428,13545,2014,63254,Englewood, CO,KL4CJBSB8EB533692,Buick,EncoreConvenience
429,17857,2016,21838,Buckeye, AZ,KL4CJASB5GB527457,Buick,EncoreFWD
430,15897,2013,24737,Marlton, NJ,KL4CJASB5DB181440,Buick,EncoreEncore
431,17034,2015,24741,Fort Smith, AR,KL4CJASB4FB235958,Buick,EncoreFWD
432,17488,2015,19274,Houston, TX,KL4CJBSB9FB240515,Buick,EncoreConvenience
433,16291,2015,29528,GILBERT, AZ,KL4CJASB5FB107356,Buick,EncoreFWD
434,16999,2015,15390,Dallas, TX,KL4CJBSB5FB260874,Buick,EncoreConvenience
435,16291,2015,29572,GILBERT, AZ,KL4CJASB7FB076725,Buick,EncoreFWD
436,17900,2015,19282,Omaha, NE,KL4CJBSB6FB182881,Buick,EncoreConvenience
437,17038,2015,29478,Shrewsbury, NJ,KL4CJFSB5FB174035,Buick,EncoreConvenience
438,17544,2015,18691,Zanesville, OH,KL4CJASB8FB252312,Buick,EncoreFWD
439,20499,2017,4120,Jacksonville, NC,KL4CJASB3HB030361,Buick,EncoreFWD
440,15995,2012,75686,Union City, GA,1G4PS5SKXC4125777,Buick,Verano4dr
441,3995,2006,48450,Calais, ME,3G5DB03L56S561465,Buick,Rendezvous4dr
442,15000,2014,43341,Birmingham, AL,KL4CJBSB1EB569899,Buick,EncoreConvenience
443,16520,2014,19557,Houston, TX,KL4CJASB6EB781367,Buick,EncoreFWD
444,17998,2015,19883,Littleton, CO,KL4CJESB6FB234320,Buick,EncoreAWD
445,11907,2013,69760,Warner Robins, GA,KL4CJASB7DB116931,Buick,EncoreEncore
446,17500,2015,19474,Stockton, CA,KL4CJASB8FB243755,Buick,EncoreFWD
447,16500,2015,21732,Louisville, KY,KL4CJASBXFB237732,Buick,EncoreFWD
448,14969,2014,35918,Palestine, TX,KL4CJBSBXEB683125,Buick,EncoreConvenience
449,18000,2015,21630,Harrisonville, MO,KL4CJCSBXFB218691,Buick,EncoreLeather
450,16935,2014,27293,Sea Girt, NJ,KL4CJASBXEB783977,Buick,EncoreFWD
451,18885,2015,4119,Sarasota, FL,KL4CJFSB1FB267344,Buick,EncoreConvenience
452,16500,2015,21898,Louisville, KY,KL4CJASB4FB265252,Buick,EncoreFWD
453,17569,2016,21336,Fishers, IN,KL4CJASB3GB545276,Buick,EncoreFWD
454,16987,2015,25936,Columbus, OH,KL4CJBSB1FB162571,Buick,EncoreConvenience
455,16946,2014,37494,Sullivan, IN,KL4CJBSB7EB532968,Buick,EncoreConvenience
456,18941,2016,13480,Salisbury, MD,KL4CJASB2GB531806,Buick,EncoreFWD
457,18995,2016,10469,Oakdale, CA,KL4CJASB8GB662982,Buick,EncoreFWD
458,18499,2016,16491,FLAGSTAFF, AZ,KL4CJASB0GB667853,Buick,EncoreFWD
459,18000,2016,12711,Louisville, KY,KL4CJASB0GB569387,Buick,EncoreFWD
460,14990,2012,98297,Columbus, MS,1G4PP5SKXC4187620,Buick,Verano4dr
461,15000,2014,31663,Louisville, KY,KL4CJASB5EB782431,Buick,EncoreFWD
462,20580,2017,14928,Killeen, TX,KL4CJCSB7HB007385,Buick,EncoreFWD
463,16750,2015,19260,Louisville, KY,KL4CJASB2FB238051,Buick,EncoreFWD
464,15599,2014,34762,Elgin, IL,KL4CJBSB6EB575178,Buick,EncoreConvenience
465,17795,2016,25578,Broken Arrow, OK,KL4CJ1SM0GB745632,Buick,EncoreSport
466,16197,2015,25995,Columbus, GA,KL4CJASB7FB238448,Buick,EncoreFWD
467,16900,2015,20066,New Hudson, MI,KL4CJESB6FB239663,Buick,EncoreAWD
468,14312,2014,57908,Conway, AR,KL4CJASB1EB575132,Buick,EncoreFWD
469,18250,2016,9955,Louisville, KY,KL4CJASB1GB526242,Buick,EncoreFWD
470,16597,2015,21264,Fresno, CA,KL4CJASB4FB267616,Buick,EncoreFWD
471,14734,2014,48621,Edmond, OK,KL4CJCSB9EB713947,Buick,EncoreLeather
472,18498,2015,11389,St. Petersburg, FL,KL4CJCSB9FB196621,Buick,EncoreLeather
473,14000,2014,52089,Bedford, OH,KL4CJCSB9EB633791,Buick,EncoreLeather
474,18990,2016,17915,Green Bay, WI,KL4CJESB0GB643303,Buick,EncoreAWD
475,18479,2015,14769,Merriam, KS,KL4CJDSB2FB185966,Buick,EncorePremium
476,15995,2014,20088,Jourdanton, TX,KL4CJASB8EB602763,Buick,EncoreFWD
477,16397,2015,23872,Charleston, SC,KL4CJASB1FB171099,Buick,EncoreFWD
478,18995,2015,2415,Merrillville, IN,KL4CJBSBXFB178784,Buick,EncoreConvenience
479,14995,2014,34003,Cincinnati, OH,KL4CJBSB9EB742634,Buick,EncoreConvenience
480,15250,2015,37702,Louisville, KY,KL4CJASB9FB229914,Buick,EncoreFWD
481,18962,2016,22383,Pontiac, IL,KL4CJBSB6GB516439,Buick,EncoreConvenience
482,17749,2015,11069,Burlington, NJ,KL4CJESB2FB180921,Buick,EncoreAWD
483,15500,2014,26108,Louisville, KY,KL4CJASB0EB717857,Buick,EncoreFWD
484,16894,2015,30582,Tempe, AZ,KL4CJASB1FB112943,Buick,EncoreFWD
485,17988,2015,15535,Laguna Niguel, CA,KL4CJBSB5FB231519,Buick,EncoreConvenience
486,17197,2015,14353,Mesa, AZ,KL4CJASB6FB242507,Buick,EncoreFWD
487,16432,2014,24880,Elmhurst, IL,KL4CJASB4EB616207,Buick,EncoreFWD
488,17588,2015,24291,Strongsville, OH,KL4CJCSB6FB055909,Buick,EncoreLeather
489,16000,2014,20277,Louisville, KY,KL4CJASB7EB595529,Buick,EncoreFWD
490,14677,2013,38607,Sarasota, FL,KL4CJBSB4DB062526,Buick,EncoreConvenience
491,13990,2013,35930,Twinsburg, OH,KL4CJFSB1DB104870,Buick,EncoreConvenience
492,15300,2014,38587,Brownsville, TX,KL4CJASB2EB621776,Buick,EncoreFWD
493,17997,2015,24393,New Smyrna Beach, FL,KL4CJASB6FB230129,Buick,EncoreFWD
494,15477,2014,41310,Garner, NC,KL4CJCSB2EB699373,Buick,EncoreLeather
495,16250,2015,26057,Louisville, KY,KL4CJASB0FB175452,Buick,EncoreFWD
496,16495,2015,30356,Vacaville, CA,KL4CJASB5FB053332,Buick,EncoreFWD
497,18999,2016,10060,Conyers, GA,KL4CJBSB7GB595250,Buick,EncoreConvenience
498,16995,2015,27802,Chelsea, MI,KL4CJESB6FB239761,Buick,EncoreAWD
499,14895,2015,46243,Wappingers Falls, NY,19VDE1F35FE006900,Acura,ILX5-Speed
500,18832,2015,9132,Brooklyn, NY,19VDE1F35FE010834,Acura,ILX5-Speed
501,22000,2017,10,Chicago, IL,19UDE2F35HA011597,Acura,ILXAutomatic
502,18990,2012,23099,Portland, OR,JH4CU2F66CC031084,Acura,TSXAutomatic
503,17890,2015,24007,Temple Hills, MD,19VDE1F39FE007287,Acura,ILX5-Speed
504,14995,2013,45907,Manassas, VA,19VDE1F3XDE016299,Acura,ILX5-Speed
505,18490,2014,54500,Virginia Beach, VA,JH4CU2F41EC004070,Acura,TSX5-Speed
506,16900,2012,58915,Albuquerque, NM,JH4CU2F49CC003374,Acura,TSXAutomatic
507,14690,2011,73227,Costa Mesa, CA,JH4CU2F62BC018637,Acura,TSX4dr
508,12983,2013,98298,Madison, WI,19VDE1F7XDE001580,Acura,ILX5-Speed
509,17599,2013,23713,Westport, CT,19UUA8F2XDA015967,Acura,TLBase
510,19440,2016,51814,Newnan, GA,19UDE2F70GA011055,Acura,ILXAutomatic
511,4995,2004,149872,Philadelphia, PA,JH4CL96854C018499,Acura,TSXAutomatic
512,12970,2009,69334,Jefferson City, MO,19UUA86599A016805,Acura,TL4dr
513,8995,2008,98706,Norfolk, VA,19UUA662X8A050129,Acura,TL4dr
514,15772,2012,76678,Monroeville, PA,19UUA9F57CA004648,Acura,TLSH-AWD
515,9990,2006,90026,Arlington, TX,19UUA66226A061848,Acura,TLAutomatic
516,10299,2008,102476,Raleigh, NC,19UUA66228A023636,Acura,TL4dr
517,3997,2001,124846,Stafford, VA,19UUA56771A019755,Acura,TLAutomatic
518,9999,2008,118535,Englewood, CO,19UUA66208A005667,Acura,TL4dr
519,9495,2008,131639,Fayetteville, NC,19UUA66298A050431,Acura,TL4dr
520,8988,2006,131760,Houston, TX,19UUA66276A016467,Acura,TLAutomatic
521,15896,2011,71356,Winston Salem, NC,5J8TB2H57BA006041,Acura,RDXFWD
522,14350,2010,78697,Paramus, NJ,5J8TB1H56AA005231,Acura,RDXAWD
523,20890,2014,31967,Rockville, MD,JH4CU2E81EC000590,Acura,TSXSpecial
524,15980,2012,81719,Pacoima, CA,5J8TB2H53CA001713,Acura,RDXFWD
525,13800,2010,78631,Chapel Hill, NC,JH4CU2F60AC006811,Acura,TSX4dr
526,25000,2016,13971,McKinney, TX,19UUB1F36GA002098,Acura,TLXFWD
527,25199,2016,3206,Verona, NJ,19UUB1F37GA003311,Acura,TLXFWD
528,21899,2014,52675,Dallas, TX,5J8TB3H50EL013315,Acura,RDXFWD
529,22675,2015,27047,Larchmont, NY,19UUB1F33FA001005,Acura,TLXFWD
530,22788,2013,40617,Valencia, CA,5J8TB3H5XDL017287,Acura,RDXwith
531,17777,2015,90970,Chantilly, VA,19UUB1F55FA002920,Acura,TLXFWD
532,23899,2015,18911,Windsor, CO,19UUB2F53FA024419,Acura,TLXFWD
533,17977,2015,26456,Akron, OH,19VDE1F30FE006593,Acura,ILX5-Speed
534,15695,2009,99376,Houston, TX,5J8TB18529A001804,Acura,RDXAWD
535,23926,2015,37578,Chicago, IL,19UUB2F53FA006468,Acura,TLXFWD
536,27990,2017,10427,Charlotte, NC,19UUB1F31HA001376,Acura,TLXFWD
537,26794,2016,21978,Tempe, AZ,19UUB2F32GA001875,Acura,TLXFWD
538,12977,2013,103535,Akron, OH,JH4CU2F41DC004584,Acura,TSX5-Speed
539,29815,2017,4508,Bethesda, MD,19UUB1F58HA005149,Acura,TLXFWD
540,26883,2015,18811,Middle Island, NY,19UUB3F56FA003781,Acura,TLXSH-AWD
541,27480,2015,27488,Philadelphia, PA,5J8TB3H32FL012134,Acura,RDXFWD
542,12990,2012,129225,Sacramento, CA,19UUA8F58CA005592,Acura,TL2WD
543,25988,2015,29069,Littleton, NH,19UUB2F59FA001243,Acura,TLXFWD
544,6995,2004,132345,Conroe, TX,2HNYD18884H522183,Acura,MDX4WD
545,31290,2015,13998,Englewood, CO,5J8TB4H55FL008067,Acura,RDXAWD
546,14500,2011,109797,Wolcott, NY,2HNYD2H67BH530471,Acura,MDXAWD
547,33495,2017,5186,Langhorne, PA,5J8TB4H37HL028810,Acura,RDXAWD
548,30995,2017,4339,West Nyack, NY,19UUB2F53HA000480,Acura,TLXFWD
549,9988,2006,132182,Englewood, CO,2HNYD18266H537859,Acura,MDX4WD
550,34800,2017,9516,Peoria, AZ,5J8TB4H57HL013810,Acura,RDXAWD
551,22426,2013,59129,Chicago, IL,2HNYD2H23DH524232,Acura,MDXBase
552,17490,2010,117079,Roanke, VA,2HNYD2H57AH520514,Acura,MDXAWD
553,29688,2015,22189,Tukwila, WA,19UUB2F52FA023651,Acura,TLXFWD
554,16995,2007,74357,Kihel, HI,2HNYD28207H535497,Acura,MDX4WD
555,4000,2004,209762,Tigard, OR,2HNYD18704H505577,Acura,MDX4WD
556,7995,2005,103469,Reading, PA,19UUA66265A015969,Acura,TLAutomatic
557,34702,2016,23380,Morton Grove, IL,5J8TB4H59GL006582,Acura,RDXAWD
558,24777,2013,62552,Phoenix, AZ,2HNYD2H33DH523669,Acura,MDXwith
559,29495,2014,34897,Brooklyn Center, MN,5FRYD4H23EB047891,Acura,MDXAWD
560,36771,2017,8324,Naperville, IL,19UUB3F56HA002746,Acura,TLXSH-AWD
561,29991,2015,41477,Riverside, CA,5FRYD3H4XFB001729,Acura,MDXFWD
562,29987,2014,35848,Springfield, NJ,5FRYD4H24EB038844,Acura,MDXAWD
563,30686,2015,38720,Tracy, CA,5FRYD3H41FB008911,Acura,MDXFWD
564,30950,2014,30586,Randallstown, MD,5FRYD4H41EB033067,Acura,MDXAWD
565,32735,2016,40313,Woodside, NY,5FRYD4H49GB009523,Acura,MDXSH-AWD
566,32500,2015,44610,Indiana, PA,5FRYD4H41FB016304,Acura,MDXAWD
567,34390,2015,41065,Chamblee, GA,5FRYD3H4XFB005926,Acura,MDXFWD
568,38000,2016,14227,Littleton, CO,5FRYD4H28GB047355,Acura,MDXSH-AWD
569,34341,2015,49520,Colorado Springs, CO,5FRYD4H43FB016658,Acura,MDXAWD
570,35000,2015,29253,Larchmont, NY,5FRYD4H45FB018864,Acura,MDXAWD
571,37843,2016,26030,Chantilly, VA,5FRYD4H4XGB027349,Acura,MDXSH-AWD
572,37500,2016,34895,Lynnwood, WA,5FRYD4H2XGB032548,Acura,MDXSH-AWD
573,40900,2017,7039,Houston, TX,5FRYD3H35HB011448,Acura,MDXFWD
574,39488,2016,23977,Gaithersburg, MD,5FRYD4H40GB037906,Acura,MDXSH-AWD
575,38587,2015,23637,Springfield, NJ,5FRYD4H89FB015940,Acura,MDXAWD
576,38420,2015,31287,Larchmont, NY,5FRYD4H85FB011240,Acura,MDXAWD
577,43900,2017,7071,Houston, TX,5FRYD3H53HB011210,Acura,MDXFWD
578,42988,2016,18436,Aurora, CO,5FRYD4H64GB045850,Acura,MDXSH-AWD
579,42485,2016,32506,Plano, TX,5FRYD4H99GB009839,Acura,MDXSH-AWD
580,37900,2016,32990,Colorado Springs, CO,5FRYD4H24GB019651,Acura,MDXSH-AWD
581,26991,2014,54914,Sarasota, FL,JH4KC1F51EC005060,Acura,RLXwith
582,36988,2015,35149,Fremont, CA,5FRYD4H41FB018053,Acura,MDXAWD
583,47835,2018,19,Seattle, WA,ZASFAKPN0J7B63378,Alfa,Romeo
584,111500,2014,15335,Lawrenceville, NJ,SCFFDABM3EGB15442,Aston,Martin
585,7450,2007,81506,Columbus, OH,WAUAF78E07A237228,Audi,A42007
586,12950,2010,52817,Daytona, FL,WAUAFAFL5AN039680,Audi,A44dr
587,16945,2013,46778,Lake Park, FL,WAUEFAFL8DN046581,Audi,A42.0T
588,7995,2006,91668,Indianapolis, IN,WAUDF78E06A231733,Audi,A44dr
589,17268,2013,59100,Las Vegas, NV,WAUEFAFL6DN035062,Audi,A42.0T
590,14999,2013,80988,Raleigh, NC,WAUBFAFL5DN038943,Audi,A42.0T
591,18988,2014,47110,Madison, TN,WAUBFAFLXEA068982,Audi,A42.0T
592,22378,2014,24596,Devon, PA,WAUBFAFL9EN040003,Audi,A42.0T
593,21975,2014,30997,Scottsdale, AZ,WAUAFAFL1EN037074,Audi,A42.0T
594,23799,2015,22467,N. Miami Beach, FL,WAUAFAFL1FN001533,Audi,A42.0T
595,21500,2015,51855,Louisville, KY,WAUBFAFLXFN035989,Audi,A42.0T
596,25991,2015,13182,Jacksonville, FL,WAUAFAFL0FN013995,Audi,A42.0T
597,19999,2015,16549,Montebello, CA,WAUBFGFF2F1002876,Audi,A3Sedan
598,25499,2015,28728,Middletown, RI,WAUDFAFL3FN005113,Audi,A42.0T
599,21890,2015,19639,Lafayette, IN,WAUCCGFF8F1018061,Audi,A3Sedan
600,19450,2015,38710,Fredericksburg, VA,WAUACGFF5F1018591,Audi,A3Sedan
601,24995,2015,41439,Long Island City, NY,WAUBFAFL3FN027488,Audi,A42.0T
602,29900,2017,5278,Portland, OR,WAUGNAF48HN036868,Audi,A4Premium
603,9495,2007,115824,Columbus, OH,WAUEV74F57N160688,Audi,A64dr
604,29759,2015,24417,Morton Grove, IL,WAUFFAFL9FN006887,Audi,A42.0T
605,23888,2015,13277,Bellevue, WA,WAUBFGFF4F1002894,Audi,A3Sedan
606,14995,2011,100355,Sacramento, CA,WAUKGAFB2BN033359,Audi,A64dr
607,24995,2015,22315,Greenville, NC,WAUBFGFF5F1082352,Audi,A3Sedan
608,26555,2016,15213,Saint Cloud, MN,WAUB8GFF0G1011491,Audi,A3Sedan
609,21788,2014,57415,Cerritos, CA,WAURFAFR3EA001501,Audi,A52.0T
610,24125,2014,43725,Virginia Beach, VA,WAULFAFR0EA008167,Audi,A52.0T
611,35000,2017,5598,Burlingame, CA,WAUDNAF47HN040879,Audi,A4Season
612,8490,2005,91500,Fredericksburg, VA,WUARL48H65K900416,Audi,S42dr
613,22999,2013,39675,West Palm Beach, FL,WAUAFAFH9DN002065,Audi,A52.0T
614,23500,2013,39003,Merriam, KS,WAUAFAFH6DN000208,Audi,A52.0T
615,23750,2012,62668,Grapevine, TX,WAUHGAFC5CN066683,Audi,A64dr
616,29995,2015,6848,Raleigh, NC,WAU5CLFF4F1122662,Audi,A3Cabriolet
617,25900,2014,33413,Plano, TX,WAULFAFH3EN011841,Audi,A52.0T
618,27920,2014,43149,Chicago, IL,WAUGFAFC8EN009959,Audi,A62.0T
619,37771,2017,9448,Chicago, IL,WAUDNAF41HN057984,Audi,A4Season
620,28780,2014,29000,Golden, CO,WAUGFAFC3EN038334,Audi,A62.0T
621,9990,2007,109868,Tuscon, AZ,WA1AY74L77D047890,Audi,Q7quattro
622,26990,2014,68158,St Louis, MO,WAUHGAFCXEN088813,Audi,A63.0T
623,15988,2011,98021,Whitman, MA,WA1LFAFP3BA089110,Audi,Q5quattro
624,18945,2011,66787,Canton, MA,WA1DKAFP0BA037840,Audi,Q5quattro
625,19973,2013,80677,Maitland, FL,WA1LFAFP5DA056760,Audi,Q52.0T
626,21486,2012,53315,Southborogh, MA,WA1DKAFP5CA064131,Audi,Q5quattro
627,18629,2012,92780,Beaverton, OR,WA1DKAFP3CA008656,Audi,Q5quattro
628,17351,2010,88367,Matteson, IL,WA1LYAFEXAD003037,Audi,Q7quattro
629,26677,2012,28110,Dulles, VA,WAUBGAFL0CA114734,Audi,S44dr
630,22950,2011,47901,Phoenix, AZ,WA1DKAFP2BA088918,Audi,Q5quattro
631,25500,2014,56642,Newark, CA,WA1CFAFP0EA083902,Audi,Q52.0T
632,13988,2008,121480,Boise, ID,WA1BV74L68D024339,Audi,Q7quattro
633,32998,2015,43767,Merriam, KS,WAUMFAFH8FN003059,Audi,A52.0T
634,28995,2015,32304,Roseville, CA,WA1EFCFS3FR005156,Audi,Q3quattro
635,19388,2010,86089,Mt. Juliet, TN,WA1LYAFE4AD007357,Audi,Q7quattro
636,27991,2015,37221,Grapevine, TX,WA1EFCFS4FR000659,Audi,Q3quattro
637,31995,2017,3233,Long Island City, NY,WA1ECCFS5HR006322,Audi,Q3quattro
638,36989,2015,42440,Morton Grove, IL,WAUHGAFC0FN009716,Audi,A63.0T
639,30199,2014,37428,Clearwater, FL,WA1LFAFPXEA063429,Audi,Q52.0T
640,19881,2008,47111,Bethesda, MD,TRURD38J881003363,Audi,TT3.2L
641,32989,2015,18437,Victor, NY,WAUBFGFF6F1117397,Audi,S3Premium
642,29480,2015,50022,Philadelphia, PA,WA1LFAFP5FA043722,Audi,Q52.0T
643,26995,2014,45097,Stonington, CT,WA1TFAFL4EA052312,Audi,allroad2.0T
644,34000,2015,27940,Columbus, OH,WA1LFAFP1FA045497,Audi,Q52.0T
645,33927,2016,8595,Cockeysville, MD,WA1EFCFS2GR011290,Audi,Q3quattro
646,27331,2012,67591,Garden Grove, CA,WA1DGAFE1CD010419,Audi,Q7quattro
647,32500,2015,41407,Salt Lake, UT,WA1LFAFP1FA104936,Audi,Q52.0T
648,30000,2013,50464,Hinsdale, IL,WA1LGAFE6DD001857,Audi,Q73.0T
649,43380,2017,7047,Philadelphia, PA,WAUC8AFC4HN028523,Audi,A62.0T
650,26500,2015,30876,Palo Alto, CA,WAUEFGFF8F1017069,Audi,A3Sedan
651,30888,2014,59533,Norman, OK,WA1LGAFE8ED007662,Audi,Q73.0T
652,39493,2017,16250,Roswell, GA,WA1L2AFP4HA030586,Audi,Q52.0
653,33875,2017,1000,Palo Alto, CA,WAUAUGFF7H1050684,Audi,A3
654,33762,2015,30105,Torrance, CA,WA1UFAFL9FA093377,Audi,allroad2.0T
655,32995,2014,54823,Manassas, VA,WA1LGAFEXED001989,Audi,Q73.0T
656,34991,2014,45659,Westmont, IL,WA1LGAFE6ED018854,Audi,Q73.0T
657,45881,2016,1735,Brentwood, TN,WAUM2AFH3GN011602,Audi,A52.0T
658,42777,2018,6,Grapevine, TX,WA1JCCFS9JR002407,Audi,Q3Premium
659,31881,2013,62869,Anaheim, CA,WAURGAFDXDN004405,Audi,A8
660,37500,2015,43806,Framingham, MA,WA1LGAFE7FD001482,Audi,Q73.0T
661,14995,2009,98858,Indianapolis, IN,WA1AY74L39D003307,Audi,Q7quattro
662,22999,2013,60873,Trevose, PA,WA1LFAFP0DA006381,Audi,Q52.0T
663,39995,2015,33260,Concord, CA,WA1LGAFE4FD003576,Audi,Q73.0T
664,34500,2014,51141,Union City, GA,WAUA2AFD8EN003072,Audi,A84.0T
665,45500,2016,21341,Atlanta, GA,WA1CVAFP2GA015977,Audi,Q5quattro
666,39991,2017,6414,Grapevine, TX,WA1C2AFP8HA042756,Audi,Q52.0
667,43950,2014,31622,Littleton, CO,WA1DGAFE5ED016100,Audi,Q73.0T
668,41544,2017,7042,Upper Saddle River, NJ,WA1L2AFP1HA016970,Audi,Q52.0
669,38998,2014,73585,Webster, TX,WAUF2AFC2EN066438,Audi,S6quattro
670,59497,2017,5609,Morton Grove, IL,WAUH2AFC6HN079111,Audi,A63.0T
671,31995,2011,66277,Englewood, CO,WAURVAFD3BN011151,Audi,A8
672,47999,2013,60853,Marrieta, GA,WAUD2AFD1DN009887,Audi,S8quattro
673,53993,2014,34142,Atlanta, GA,WAUD2AFD3EN006152,Audi,S8quattro
674,39989,2014,42665,Colorado Springs, CO,WA1DGAFE8ED019623,Audi,Q73.0T
675,62987,2017,1870,Sanford, FL,WA1VAAF70HD044477,Audi,Q73.0T
676,60995,2013,26005,Austin, TX,WAUR4AFD6DN025048,Audi,A8
677,79900,2015,13018,Paterson, NJ,WUAW2BFC8FN900323,Audi,RS
678,99000,2011,12714,Downers Grove, IL,WUADNAFG8BN000544,Audi,R82dr
679,56999,2009,62173,Marrieta, GA,SCBBR93WX9C057923,Bentley,Continental
680,79500,2007,19267,Raleigh, NC,SCBDR33W67C048204,Bentley,Continental
681,138991,2014,21710,Anaheim, CA,SCBFH7ZA6EC095017,Bentley,Continental
682,8989,2009,70509,Spring Branch, TX,WBAPH77599NM29555,BMW,3
683,17750,2014,33026,San Antonio, TX,WBA3C3G53ENS67891,BMW,3
684,18000,2014,49669,Sarasota, FL,WBA3B1C50EPV79376,BMW,3
685,16888,2014,49877,Orlando, FL,WBA3B1C57EP680544,BMW,3
686,16788,2014,33140,Miami, FL,WBAVM1C54EVW55308,BMW,X1sDrive28i
687,8450,2008,96236,Grove City, OH,WBAVC93548KZ73002,BMW,3
688,14595,2014,20291,Doral, FL,WBY1Z2C55EVX51584,BMW,i3Hatchback
689,19789,2014,45133,Edmond, OK,WBA3B1C59EK135376,BMW,3
690,10995,2009,81422,Great Neck, NY,WBAPK53509A645541,BMW,3
691,16995,2013,55811,Kinston, NC,WBA3A5C52DF357291,BMW,3
692,19999,2016,37682,West Park, FL,WBA8E9G59GNT82436,BMW,3
693,9994,2006,87459,Greenville, SC,WBABW534X6PL54706,BMW,3
694,14500,2011,64440,Longmont, CO,WBAPK5G57BNN25793,BMW,3
695,16500,2011,41914,Kansas City, MO,WBAPK5C53BF127612,BMW,3
696,19988,2014,33263,Union City, GA,WBA3C3C56EP661444,BMW,3
697,15595,2013,73360,Riverside, CA,WBAKE5C53DJ107023,BMW,3
698,18862,2013,50934,Buford, GA,WBA3A5C52DF351703,BMW,3
699,19895,2014,50157,Winter Park, FL,WBA3D3C53EK156472,BMW,3
700,20490,2015,37964,Plano, TX,WBA3B1G57FNT02294,BMW,3
701,18491,2013,43450,Pembroke Pines, FL,WBA3A5C5XDF359323,BMW,3
702,16388,2014,67053,Monroe, NC,WBAVM1C57EVW50300,BMW,X1sDrive28i
703,21990,2016,32933,West Park, FL,WBA8E9G55GNT83177,BMW,3
704,22430,2016,28953,Buford, GA,WBA8E9G51GNT43808,BMW,3
705,6800,2002,67063,Lincoln, NE,WBADT43412GY40200,BMW,5
706,6499,2007,105959,Roswell, GA,WBANE73597CM54342,BMW,5
707,9995,2008,88282,Stafford, VA,WBANW13578CZ83428,BMW,5
708,19999,2014,46578,Miami, FL,WBA3D5C56EKX98048,BMW,3
709,24997,2015,17250,San Francisco, CA,WBA3B1C59FK141406,BMW,3
710,14899,2011,88961,Schaumburg, IL,WBAPK7G5XBNN78600,BMW,3
711,23000,2015,33205,Little Rock, AR,WBA3B1C57FP831402,BMW,3
712,24469,2015,25860,Irvine, CA,WBA3B1C58FK136925,BMW,3
713,20990,2014,41310,Plano, TX,WBA3A5G53ENP27178,BMW,3
714,19898,2015,19690,Bellevue, WA,WBY1Z2C50FV286486,BMW,i3Hatchback
715,13995,2009,95060,Houston, TX,WBAPM77579NL87754,BMW,3
716,22990,2014,41378,Minnetonka, MN,WBA3B3C59EJ978768,BMW,3
717,15000,2012,101589,Phoenix, AZ,WBA3A5C52CF342224,BMW,3
718,24788,2016,25562,Wilmington, DE,WBA8E3C58GK502960,BMW,3
719,9995,2006,112730,San Diego, CA,WBAVB13556PT04273,BMW,3
720,21995,2014,38392,Westborough, MA,WBA3B3C5XEF545334,BMW,3
721,7333,2002,112940,Manassas, VA,WBABS33482PG83014,BMW,3
722,23820,2014,38531,Newport News, VA,WBA3A5G51ENP29334,BMW,3
723,17924,2014,91368,FONTANA, CA,WBA3C1C56EK107726,BMW,3
724,21700,2014,47235,Lexington, KY,WBA3D5C53EKX97861,BMW,3
725,8890,2006,133809,Santa Ana, CA,WBAVB17576NK32465,BMW,3
726,29375,2017,9557,Escondido, CA,WBA8E1G57HNU12522,BMW,3
727,23986,2012,16066,Saint James, NY,WBADW7C50CE730687,BMW,3
728,19995,2014,27533,Henderson, NV,WBY1Z4C59EVX63264,BMW,i3Hatchback
729,7999,2006,95494,Las Vegas, NV,WBXPA93426WG77741,BMW,X3AWD
730,7998,2005,103000,La Habra, CA,WBANB33505CN67515,BMW,5
731,23549,2014,34669,Lafayette, IN,WBA3B5G56ENS07344,BMW,3
732,20995,2014,61404,Grand Rapids, MI,WBAVM5C54EVV91441,BMW,X1xDrive35i
733,26844,2016,36620,Olympia, WA,WBA8E9C55GK644573,BMW,3
734,9495,2006,83802,Newport News, VA,WBXPA93426WD33018,BMW,X3AWD
735,9885,2008,112340,Wilmington, CA,WBANW53538CT48374,BMW,5
736,10999,2008,100551,Bellingham, WA,WBANV935X8CW55051,BMW,5
737,12000,2011,117717,Pinellas Park, FL,5UXWX5C5XBL710307,BMW,X3AWD
738,23200,2013,39864,Fayetteville, NC,WBAKF9C54DE860346,BMW,3
739,24850,2014,30507,Monterey Park, CA,WBA3A5G56ENP31905,BMW,3
740,26000,2014,26158,Arlington, TX,WBA3B5C59EF599915,BMW,3
741,17899,2011,52085,Phoenix, AZ,WBAFR1C55BC742048,BMW,5
742,26000,2014,39146,Berkeley, CA,WBA3A5G51ENP26661,BMW,3
743,18497,2012,65819,Concord, NC,WBAXG5C55CDY29722,BMW,5
744,26742,2014,28556,Norwalk, CA,WBA3C1C50EK107348,BMW,3
745,27900,2014,9689,Huntington Station, NY,WBA3B5G53ENS09455,BMW,3
746,22995,2012,48361,Brea, CA,WBAKG7C56CE802836,BMW,3
747,16999,2012,82123,Manassas, VA,WBAXH5C56CDW05517,BMW,5
748,17977,2012,72367,Hillside, NJ,WBAXH5C58CDW07477,BMW,5
749,25094,2014,19896,Oklahoma City, OK,WBA5A5C52ED502934,BMW,5
750,17995,2014,78778,Manassas, VA,5UXWX9C52E0D13853,BMW,X3xDrive28i
751,16000,2011,80141,Massillon, OH,5UXWX7C54BL731990,BMW,X3AWD
752,30624,2015,11258,Ocala, FL,WBA3A5G57FNS89508,BMW,3
753,23000,2013,34597,Frisco, TX,WBAXG5C53DDY37383,BMW,5
754,18847,2011,64741,Wakefield, MA,WBAFU7C50BC876712,BMW,5
755,34000,2017,4613,Beaverton, OR,WBA8A9C59HK620418,BMW,3
756,24877,2014,21981,Hollywood, FL,WBA5A5C52ED503632,BMW,5
757,12995,2009,80731,Knoxville, TN,5UXFE43589L269447,BMW,X5AWD
758,27955,2014,42557,Tampa, FL,WBA3B3G56ENR81839,BMW,3
759,25000,2014,34105,Gaithersburg, MD,5UXWX9C58E0D33069,BMW,X3xDrive28i
760,21991,2011,43714,West Palm Beach, FL,WBAFR9C58BC758811,BMW,5
761,21800,2014,53632,San Jose, CA,5UXWX9C53E0D23677,BMW,X3xDrive28i
762,22590,2014,46009,Plano, TX,5UXWX9C54E0D13952,BMW,X3xDrive28i
763,19995,2013,85000,Scottsdale, AZ,WBAFR7C51DC824669,BMW,5
764,14000,2008,73826,Arlington Heights, IL,5UXFE83548L098959,BMW,X5AWD
765,9995,2009,120186,Columbus, OH,WBAWC33529PU84913,BMW,3
766,26595,2014,59690,Great Neck, NY,WBA3B9C57EP458925,BMW,3
767,13478,2008,85337,Colorado Springs, CO,5UXFE83548L098492,BMW,X5AWD
768,9999,2006,94931,Marietta, GA,WBAHN83526DT30828,BMW,7
769,25477,2014,42391,Denton, TX,5UXWX9C53E0D34694,BMW,X3xDrive28i
770,29995,2014,25765,Addison, TX,WBA3B9C52EJ899591,BMW,3
771,7995,2005,115969,Jacksonville, FL,WBAGN63505DS60387,BMW,7
772,31499,2016,35564,Avon, IN,WBA8E3G51GNT80466,BMW,3
773,7999,2009,81000,Orlando, FL,WBAPH73549E127984,BMW,3
774,26897,2014,70468,Avenel, NJ,WBA3B9G55ENR79471,BMW,3
775,16995,2008,57135,Kihel, HI,5UXFE83568L098297,BMW,X5AWD
776,11995,2007,93270,Chesapeake, VA,WBAHN835X7DT71886,BMW,7
777,14995,2010,109000,Stonewall, LA,5UXFE4C5XAL379940,BMW,X5AWD
778,31950,2016,12585,Carrollton, TX,WBXHT3C30GP882339,BMW,X1xDrive
779,11500,2007,68613,Old Hickory, TN,WBANF33577CS39953,BMW,5
780,25995,2014,35734,Westborough, MA,5UXWX9C59E0D25157,BMW,X3xDrive28i
781,17995,2006,79444,Fuquay Varina, NC,WBSNB93596B581998,BMW,5
782,28795,2014,24202,Fremont, CA,WBA5A5C5XED510411,BMW,5
783,28961,2016,26723,Lewisville, TX,5UXWX9C51G0D71634,BMW,X3xDrive28i
784,11899,2007,76438,San Diego, CA,WBAWV135X7PK48814,BMW,3
785,26695,2014,48324,Great Neck, NY,WBA5A7C52ED612006,BMW,5
786,15995,2008,68001,Chicago, IL,WBAHL83578DT13211,BMW,7
787,28996,2014,30213,Mountain View, CA,WBA5A5C59ED507354,BMW,5
788,13988,2007,104305,Englewood, CO,5UXFE83507LZ44647,BMW,X5AWD
789,36857,2017,4030,Highlands Ranch, CO,WBXHT3C3XH5F83862,BMW,X1xDrive28i
790,14795,2008,60750,Lakewood, NJ,WBAVD53578A009649,BMW,3
791,29879,2014,29405,Rochelle Park, NJ,WBA5A7C55ED618527,BMW,5
792,12888,2008,87695,San Mateo, CA,WBAVB73578KY63102,BMW,3
793,27591,2014,53498,Hoover, AL,WBA5B3C55ED538943,BMW,5
794,24577,2014,30175,San Rafael, CA,WBA3N7C54EK221141,BMW,4
795,23830,2012,45548,Pensacola, FL,WBA3A9C54CFX59772,BMW,3
796,38799,2017,8583,Encinitas, CA,WBA8B9C5XHK676445,BMW,3
797,35994,2016,21451,Bensenville, IL,WBA8F1C51GK438407,BMW,3
798,39525,2017,5776,Midlothian, VA,WBA8D9G54HNU58570,BMW,3
799,32180,2014,26735,Boardman, OH,WBA5A7C50ED617074,BMW,5
800,20999,2012,77682,Miami, FL,WBAKC8C58CC436309,BMW,7
801,17988,2011,72972,San Diego, CA,WBAFR1C52BDS36803,BMW,5
802,33476,2016,12655,Houston, TX,5UXWZ7C55G0R32039,BMW,X3sDrive28i
803,25559,2012,59718,Littleton, CO,5UXZW0C53CL672360,BMW,X5AWD
804,33964,2015,36020,San Antonio, TX,WBA5B1C51FD921817,BMW,5
805,22897,2011,59865,Garden Grove, CA,WBAKX8C52BC198072,BMW,7
806,15988,2010,34737,Montgomery, AL,WBANU5C50AC364592,BMW,5
807,28490,2014,25341,Plano, TX,WBA5M2C57ED871830,BMW,5
808,33998,2015,38116,Decatur, GA,WBA5B1C50FD921582,BMW,5
809,28995,2014,40897,Westbrook, ME,WBA3D5C52EKX99178,BMW,3
810,29000,2015,28358,Palatine, IL,WBA4A7C57FD415796,BMW,4
811,35995,2016,24378,Fountain Hills, AZ,WBA5A5C56GG352439,BMW,5
812,30995,2016,6825,Doral, FL,WBA3N9C5XGK248567,BMW,4
813,35995,2016,29649,Springfield, VA,5UXWX9C55G0D89635,BMW,X3xDrive28i
814,28500,2015,35340,MONTCLAIR, CA,WBA4A9C51FD416308,BMW,4
815,26940,2013,64043,Newnan, GA,WBAYF4C59DD127910,BMW,7
816,27998,2015,57136,Jacksonville, FL,WBA3V5C53FP752971,BMW,4
817,24991,2015,30176,Gainesville, FL,WBAVM1C57FV314762,BMW,X1sDrive28i
818,35993,2015,30456,Henderson, NV,5UXXW3C55F0F89479,BMW,X4xDrive28i
819,32899,2014,51502,Beavercreek, OH,5UXKR0C53E0H20231,BMW,X5xDrive35i
820,31988,2012,62364,Monterey Park, CA,5YMGY0C50CLK27519,BMW,X5
821,32494,2014,64792,Loveland, CO,5UXKR0C51E0H17991,BMW,X5xDrive35i
822,29990,2012,51784,Concord, CA,WBAKC8C57CC436849,BMW,7
823,39567,2017,4234,Midlothian, VA,WBA8B9G57HNU48766,BMW,3
824,34777,2014,39998,Lodi, NJ,5UXKS4C57E0J95392,BMW,X5xDrive35d
825,40885,2014,12535,Santa Monica, CA,WBAKN9C55ED681565,BMW,5
826,32900,2014,28947,Huntington Station, NY,WBA3D5C53EKX95382,BMW,3
827,32988,2013,53557,Decatur, GA,WBAYF8C58DD140901,BMW,7
828,32999,2012,38787,Sarasota, FL,WBALZ3C50CDL72704,BMW,6
829,24999,2014,48102,Miami Gardens, FL,WBA5B1C58ED917178,BMW,5
830,34995,2015,30127,Westborough, MA,WBA1J9C58FVX98513,BMW,2
831,30988,2012,69335,Pacoima, CA,WBALZ3C51CDL70511,BMW,6
832,35500,2014,35998,Hartford, CT,WBA3T1C59EPS98965,BMW,4
833,46867,2017,4306,Plano, TX,WBY1Z8C39HV890820,BMW,i394
834,28888,2011,56386,Hollywood, FL,WBSKG9C52BE368520,BMW,M32dr
835,41777,2018,4257,Valencia, CA,WBA4J1C55JBA30192,BMW,4
836,47045,2017,4934,Fremont, CA,5UXWX7C56H0U41132,BMW,X3xDrive35i
837,23867,2013,63801,Austin, TX,5UXZV4C5XD0B18040,BMW,X5xDrive35i
838,43276,2016,28650,Mount Kisco, NY,5UXKR0C5XG0P34658,BMW,X5xDrive35i
839,34333,2013,83800,North Hollywood, CA,WBA6B2C51DD128184,BMW,6
840,38995,2014,35691,Hayward, CA,5UXFG2C53E0H11418,BMW,X6xDrive35i
841,34995,2015,4252,Darien, CT,WBY1Z4C56FV502563,BMW,i3Hatchback
842,43881,2014,29304,Bedford, TX,5UXKR6C54E0C03118,BMW,X5xDrive50i
843,25995,2011,80713,Farmers Branch, TX,WBAKB8C56BCY66776,BMW,7
844,49299,2017,13000,Spring Valley, NY,5UXKR0C50H0U52921,BMW,X5xDrive35i
845,47444,2015,25147,Reading, PA,5UXKS4C50F0N09854,BMW,X5xDrive35d
846,52991,2016,13158,Charlotte, NC,5UXKT0C50G0F75105,BMW,X5
847,56445,2017,99,Lancaster, PA,5UXWX7C34H0U44464,BMW,X3xDrive35i
848,49995,2015,17292,Chicago, IL,WBAYE8C58FD781228,BMW,7
849,59776,2017,4167,Eatontown, NJ,WBAJA7C31HG905086,BMW,5
850,56990,2017,7890,Arlington, TX,5UXKR0C58H0U51824,BMW,X5xDrive35i
851,55620,2017,6185,Mobile, AL,WBA4U9C33H5H64455,BMW,4
852,59991,2017,15348,Charlotte, NC,5UXKR0C57H0V67516,BMW,X5xDrive35i
853,14300,2014,7544,Waterford, MI,1G4PN5SK0E4160578,Buick,VeranoBase
854,13991,2015,31916,Costa Mesa, CA,KL4CJASB6FB135182,Buick,EncoreFWD
855,16800,2015,15855,Melbourne, FL,KL4CJASB6FB123761,Buick,EncoreFWD
856,5995,2007,116419,Abington, MA,3G5DA03LX7S586169,Buick,RendezvousFWD
857,7990,2007,52399,Golden Valley, MN,1G4HP572X7U130157,Buick,Lucerne4dr
858,7950,2009,88000,Seattle, WA,2G4WD582891140456,Buick,LaCrosse4dr
859,16253,2013,43494,Lewes, DE,1G4GF5E36DF165045,Buick,LaCrosseFWD
860,10988,2009,48890,Cincinnati, OH,1G4HD57M59U121024,Buick,Lucerne4dr
861,7988,2008,78880,Manassas, VA,1G4HD57268U127671,Buick,Lucerne4dr
862,18898,2014,33098,Tinley Park, IL,1G4GB5G32EF181527,Buick,LaCrosseFWD
863,16440,2014,36973,CREVE COEUR, MO,2G4GK5EX9E9269733,Buick,RegalTurbo
864,8500,2006,83940,Muncie, IN,1G4HD57256U239746,Buick,Lucerne4dr
865,13888,2011,84151,San Antonio, TX,5GAKRBED1BJ363850,Buick,EnclaveFWD
866,16981,2012,73562,Addison, TX,5GAKRCED8CJ100648,Buick,EnclaveLeather
867,2995,2002,121002,Fort Benton, MT,2G4WY55J921177683,Buick,Century4dr
868,13294,2009,121339,Punta Gorda, FL,5GAEV23D29J113152,Buick,EnclaveAWD
869,25490,2014,29848,Duluth, GA,5GAKRBKDXEJ340639,Buick,EnclaveLeather
870,24981,2014,55770,League City, TX,5GAKRBKD6EJ365277,Buick,EnclaveLeather
871,10395,2009,82119,Columbus, OH,1G4HP57M39U130800,Buick,Lucerne4dr
872,25500,2013,68526,Kansas City, KS,5GAKVDKD6DJ236106,Buick,EnclavePremium
873,23988,2014,47761,Coon Rapids, MN,1G4GF5G34EF195417,Buick,LaCrosseFWD
874,34848,2017,16500,Las Cruces, NM,5GAKRBKD6HJ172048,Buick,EnclaveLeather
875,37488,2017,32548,Savannah, MO,5GAKVCKD7HJ190348,Buick,EnclavePremium
876,40635,2017,4081,Miami, FL,LRBFXBSA3HD020169,Buick,EnvisionFWD
877,17222,2013,52198,Naples, FL,1G6AJ5S36D0123204,Cadillac,ATS3.6L
878,18600,2014,48392,Dallas, TX,1G6AB5RX1E0155811,Cadillac,ATS2.0L
879,22000,2014,40624,Lowell, NC,1G6AB5RA9E0154305,Cadillac,ATS2.5L
880,17500,2012,56154,Glen Burnie, MD,3GYFNGE31CS655508,Cadillac,SRXFWD
881,9990,2012,121438,Tampa, FL,1G6DA5E57C0145852,Cadillac,CTS
882,21995,2012,30103,Westborough, MA,3GYFNAE3XCS516037,Cadillac,SRXFWD
883,26290,2016,36723,Kennesaw, GA,3GYFNBE33GS531376,Cadillac,SRXLuxury
884,27998,2014,21176,Houston, TX,1G6DD1E33E0169731,Cadillac,CTS
885,30499,2016,17463,McKinney, TX,3GYFNBE33GS506767,Cadillac,SRXLuxury
886,28989,2015,31572,Orem, UT,3GYFNEE38FS616679,Cadillac,SRXLuxury
887,23928,2014,46821,Albert Lea, MN,1G6AX5SX0E0131463,Cadillac,CTS
888,28995,2015,16591,Pelham, AL,3GYFNBE31FS640076,Cadillac,SRXLuxury
889,9503,2006,118610,Atlanta, GA,1GYEC63N86R141209,Cadillac,Escalade4dr
890,29900,2014,20273,Englewood Cliffs, NJ,3GYFNEE39ES624675,Cadillac,SRXLuxury
891,30995,2016,7175,Orlando, FL,1G6AR5SX4G0122407,Cadillac,CTS
892,32594,2015,34796,Fredericktown, MO,3GYFNGE39FS574423,Cadillac,SRXPremium
893,28999,2016,24745,Hudson, MA,2G61N5S31G9145061,Cadillac,XTSAWD
894,38280,2017,6628,Van Wert, OH,1GYKNDRS9HZ192929,Cadillac,XT5Luxury
895,15000,2012,61839,Alexandria, VA,1G6DC5E58C0113860,Cadillac,CTS
896,20995,2009,116882,Brooklyn Center, MN,1GYFK23229R255877,Cadillac,EscaladeAWD
897,19988,2008,114676,Englewood, CO,1GYFK63868R219670,Cadillac,EscaladeAWD
898,23999,2014,32259,Salinas, CA,3GYFNBE3XES520100,Cadillac,SRXLuxury
899,25998,2009,71330,Abilene, TX,1GYFK23219R194344,Cadillac,EscaladeAWD
900,26988,2009,71551,Englewood, CO,1GYFK13259R234810,Cadillac,EscaladeAWD
901,39888,2017,11078,Ocala, FL,2G61M5S32H9142562,Cadillac,XTSFWD
902,28888,2017,19800,Richardson, TX,2G61M5S32H9145414,Cadillac,XTSFWD
903,35000,2011,76947,Fuquay-Varina, NC,1G6DV5EPXB0169437,Cadillac,CTS-V
904,57891,2015,33507,Jacksonville, FL,1GYS4BKJ3FR186484,Cadillac,Escalade4WD
905,76230,2016,6913,Philadelphia, PA,1G6A15S60G0158934,Cadillac,CTS-V
906,10700,2015,24236,Princeton, NJ,1G1PB5SG6F7285330,Chevrolet,CruzeLS
907,10900,2011,18001,Belton, MO,1G1ZA5EU5BF368258,Chevrolet,Malibu
908,14500,2014,12519,St Louis, MO,1G11C5SL4EF174599,Chevrolet,Malibu1LT
909,9977,2014,70561,Villa Park, IL,1G11B5SL7EF106817,Chevrolet,MalibuLS
910,17233,2017,25076,Fort Lauderdale, FL,1G1ZE5ST1HF149502,Chevrolet,MalibuLT
911,8575,2012,47036,Allentown, PA,1G1PF5SC4C7361740,Chevrolet,CruzeSedan
912,16733,2017,34415,Lakewood, WA,1G1ZE5ST5HF121654,Chevrolet,MalibuLT
913,17233,2017,29601,Saint Paul, MN,1G1ZE5ST3HF116307,Chevrolet,MalibuLT
914,17933,2017,21580,New Orleans, LA,1G1ZE5ST4HF207991,Chevrolet,MalibuLT
915,12500,2015,40580,Warrenton, VA,1G1PG5SB0F7184676,Chevrolet,CruzeLTZ
916,18533,2017,17199,Sandy, UT,1G1ZE5ST6HF174265,Chevrolet,MalibuLT
917,18533,2017,17982,Lakewood, WA,1G1ZE5ST3HF172585,Chevrolet,MalibuLT
918,18533,2017,18407,San Mateo, CA,1G1ZE5ST3HF199897,Chevrolet,MalibuLT
919,18533,2017,19810,Lawrenceville, GA,1G1ZE5ST0HF199338,Chevrolet,MalibuLT
920,14989,2016,18576,Brook Park, OH,1G11B5SA4GF133927,Chevrolet,Malibu
921,11800,2014,42012,Cedar Rapids, IA,1G1PC5SB2E7357267,Chevrolet,Cruze1LT
922,8584,2011,53918,Garland, TX,1G1PF5S9XB7109896,Chevrolet,Cruze4dr
923,11988,2013,64780,North Dartmouth, MA,1G11D5RR6DF121747,Chevrolet,MalibuECO
924,14737,2016,20292,Seymour, IN,1G1BE5SM8G7262902,Chevrolet,CruzeLT
925,15206,2017,22941,Ivel, KY,1G1BE5SM7H7100096,Chevrolet,CruzeSedan
926,18679,2017,11705,Virgina Beach, VA,1G1ZE5ST2HF151209,Chevrolet,MalibuLT
927,4100,2010,83647,Portsmouth, VA,KL1TD5DE2AB113790,Chevrolet,Aveo4dr
928,13995,2016,41324,Elizabeth City, NC,1G11C5SA9GF148243,Chevrolet,Malibu
929,13500,2015,22561,Merriam, KS,1G1PC5SB3F7121759,Chevrolet,Cruze1LT
930,14195,2014,24549,Coon Rapids, MN,1G1PE5SBXE7258822,Chevrolet,Cruze2LT
931,15000,2016,24799,Austin, TX,1G1BE5SM0G7326656,Chevrolet,CruzeLT
932,7500,2011,73246,Alliance, OH,1G1PC5SH5B7133023,Chevrolet,Cruze4dr
933,9803,2013,63278,Gladstone, OR,1G1PA5SG5D7213678,Chevrolet,CruzeLS
934,6494,2011,115116,Lawrenceville, NJ,1G1ZB5E19BF159253,Chevrolet,Malibu4dr
935,11995,2013,66280,Philadelphia, PA,1G11D5RR7DF106285,Chevrolet,MalibuECO
936,13999,2014,46942,Granite City, IL,2GNALAEK1E6345054,Chevrolet,EquinoxFWD
937,13924,2016,22225,Livonia, MI,3GNCJKSB6GL219747,Chevrolet,TraxFWD
938,13830,2015,15437,Lake Park, FL,3GNCJKSB0FL199669,Chevrolet,TraxFWD
939,8000,2011,69668,Sunrise, FL,1G1PC5SH4B7267330,Chevrolet,Cruze4dr
940,18990,2016,18326,Derby, KS,1G1ZE5ST4GF356013,Chevrolet,MalibuLT
941,9700,2014,85319,Dallas, TX,1G1PE5SB8E7335686,Chevrolet,Cruze2LT
942,16772,2013,34487,Tucson, AZ,2GNALDEKXD6236768,Chevrolet,EquinoxFWD
943,12894,2014,77584,Union City, GA,2GNALAEK5E1129107,Chevrolet,EquinoxFWD
944,21495,2017,986,El Paso, TX,2GNALBEK6H1542471,Chevrolet,EquinoxFWD
945,9891,2011,79488,Pinconning, MI,2CNALDEC1B6237897,Chevrolet,EquinoxFWD
946,15725,2016,21266,Rainbow City, AL,3GNCJKSB9GL266027,Chevrolet,TraxFWD
947,16900,2016,34614,Chicago, IL,1G11C5SA4GU162160,Chevrolet,Malibu
948,8222,2010,97332,Spencerport, NY,2CNALDEW7A6202290,Chevrolet,EquinoxFWD
949,15871,2015,23993,Tallmadge, OH,3GNCJRSBXFL204269,Chevrolet,TraxAWD
950,12979,2011,57954,Columbia, SC,2CNALDEC4B6219796,Chevrolet,EquinoxFWD
951,16497,2016,34546,Columbus, OH,1G11E5SA0GF103296,Chevrolet,Malibu
952,14595,2014,71206,Allentown, PA,1G11B5SL3EF190330,Chevrolet,MalibuLS
953,18500,2015,47327,Kansas City, MO,1G11G5SX4FF211661,Chevrolet,Malibu2LTZ
954,4999,2009,97693,Spring, TX,1G1AT18H297215582,Chevrolet,Cobalt2dr
955,11000,2011,91590,Savannah, GA,2CNALDECXB6468670,Chevrolet,EquinoxFWD
956,9555,2015,44092,Phoenix, AZ,1G1JC6SG4F4210894,Chevrolet,SonicHatch
957,21905,2017,13420,Mission, TX,2GNALBEK3H1524798,Chevrolet,EquinoxFWD
958,18995,2013,12920,Midlothian, VA,1GNFLEEK7DZ132111,Chevrolet,EquinoxAWD
959,8995,2014,36307,Memphis, TN,1G1JA6SH7E4128090,Chevrolet,SonicHatchback
960,12881,2015,12987,Gadsden, AL,1G1JC6SH2F4183834,Chevrolet,SonicHatch
961,15688,2015,83508,SHAKOPEE, MN,2GNALCEK9F6322571,Chevrolet,EquinoxFWD
962,17500,2015,52843,Wolcott, NY,2GNFLFEK0F6416166,Chevrolet,EquinoxAWD
963,22994,2016,18139,Watertown, NY,2GNFLFEK4G6359729,Chevrolet,EquinoxAWD
964,12995,2016,16829,Graham, NC,1G1PE5SB1G7182197,Chevrolet,Cruze
965,14980,2013,73589,Austin, TX,2GNALBEK5D1264947,Chevrolet,EquinoxFWD
966,11495,2015,42024,LITCHFIELD, IL,1G1JC5SH6F4115093,Chevrolet,SonicSedan
967,17999,2014,57009,Dover, DE,2GNFLFE34E6185490,Chevrolet,EquinoxAWD
968,22998,2017,12706,Highland, IN,2GNALCEK9H6105198,Chevrolet,EquinoxFWD
969,13999,2013,77433,Brooksville, FL,1G1PG5SBXD7283633,Chevrolet,CruzeLTZ
970,11900,2014,29219,Glendale, CA,1G1JE6SB5E4192919,Chevrolet,SonicHatchback
971,12588,2014,125084,Conroe, TX,2GNALCEK3E6191796,Chevrolet,EquinoxFWD
972,14100,2016,39912,Columbia, MO,1G1PG5SB1G7120650,Chevrolet,Cruze
973,15988,2014,27400,Chandler, AZ,2G1FA1E35E9126375,Chevrolet,CamaroCoupe
974,6555,2012,109689,Pontiac, MI,2G1WF5E37C1192446,Chevrolet,ImpalaLS
975,7440,2009,86199,Tinley Park, IL,2G1WT57N991269849,Chevrolet,Impala4dr
976,4000,2010,168399,Crittenden, KY,1G1AB5F54A7132789,Chevrolet,Cobalt4dr
977,8000,2009,96919,Columbus, OH,2G1WT57K291210003,Chevrolet,Impala4dr
978,16988,2015,36781,Surprise, AZ,2G1FB1E32F9160126,Chevrolet,CamaroCoupe
979,17500,2016,40035,Watertown, CT,2G1115S36G9126569,Chevrolet,Impala2LT
980,17725,2016,36476,Clearwater, FL,2G1115S37G9147432,Chevrolet,Impala2LT
981,6494,2012,110860,Wichita, KS,1G1PD5SH6C7231878,Chevrolet,CruzeSedan
982,15894,2014,55082,St. Peters, MO,1G1115SL0EU160278,Chevrolet,ImpalaLT
983,12095,2013,79451,Aurora, IL,2G1WG5E34D1184500,Chevrolet,ImpalaLT
984,14777,2013,60792,Lubbock, TX,2G1FB1E30D9122407,Chevrolet,CamaroCoupe
985,12500,2010,74936,Troy, MO,1GCPCPEX4AZ238628,Chevrolet,Silverado
986,9274,2013,91658,Milwaukie, OR,1G1PC5SB5D7159264,Chevrolet,Cruze1LT
987,16995,2014,24295,Colmar, PA,1G1RB6E41EU157237,Chevrolet,VoltPlug-In
988,13000,2011,80063,Tampa, FL,2G1FC3DD2B9170488,Chevrolet,Camaro2dr
989,20320,2015,21912,Jonesboro, GA,2G1FD1E38F9306734,Chevrolet,CamaroCoupe
990,14980,2013,42516,Saint Helens, OR,1G1RD6E48DU128312,Chevrolet,VoltBase
991,8768,2012,143749,Memphis, TN,2G1WG5E34C1171972,Chevrolet,ImpalaLT
992,9980,2012,85216,Columbia, KY,1G1ZB5E08CF189689,Chevrolet,MalibuLS
993,9990,2009,81598,Orland Park, IL,1GNER13D39S147552,Chevrolet,TraverseFWD
994,19950,2016,48692,Worthington, OH,2G1115S34G9133598,Chevrolet,Impala2LT
995,22988,2017,38260,Topeka, KS,2G1105S3XH9105812,Chevrolet,ImpalaLT
996,2960,2002,110282,Columbus, OH,1G1JF524327218498,Chevrolet,Cavalier4dr
997,15995,2016,18833,Pueblo, CO,1G1ZB5ST7GF352871,Chevrolet,MalibuLS
998,10800,2011,108404,Marion, IL,1GNKREED6BJ154076,Chevrolet,TraverseFWD
999,25200,2017,15344,Montebello, CA,2G1145S3XH9133663,Chevrolet,ImpalaPremier
1000,12500,2013,98228,Houston, TX,1GNKRJKD9DJ243978,Chevrolet,Traverse2LT
1001,16500,2009,62832,Wilkes-Barre, PA,1GCEC19C59Z265957,Chevrolet,Silverado
1002,16754,2011,71702,Pharr, TX,1GCRCREA2BZ208738,Chevrolet,Silverado
1003,10900,2014,89668,Pocatello, ID,1G11C5SL3EF199381,Chevrolet,Malibu1LT
1004,13908,2008,93005,Austin, TX,2GCEC19C081313484,Chevrolet,Silverado
1005,26777,2017,53,Honolulu, HI,1GCGSBEA6H1247292,Chevrolet,ColoradoCrew
1006,25205,2017,17096,Tucson, AZ,1GNKRGKDXHJ218757,Chevrolet,TraverseFWD
1007,7900,2011,175265,Cadiz, KY,2G1WC5EM4B1106283,Chevrolet,Impala4dr
1008,8975,2006,99890,New Philadelphia, OH,1GCDT148568262590,Chevrolet,Colorado4WD
1009,12655,2012,129855,Killeen, TX,1GNKRLED9CJ168804,Chevrolet,TraverseFWD
1010,24495,2017,25044,Gainesville, FL,1GNKRGKD8HJ192742,Chevrolet,TraverseFWD
1011,13999,2011,89002,Puyallup, WA,1GNKRGED2BJ361205,Chevrolet,TraverseFWD
1012,21998,2010,17485,Limerick, PA,2G1FT1EW2A9126087,Chevrolet,Camaro2dr
1013,24888,2015,25458,Columbia, KY,1GCVKPEH0FZ206785,Chevrolet,Silverado
1014,17500,2008,66155,State College, PA,2GCEK19J181138342,Chevrolet,Silverado
1015,19476,2011,62325,Lexington, TN,2G1FJ1EJXB9145661,Chevrolet,Camaro2dr
1016,9750,2010,119932,Rochester, MN,2CNALPEW1A6292263,Chevrolet,EquinoxFWD
1017,6994,2005,149445,Cincinnati, OH,1GCDS136158109528,Chevrolet,Colorado2WD
1018,9750,2006,143854,Olive Branch, MS,2GCEC13VX61230491,Chevrolet,Silverado
1019,27997,2015,19286,Midlothian, VA,2G1FK1EJ7F9152571,Chevrolet,CamaroCoupe
1020,17995,2016,39538,Taylor, MI,1G1ZE5ST0GF287756,Chevrolet,MalibuLT
1021,19995,2011,70122,Noblesville, IN,3GCPKSEA8BG207031,Chevrolet,Silverado
1022,27995,2017,15700,National City, CA,1GNKRGKD8HJ191767,Chevrolet,TraverseFWD
1023,7995,2009,118460,Columbia, SC,3GNCA23B59S550956,Chevrolet,HHRFWD
1024,7999,2000,128788,Raleigh, NC,1GAGG25R0Y1145521,Chevrolet,Express
1025,8750,2005,133367,Oconomowoc, WI,1GNEK13T95J135594,Chevrolet,Tahoe4dr
1026,16598,2011,113000,Sacramento, CA,2G1FJ1EJ8B9191876,Chevrolet,Camaro2dr
1027,27000,2014,48850,Chesapeake, VA,1GCVKREC9EZ220568,Chevrolet,Silverado
1028,26991,2013,36440,San Antonio, TX,3GCPCSE04DG149152,Chevrolet,Silverado
1029,25296,2015,37777,La Grange, KY,1GNKVGKD2FJ122775,Chevrolet,Traverse1LT
1030,22499,2011,65914,Midlothian, VA,1GCRKSE3XBZ319243,Chevrolet,Silverado
1031,13695,2012,33891,Midlothian, IL,2G1WG5E31C1192410,Chevrolet,ImpalaLT
1032,29987,2015,20854,Concord, NH,1GCVKPEC2FZ395055,Chevrolet,Silverado
1033,12427,2012,94860,Columbia, IL,2GNFLEE50C6247265,Chevrolet,EquinoxAWD
1034,16999,2011,137851,Missouri Valley, IA,1GCRKSE35BZ133917,Chevrolet,Silverado
1035,22900,2010,59000,Lincoln, NE,3GCRKSE3XAG156978,Chevrolet,Silverado
1036,27995,2014,24387,Houston, TX,1GCVKREH7EZ178686,Chevrolet,Silverado
1037,24988,2014,63703,Baton Rouge, LA,1GCRCREC0EZ370907,Chevrolet,Silverado
1038,24900,2015,72426,Lubbock, TX,3GCUKREC9FG392628,Chevrolet,Silverado
1039,26988,2014,34816,Memphis, TN,2G1FK1EJ7E9249624,Chevrolet,CamaroCoupe
1040,26995,2014,52716,Leesville, LA,3GCPCREC0EG143840,Chevrolet,Silverado
1041,4200,2001,206254,Arlington, TX,2GCEC19T911226575,Chevrolet,Silverado
1042,18390,2011,124199,Mooresville, NC,1GCRKSE35BZ241079,Chevrolet,Silverado
1043,8995,2004,179049,Defiance, OH,1GCEK14T74E104360,Chevrolet,Silverado
1044,10000,2007,205495,Plainfield, IN,2GCEK13M171700631,Chevrolet,Silverado
1045,24991,2013,76352,Lake Wales, FL,1GCRKSE75DZ341592,Chevrolet,Silverado
1046,21995,2011,85486,Houston, TX,3GCPCSE08BG395117,Chevrolet,Silverado
1047,30855,2016,26502,Dundee, MI,1GCVKREC0GZ148680,Chevrolet,Silverado
1048,7999,2008,231500,Akron, OH,2GCEK19J681248870,Chevrolet,Silverado
1049,33975,2017,12402,South River, NJ,1GC2KUEG1HZ243310,Chevrolet,Silverado
1050,12477,2016,46985,Landover, MD,1G1PE5SBXG7203435,Chevrolet,Cruze
1051,16764,2007,90424,Frankfort, KY,1GNFK13087J153839,Chevrolet,Tahoe4WD
1052,32807,2015,17477,Corpus Christi, TX,1GCVKREC9FZ315178,Chevrolet,Silverado
1053,22100,2012,119160,North Little Rock, AR,3GCPKSE76CG234413,Chevrolet,Silverado
1054,32456,2016,18031,Lexington, KY,1G1FF1R77G0155937,Chevrolet,CamaroCoupe
1055,23130,2013,110485,Houston, TX,3GCPKSE70DG338641,Chevrolet,Silverado
1056,30995,2014,26899,Chelsea, MI,3GCUKREC1EG446521,Chevrolet,Silverado
1057,6995,2008,78222,Wakefield, MA,2G1WT58K989174292,Chevrolet,Impala4dr
1058,28500,2014,58220,St. Augustine, FL,3GCPCREC7EG199502,Chevrolet,Silverado
1059,21995,2005,54317,Lawrenceville, GA,1GCEK19B75Z239008,Chevrolet,Silverado
1060,7495,2005,146341,Lexington, KY,3GNEK12Z25G177015,Chevrolet,Avalanche1500
1061,18800,2012,52418,Santa Rosa, CA,1GCDSCF95C8108641,Chevrolet,ColoradoCrew
1062,30555,2015,53539,Newark, DE,3GCUKREC4FG169008,Chevrolet,Silverado
1063,13995,2011,124749,Woodbridge, VA,1GCWGFBG3B1119659,Chevrolet,Express
1064,24000,2009,109891,Brooklyn Park, MN,1GCHK63K79F137735,Chevrolet,Silverado
1065,33994,2015,21519,Watseka, IL,3GCUKREC4FG235170,Chevrolet,Silverado
1066,25880,2013,78416,Mcgregor, TX,1GNSCBE07DR320848,Chevrolet,TahoeLT
1067,29725,2013,69437,Tampa, FL,3GCPKSE71DG336400,Chevrolet,Silverado
1068,22900,2012,127792,Hattiesburg, MS,1GCPKTE75CF146038,Chevrolet,Silverado
1069,31497,2015,22609,Phoenix, AZ,1GCGTCE37F1209023,Chevrolet,ColoradoCrew
1070,9995,2007,184706,Farmers Branch, TX,3GNFC16067G210498,Chevrolet,Suburban2WD
1071,33333,2014,40546,London, OH,3GCUKREC4EG420169,Chevrolet,Silverado
1072,32991,2016,24879,McKinney, TX,1GCGTDE31G1248326,Chevrolet,ColoradoCrew
1073,7500,2011,63705,Belleville, IL,3GNBABFW1BS646941,Chevrolet,HHRFWD
1074,37995,2017,4534,Southgate, MI,1G1FH1R72H0107757,Chevrolet,CamaroCoupe
1075,19995,2013,110045,Houston, TX,3GNMCEE09DG105207,Chevrolet,AvalancheLS
1076,34980,2015,49090,Cokato, MN,3GCUKSEC8FG138626,Chevrolet,Silverado
1077,22990,2016,33316,Anaheim, CA,1G1FB1RS5G0148808,Chevrolet,CamaroCoupe
1078,7400,2000,123490,Crystal Lake, IL,1GCGC23J4YF441323,Chevrolet,C/K
1079,33400,2014,61651,Springdale, AR,3GCUKSEC8EG470712,Chevrolet,Silverado
1080,27600,2015,60739,Harrisonville, MO,1GC2KUEG6FZ556480,Chevrolet,Silverado
1081,18887,2001,44477,Longview, TX,1G1YY22G215103130,Chevrolet,Corvette2dr
1082,40737,2017,17060,Santa Fe, NM,3GCUKSEC1HG166478,Chevrolet,Silverado
1083,19988,2014,56340,Henderson, KY,2G1FB3D33E9258125,Chevrolet,CamaroConvertible
1084,18998,2008,102054,Houston, TX,3GNFK12348G255704,Chevrolet,Avalanche4WD
1085,41928,2016,14111,Clarksville, TN,3GCUKSEC9GG384246,Chevrolet,Silverado
1086,21399,2007,129411,Bedford, OH,1GCHK23D57F102649,Chevrolet,Silverado
1087,39930,2017,4829,Colorado Springs, CO,1GCPTDE14H1168681,Chevrolet,ColoradoCrew
1088,39888,2015,25898,Nampa, ID,3GCUKSEJ6FG249223,Chevrolet,Silverado
1089,41215,2015,15055,Clinton, MO,3GCUKSEC6FG524624,Chevrolet,Silverado
1090,16995,2011,106769,Waukegan, IL,1GNKVLED9BJ153715,Chevrolet,TraverseAWD
1091,35234,2015,68370,Ft Walton Beach, FL,1GNSCBKC2FR653332,Chevrolet,Tahoe2WD
1092,23991,2011,45109,Omaha, NE,3GCPKSE36BG382170,Chevrolet,Silverado
1093,21995,2013,70103,Meridian, MS,1GCRCSEA2DZ228031,Chevrolet,Silverado
1094,26489,2017,23370,Pittsburgh, PA,1GNKVGKD7HJ125268,Chevrolet,TraverseAWD
1095,18980,1999,75655,Clinton, MO,1G1YY22G1X5107162,Chevrolet,Corvette2dr
1096,32000,2017,3034,PERU, IL,3GCUKNEH3HG115710,Chevrolet,Silverado
1097,37950,2015,59490,Houston, TX,1GNSCBKC5FR604691,Chevrolet,Tahoe2WD
1098,39844,2015,62989,PEARLAND, TX,1GNSCCKCXFR681255,Chevrolet,Tahoe2WD
1099,50999,2017,8068,Portsmouth, NH,3GCUKTEC1HG322637,Chevrolet,Silverado
1100,30999,2014,63749,Charlotte, NC,3GCUKSEC1EG212399,Chevrolet,Silverado
1101,49991,2017,23275,Lakeland, FL,1GNSCHKC9HR216336,Chevrolet,Suburban2WD
1102,49000,2014,4151,Shreveport, LA,1G1YF3D75E5116453,Chevrolet,Corvette
1103,48385,2016,61848,Pataskala, OH,1GC1KWE85GF105494,Chevrolet,Silverado
1104,57000,2016,14420,Tampa, Fl,1G1YM3D72G5118539,Chevrolet,CorvetteStingray
1105,48995,2016,13196,Franklin, TN,1GNSKBKC5GR141889,Chevrolet,Tahoe4WD
1106,54088,2016,62826,Mt. Juliet, TN,1GC4K1E86GF230701,Chevrolet,Silverado
1107,63595,2017,2080,Delta, UT,1GC4K0EY6HF176649,Chevrolet,Silverado
1108,12045,2016,40112,Vero Beach, FL,1C3CCCAB2GN177446,Chrysler,200Limited
1109,12845,2016,36515,Escondido, CA,1C3CCCABXGN176741,Chrysler,200Limited
1110,11066,2015,46918,Newburgh, NY,1C3CCCAB1FN733910,Chrysler,200Limited
1111,13994,2015,21431,San Jose, CA,1C3CCCBG5FN516015,Chrysler,200S
1112,6891,2007,174539,Oklahoma City, OK,1A8HX58207F555208,Chrysler,Aspen2WD
1113,9677,2012,54976,Olathe, KS,1C3CCBBG3CN288487,Chrysler,2004dr
1114,13988,2016,31259,Reno, NV,1C3CCCAB9GN120001,Chrysler,200Limited
1115,13854,2015,35297,Little Rock, AR,1C3CCCAB3FN705896,Chrysler,200Limited
1116,15990,2015,30191,Columbus, MS,1C3CCCAB0FN529552,Chrysler,200Limited
1117,10995,2013,66982,Midlothian, IL,1C3CCBBB7DN693757,Chrysler,200Sedan
1118,13993,2014,62693,Shelbyville, KY,2C3CCAAG5EH330097,Chrysler,300300
1119,24595,2017,13347,Kenosha, WI,2C4RC1DG4HR515399,Chrysler,PacificaTouring
1120,16950,2013,46375,Somersworth, NH,2C3CCARGXDH603702,Chrysler,300300
1121,7917,2005,85249,Bellevue, WA,2C3AK63H55H672341,Chrysler,3004dr
1122,14995,2013,56597,Lithia Springs, GA,1C3CCBBB1DN674685,Chrysler,200Sedan
1123,14895,2015,82683,Dallas, TX,1C3CCCAB4FN684024,Chrysler,200Limited
1124,27223,2017,31452,Virginia Beach, VA,2C4RC1BG5HR569264,Chrysler,PacificaTouring-L
1125,23245,2016,36269,Raynham, MA,2C3CCAKG0GH198495,Chrysler,300300C
1126,17985,2016,43947,Kernersville, NC,2C4RC1BGXGR281187,Chrysler,Town
1127,17785,2014,33296,Houston, TX,2C4RC1BG4ER242849,Chrysler,Town
1128,15777,2015,61717,Mooresville, NC,2C4RC1BG6FR544659,Chrysler,Town
1129,7695,2008,102858,East Syracuse, NY,2A8HR54P88R687185,Chrysler,Town
1130,23997,2014,47224,Fort Smith, AR,2C3CCAJG2EH240423,Chrysler,300John
1131,5345,2008,148068,Milwaukee, WI,2A8HR44H68R707551,Chrysler,Town
1132,3688,2007,99726,Albemarle, NC,3A4FY58B87T615909,Chrysler,PT
1133,20696,2014,40767,Twin Falls, ID,2C4RC1HG1ER232805,Chrysler,Town
1134,15500,2016,46553,Long Beach, CA,1C3CCCAB8GN154219,Chrysler,200Limited
1135,2988,2005,172053,Jacksonville, FL,3C3AY75S15T284124,Chrysler,PT
1136,15944,2015,45338,Houston, TX,1C3CCCBG7FN518235,Chrysler,200S
1137,27400,2017,28514,Abilene, TX,2C4RC1BGXHR513157,Chrysler,PacificaTouring-L
1138,5000,2002,90273,Iowa City, IA,2C8GP64L62R788470,Chrysler,Town
1139,8571,2013,53598,OKARCHE, OK,1C3CDZAB7DN516858,Dodge,AvengerSE
1140,10999,2014,66835,Murfreesboro, TN,1C3CDZAB9EN173391,Dodge,AvengerSE
1141,4990,2010,104764,Cape Girardeau, MO,1B3CB4HA1AD546958,Dodge,CaliberSXT
1142,6399,2008,103351,West Bridgewater, MA,2B3KA43G08H232127,Dodge,Charger4dr
1143,20879,2016,38319,Norfolk, VA,2C3CDXHG3GH196280,Dodge,ChargerSXT
1144,11759,2015,9843,Lugoff, SC,1C3CDFBB1FD337690,Dodge,DartSXT
1145,11824,2016,28579,Victorville, CA,1C3CDFBAXGD652383,Dodge,DartSXT
1146,11000,2016,49184,Tempe, AZ,1C3CDFBB1GD689699,Dodge,DartSXT
1147,17575,2016,5517,Corpus Christi, TX,3C4PDCABXGT104187,Dodge,JourneyFWD
1148,10500,2015,60284,Tinley Park, IL,1C3CDFBB2FD226887,Dodge,DartSXT
1149,13995,2015,17030,Carmel, IN,1C3CDFEB7FD279077,Dodge,DartGT
1150,11499,2014,60247,Fayetteville, NC,1C3CDFBBXED747192,Dodge,DartSXT
1151,16500,2015,44757,Nottingham, MD,2C3CDZAG7FH821025,Dodge,ChallengerSXT
1152,13295,2016,41440,Las Vegas, NV,1C3CDFBB0GD661389,Dodge,DartSXT
1153,14000,2015,32465,Highland, IN,1C3CDFEB1FD141700,Dodge,DartGT
1154,7995,2015,99713,Sacramento, CA,1C3CDFAA8FD390248,Dodge,DartSE
1155,20199,2017,3629,Carbondale, IL,3C4PDCAB8HT587423,Dodge,JourneySE
1156,8995,2012,109505,Orem, UT,3C4PDCAB1CT262413,Dodge,JourneyFWD
1157,15997,2013,37653,Woburn, MA,3C4PDDDG1DT541908,Dodge,JourneyCrew
1158,17357,2011,115015,Humble, TX,2B3CL5CT2BH504783,Dodge,Charger4dr
1159,18529,2015,32098,Dublin, OH,3C4PDDEG1FT666151,Dodge,JourneyR/T
1160,18500,2016,28428,St George, UT,2C4RDGCG1GR374310,Dodge,Grand
1161,24810,2017,906,Silver Spring, MD,2C3CDZAG3HH622606,Dodge,ChallengerSXT
1162,13583,2012,64976,Waterford Township, MI,3C4PDDFGXCT354419,Dodge,JourneyAWD
1163,10975,2013,81368,Waterbury, CT,2C4RDGBG9DR788822,Dodge,Grand
1164,24999,2016,5578,Lawrenceville, GA,2C3CDZBT2GH108434,Dodge,ChallengerR/T
1165,11875,2013,81892,Cherry Hill, NJ,2C4RDGBG7DR568126,Dodge,Grand
1166,20295,2017,28197,Coon Rapids, MN,2C4RDGCG8HR547225,Dodge,Grand
1167,10849,2013,109345,Ft Walton Beach, FL,2C4RDGBG8DR662760,Dodge,Grand
1168,20999,2017,6744,Chicago, IL,2C4RDGCG6HR713709,Dodge,Grand
1169,12695,2015,91255,Fort Wayne, IN,2C4RDGBG6FR639934,Dodge,Grand
1170,17490,2016,45983,Rosedale, MD,2C4RDGCG6GR318654,Dodge,Grand
1171,22259,2013,33165,Alpharetta, GA,2C3CDYBT2DH591445,Dodge,ChallengerR/T
1172,13595,2013,78608,Richmond, VA,2C4RDGBG8DR791193,Dodge,Grand
1173,20995,2017,32590,Houston, TX,2C4RDGCG0HR545663,Dodge,Grand
1174,7995,2008,122745,Houston, TX,1D8GT58618W203341,Dodge,NitroR/T
1175,21988,2010,21244,Cincinnati, OH,2B3CJ5DT8AH319575,Dodge,Challenger2dr
1176,19989,2016,42972,Punta Gorda, FL,2C4RDGCG4GR361860,Dodge,Grand
1177,24994,2017,13765,Souderton, PA,2C4RDGEG2HR740628,Dodge,Grand
1178,11999,2006,113000,Fort Lauderdale, FL,3D7KR19D16G153796,Dodge,Ram
1179,23495,2014,56178,Dothan, AL,2C3CDYBT2EH127153,Dodge,ChallengerR/T
1180,25991,2016,7094,Tucson, AZ,2C4RDGCG9GR385491,Dodge,Grand
1181,37888,2016,8705,Wilsonville, OR,2C3CDXGJ9GH230980,Dodge,ChargerRT
1182,17995,2011,60177,Barneveld, WI,1D4RE2GG4BC639770,Dodge,DurangoAWD
1183,11484,2011,100737,Englewood, CO,1D4PU2GK6BW541367,Dodge,NitroSE
1184,21995,2010,86871,Roseville, CA,1D7RV1CP7AS157785,Dodge,Ram
1185,14900,2015,42207,Orlando, FL,3C4PDCBG3FT701755,Dodge,JourneySXT
1186,23990,2016,29013,Princeton, WV,3C4PDDGG1GT217308,Dodge,JourneyAWD
1187,26433,2016,46864,Houston, TX,1C4RDHDG7GC432806,Dodge,Durango2WD
1188,33900,2015,22850,Houston, TX,2C3CDZFJXFH713566,Dodge,ChallengerR/T
1189,22994,2014,76359,Pelham, AL,1C4RDHEG9EC475281,Dodge,DurangoCitadel
1190,23993,2013,70143,Live Oak, FL,1C4SDHET2DC678476,Dodge,DurangoCitadel
1191,27999,2014,36263,Schaumburg, IL,1C4RDJDG6EC532982,Dodge,DurangoLimited
1192,1850,2002,210000,East Landsdowne, PA,1B4GP24362B634329,Dodge,CaravanSE
1193,17295,2008,94328,Pensacola, FL,1D7HA18218J229679,Dodge,Ram
1194,16500,2001,126452,Eden, NC,1B7MC33731J582061,Dodge,Ram
1195,22657,2014,49249,Vandalia, OH,1C4RDJAG4EC597866,Dodge,DurangoSXT
1196,35423,2015,26082,Houston, TX,1C4SDHCT1FC153687,Dodge,Durango2WD
1197,14795,2016,42990,Houston, TX,ZFBCFXBT2GP411898,FIAT,500XFWD
1198,6995,2013,69881,Nelliston, NY,3C3CFFARXDT574887,FIAT,500Pop
1199,5995,2012,74612,Chantilly, VA,3C3CFFAR3CT313339,FIAT,500Pop
1200,9799,2012,37419,San Antonio, TX,3FAHP0HAXCR189181,Ford,Fusion4dr
1201,13585,2016,35825,Oswego, NY,3FA6P0H79GR212404,Ford,FusionSE
1202,11810,2015,46872,Alexandria, VA,3FA6P0G78FR102170,Ford,FusionS
1203,10925,2015,19279,Raleigh, NC,1FADP3E29FL353911,Ford,FocusSedan
1204,12926,2016,12055,Gaithersburg, MD,1FADP3K24GL357113,Ford,FocusHatchback
1205,11675,2016,34392,Livermore, CA,1FADP3F2XGL285442,Ford,FocusSedan
1206,11900,2015,16869,Coraopolis, PA,1FADP3K29FL213829,Ford,FocusHatchback
1207,9713,2014,33811,Asheboro, NC,1FADP3F24EL190534,Ford,FocusSedan
1208,14694,2014,37104,Branson, MO,3FA6P0HD9ER114881,Ford,FusionSE
1209,5490,2011,112445,Suitland, MD,3FAHP0HA0BR183081,Ford,Fusion4dr
1210,8250,2013,38309,Sandy, UT,3FADP4TJ6DM147165,Ford,FiestaHatchback
1211,10995,2014,18873,Houston, TX,1FADP3E22EL323311,Ford,FocusSedan
1212,8800,2014,57235,El Cajon, CA,3FADP4BJ4EM216969,Ford,FiestaSedan
1213,8299,2015,50053,Raleigh, NC,3FADP4TJ0FM145432,Ford,FiestaHatchback
1214,9989,2013,27220,Charleston, SC,1FADP3F24DL359272,Ford,FocusSedan
1215,11685,2015,38787,Langhorne, PA,1FADP3K24FL275896,Ford,FocusHatchback
1216,10399,2013,71390,Sarasota, FL,3FA6P0HR5DR131526,Ford,FusionSE
1217,9950,2014,49411,Gladstone, OR,1FADP3F24EL312941,Ford,FocusSedan
1218,10888,2014,42852,Libertyville, IL,1FADP3F2XEL223651,Ford,FocusSedan
1219,9998,2014,50140,Burlington, MA,1FADP3K26EL101259,Ford,FocusHatchback
1220,8900,2013,44908,North Hampton, NH,1FADP3K27DL274383,Ford,FocusHatchback
1221,11500,2014,41304,North Little Rock, AR,1FADP3F25EL345446,Ford,FocusSedan
1222,8988,2013,98010,Sacramento, CA,3FA6P0HR3DR171216,Ford,FusionSE
1223,11495,2015,43400,St. Augustine, FL,1FADP3F20FL335683,Ford,FocusSedan
1224,18998,2017,27282,Langhorne, PA,3FA6P0K97HR115147,Ford,FusionTitanium
1225,10997,2012,67288,Sacramento, CA,3FAHP0HG8CR422432,Ford,Fusion4dr
1226,8727,2013,85680,Arundel, ME,1FADP3K29DL206425,Ford,FocusHatchback
1227,8190,2010,89074,Henderson, NV,3FAHP0HA3AR411041,Ford,Fusion4dr
1228,18190,2017,29051,Oklahoma City, OK,3FA6P0LU8HR165347,Ford,FusionHybrid
1229,8998,2012,57262,Hudson, NH,1FAHP3K23CL275997,Ford,Focus5dr
1230,13975,2013,47969,East Petersburg, PA,3FA6P0LU9DR283899,Ford,FusionHybrid
1231,13849,2013,56904,Madison, WI,3FA6P0LU3DR339383,Ford,FusionHybrid
1232,10225,2012,56724,Clarksville, TN,1FAHP3F21CL307758,Ford,Focus4dr
1233,6995,2011,115364,Weymouth, MA,3FAHP0HA5BR184940,Ford,Fusion4dr
1234,6613,2012,100883,Westlake, OH,1FAHP3F22CL214666,Ford,Focus4dr
1235,8900,2012,41287,Universal City, TX,3FADP4AJ5CM194544,Ford,Fiesta4dr
1236,14638,2015,75422,Bountiful, UT,3FA6P0K9XFR162928,Ford,FusionTitanium
1237,11981,2011,61741,Raleigh, NC,3FAHP0DC0BR102698,Ford,Fusion4dr
1238,9595,2012,54093,Pueblo, CO,1FAHP3K20CL343799,Ford,Focus5dr
1239,16990,2016,16632,Philadelphia, PA,1FMCU0G70GUA48570,Ford,EscapeFWD
1240,8580,2014,74648,Port Orchard, WA,3FADP4EJ5EM111188,Ford,FiestaHatchback
1241,12990,2013,47150,Henderson, TX,1FMCU0F73DUA39861,Ford,EscapeS
1242,17187,2017,42404,Colorado Springs, CO,1FMCU0G93HUA69660,Ford,EscapeFWD
1243,16488,2014,16189,Houston, TX,1FMCU0GX7EUE23772,Ford,EscapeSE
1244,6456,2012,103896,Norfork, VA,1FAHP3H25CL190053,Ford,Focus4dr
1245,8699,2014,98968,Phoenix, AZ,1FADP3K29EL139049,Ford,FocusHatchback
1246,13617,2012,43199,Hanford, CA,1FMCU9D78CKC47901,Ford,Escape4WD
1247,16990,2016,31890,Houston, TX,1FMCU9GX5GUB39600,Ford,Escape4WD
1248,17598,2014,16787,Oconomowoc, WI,1FMCU0GXXEUB13387,Ford,EscapeSE
1249,6999,2010,105546,Peoria, AZ,1FMEU6DEXAUA55919,Ford,ExplorerRWD
1250,16999,2014,25459,Bartow, FL,1FMCU0GX2EUD93242,Ford,EscapeSE
1251,14499,2013,65029,Gower, MO,3FA6P0SU3DR297251,Ford,Fusion
1252,20887,2017,24886,Saint Paul, MN,1FMCU9G92HUB46250,Ford,Escape4WD
1253,7800,2009,115076,Allentown, PA,1FMCU93G39KA13501,Ford,Escape4WD
1254,18491,2016,24229,Carrollton, TX,1FA6P8AM9G5202022,Ford,MustangFastback
1255,18900,2014,14878,Kirkwood, MO,1FMCU0JX7EUE23988,Ford,EscapeTitanium
1256,10950,2012,86851,Bastrop, TX,1FAHP3J20CL382054,Ford,Focus4dr
1257,16999,2014,41925,Gower, MO,1FMCU0JX6EUD79174,Ford,EscapeTitanium
1258,17988,2016,35000,Austin, TX,1FA6P8AM9G5256534,Ford,MustangFastback
1259,21111,2017,20958,Madison, CT,1FMCU9GD2HUB71783,Ford,Escape4WD
1260,17978,2013,16680,Old Bridge, NJ,1FADP3L99DL323272,Ford,FocusHatchback
1261,10999,2010,85300,Netcong, NJ,1FMCU9E71AKB90047,Ford,Escape4WD
1262,8995,2010,111097,Birch Run, MI,1FMCU0E73AKA34684,Ford,EscapeFWD
1263,13985,2014,88143,Yarmouth, ME,1FMCU9G95EUA14790,Ford,EscapeSE
1264,17279,2013,79564,Phoenix, AZ,3FA6P0LU9DR264303,Ford,FusionHybrid
1265,22567,2017,22204,Sarasota, FL,1FMCU0J93HUC56146,Ford,EscapeFWD
1266,8995,2011,107936,Pacific, MO,2FMDK3GC1BBA97834,Ford,Edge4dr
1267,14999,2012,44251,Montclair, CA,1ZVBP8AM3C5245609,Ford,Mustang2dr
1268,15995,2014,67182,New Braunfels, TX,1ZVBP8EM6E5243026,Ford,MustangConvertible
1269,8128,2008,112200,Delavan, WI,1FMCU93178KD99026,Ford,Escape4WD
1270,6999,2001,62317,Wait Park, MN,1FAFP44401F202352,Ford,Mustang2dr
1271,24325,2017,15060,Greenwood, IN,1FMCU9J98HUC56308,Ford,Escape4WD
1272,20789,2015,19951,Tallahassee, FL,1FA6P8AMXF5374512,Ford,MustangFastback
1273,13995,2013,77800,Naugatuck, CT,2FMDK4KC9DBB23693,Ford,EdgeLimited
1274,12269,2010,88482,Downers Grove, IL,2FMDK3KC0ABA38425,Ford,Edge4dr
1275,9500,2010,124970,Bloomington, IN,1FMEU7DE9AUB02640,Ford,Explorer4WD
1276,17900,2012,49641,Arlington Heights, IL,2FMDK3JC2CBA67798,Ford,Edge4dr
1277,2999,2000,109080,Totowa, NJ,1FAFP4442YF197620,Ford,Mustang2dr
1278,21987,2016,37253,Mcgregor, TX,1FM5K7B85GGB53871,Ford,ExplorerFWD
1279,18991,2015,51707,Fremont, CA,1FA6P8TH7F5343265,Ford,MustangFastback
1280,22547,2014,14893,Albuquerque, NM,1FM5K7B84EGB89905,Ford,ExplorerBase
1281,11998,2010,115319,Clive, IA,2FMDK4KC4ABA78769,Ford,Edge4dr
1282,12995,2013,60318,Blue Island, IL,1FAHP2F86DG232001,Ford,TaurusLimited
1283,16978,2015,34408,Houston, TX,1FAHP2E87FG129173,Ford,TaurusSEL
1284,13900,2010,78062,Universal City, TX,2FMDK3GC0ABA27367,Ford,Edge4dr
1285,22987,2016,33234,Tacoma, WA,1FATP8UHXG5282108,Ford,MustangConvertible
1286,4990,2006,159165,Collins, MS,1ZVFT80N965262006,Ford,Mustang2dr
1287,8990,2008,68965,Mchenry, IL,1FAHP25W38G171020,Ford,Taurus4dr
1288,12500,2015,35245,Long Beach, CA,3FADP4BJ0FM102081,Ford,FiestaSedan
1289,17990,2013,77148,Laramie, WY,1FM5K8D87DGB70217,Ford,ExplorerXLT
1290,9987,2011,59394,Ontario, OR,1FAHP3FN7BW117776,Ford,Focus4dr
1291,14695,2010,91728,Athens, GA,1FMCU0D71AKB19900,Ford,EscapeFWD
1292,15200,2011,100056,Abilene, TX,1FTFX1CF5BFC13022,Ford,F-1502WD
1293,11991,2011,59483,Littleton, CO,1FMCU0C74BKB78636,Ford,EscapeFWD
1294,24999,2015,18816,Milwaukee, WI,2FMTK4J98FBB25603,Ford,EdgeSEL
1295,26995,2017,14570,Sanford, FL,1FTYR1CM1HKA22280,Ford,Transit
1296,9600,2011,108965,Duluth, GA,1FAHP2KT3BG152422,Ford,Taurus4dr
1297,8988,2013,70435,Mendon, MA,1FADP3K23DL154743,Ford,FocusHatchback
1298,15998,2010,93801,Tulsa, OK,1FTEW1C84AKE49067,Ford,F-1502WD
1299,11900,2009,137750,Lincoln, NE,1FTPX14V79FB16974,Ford,F-1504WD
1300,5850,2003,150821,Spotsylvania, VA,2FTRF18223CA24878,Ford,F-1504WD
1301,12500,2016,23024,Butler, MO,1FADP3E29GL279830,Ford,FocusSedan
1302,7900,2007,66628,Des Moines, IA,3FAHP07157R225057,Ford,Fusion4dr
1303,6989,2004,101877,Bradenton, FL,1FMZU67K34UB76038,Ford,Explorer
1304,22981,2014,60280,Conroe, TX,2FMDK3AK0EBA90118,Ford,EdgeSport
1305,11800,2005,109399,Brentwood, CA,1FTPX14535FA34475,Ford,F-1504WD
1306,23995,2014,55472,Weatherford, TX,1ZVBP8FF3E5247468,Ford,MustangConvertible
1307,28490,2017,13872,Homestead, FL,1FA6P8CF8H5227333,Ford,MustangGT
1308,26950,2015,29734,Hurst, TX,2FMPK3AP6FBB66161,Ford,EdgeSport
1309,22995,2013,59774,Collinsville, IL,1FM5K8F87DGA20895,Ford,ExplorerLimited
1310,29995,2017,13038,Plantation, FL,1FTYR2XMXHKA53916,Ford,Transit
1311,19842,2011,41675,Oconomowoc, WI,1FTBF2B61BEA15620,Ford,Super
1312,27995,2015,25076,Doylestown, PA,1FM5K8D82FGC56599,Ford,ExplorerXLT
1313,11995,2010,106000,Topeka, KS,1FAHP2KT6AG102564,Ford,Taurus4dr
1314,21988,2013,73437,Sacramento, CA,1FM5K7F80DGC06580,Ford,ExplorerLimited
1315,13495,2005,103310,Chandler, AZ,1FTRX02W55KD04456,Ford,F-1502WD
1316,27500,2014,29302,Westmont, IL,1FM5K8F83EGB50111,Ford,ExplorerLimited
1317,24871,2013,40538,Kansas City, MO,1FTFW1EF4DFB62891,Ford,F-150SuperCrew
1318,31205,2017,15453,Tucson, AZ,1FA6P8CF0H5247981,Ford,MustangGT
1319,17999,2002,56124,Puyallup, WA,1FTNX20FX2EA43946,Ford,Super
1320,29995,2015,37585,Blackwood, NJ,1FT8X3B62FEC65211,Ford,Super
1321,6995,2004,155745,Franklin, TN,1FMFU17L24LA03423,Ford,Expedition5.4L
1322,25888,2014,43774,Riverhead, NY,1ZVBP8CF6E5295681,Ford,MustangCoupe
1323,22466,2011,70149,Cumming, GA,1FMHK8F87BGA75115,Ford,Explorer4WD
1324,31700,2016,26229,Sarasota, FL,1FM5K7F88GGD15082,Ford,ExplorerFWD
1325,7790,2010,142852,Houston, TX,2FMGK5DC7ABA66485,Ford,Flex4dr
1326,8995,2013,71819,Visalia, CA,1FADP3F27DL309336,Ford,FocusSedan
1327,28700,2015,50946,Huntville, AL,1FTFX1EFXFFA66425,Ford,F-1504WD
1328,16998,2014,88054,Barrington, IL,2FMHK6C89EBD04236,Ford,FlexSEL
1329,28500,2015,27136,Louisville, KY,1FA6P8CF5F5348222,Ford,MustangFastback
1330,6410,2010,93297,Naples, FL,1FAHP3HN2AW180683,Ford,Focus4dr
1331,6590,2008,123854,Terre Haute, IN,3FAHP07118R135826,Ford,Fusion4dr
1332,31995,2017,20230,Sebring, FL,1FTEW1CG0HFA12368,Ford,F-150XLT
1333,17977,2007,94161,Raleigh, NC,1FTPW14V17FA18023,Ford,F-1504WD
1334,12195,2012,90054,Fresno, CA,1FMCU0DG4CKC18617,Ford,EscapeFWD
1335,27992,2014,54172,Bloomington, MN,1FTFW1ETXEFC08571,Ford,F-1504WD
1336,33899,2017,20017,Houston, TX,1FM5K7F8XHGB23180,Ford,ExplorerFWD
1337,5827,2012,129096,Valdosta, GA,1FAHP3J24CL109344,Ford,Focus4dr
1338,31996,2015,410,Pikeville, KY,1FA6P8CF5F5313809,Ford,MustangFastback
1339,33899,2017,22908,Tucson, AZ,1FM5K7F88HGB16535,Ford,ExplorerFWD
1340,28237,2014,59912,Ballwin, MO,1FM5K8GTXEGA29403,Ford,ExplorerSport
1341,31480,2015,22317,Mandeville, LA,1FTEX1EP2FFA22598,Ford,F-1504WD
1342,24791,2012,74692,Maitland, FL,1FTFW1CF5CFC93572,Ford,F-1502WD
1343,14999,2009,166494,Puyallup, WA,1FTPW14V39FA10539,Ford,F-1504WD
1344,9745,2010,120907,Valencia, CA,2FMDK3JC9ABA36013,Ford,Edge4dr
1345,31499,2015,49943,Atlanta, GA,1FM5K8GT9FGC45390,Ford,ExplorerSport
1346,36000,2017,14303,Seekonk, MA,1FM5K8F89HGB81688,Ford,Explorer4WD
1347,26995,2006,49224,Honolulu, HI,1FTSX31P36EC98220,Ford,Super
1348,19500,2011,144658,Fountain, CO,1FTFW1EF0BFA42664,Ford,F-1504WD
1349,28798,2013,64277,Columbia, MO,1FTFW1ET3DKG49075,Ford,F-150SuperCrew
1350,15995,2013,58505,Conway, SC,1FMCU9G9XDUD57710,Ford,EscapeSE
1351,31495,2014,43505,Norman, OK,1FTFW1EFXEFC67999,Ford,F-1504WD
1352,25988,2014,22595,Fruitland Park, FL,2FMGK5D81EBD39504,Ford,FlexLimited
1353,33495,2014,31861,Kansas City, KS,1FTFW1EFXEKF25719,Ford,F-1504WD
1354,32995,2014,38611,Bel Air, MD,1FM5K8GT2EGB76699,Ford,ExplorerSport
1355,28000,2013,91765,Independence, MO,1FTFW1ET8DFA72584,Ford,F-150SuperCrew
1356,33970,2016,31301,League City, TX,1FTEW1CF5GKD01291,Ford,F-1502WD
1357,31996,2014,53332,Cumming, GA,1FTFW1ET6EFA86078,Ford,F-1504WD
1358,33995,2013,27752,San Luis Obispo, CA,1ZVBP8CU5D5251555,Ford,MustangCoupe
1359,34750,2013,34923,Nashville, TN,1FTFW1ET0DKD57576,Ford,F-150SuperCrew
1360,6481,2003,145287,Fayetteville, NC,1FMEU17L83LA46058,Ford,Expedition5.4L
1361,13951,2011,133000,Sioux Falls, SD,2FMDK4KC3BBA12067,Ford,Edge4dr
1362,29991,2015,76527,Orange City, FL,1FT7W2B62FEA48394,Ford,Super
1363,16989,2005,208570,Madison, NC,1FTSW21PX5EB53347,Ford,Super
1364,28800,2014,32185,Bloomington, IN,1FTFX1ET1EFC90137,Ford,F-1504WD
1365,38999,2015,17577,Kenosha, WI,1FTEW1EG1FFB20931,Ford,F-1504WD
1366,34995,2015,74191,Boyertown, PA,1FTEW1EG4FFC35264,Ford,F-1504WD
1367,38399,2015,57413,Longmont, CO,1FTEW1EF5FFA07443,Ford,F-1504WD
1368,19358,2008,47810,Lithia Springs, GA,1ZVHT82H585143713,Ford,Mustang2dr
1369,10995,2012,84964,Van Buren, AR,NM0LS7AN7CT090886,Ford,Transit
1370,37995,2016,34504,Gilbert, AZ,1FMJU1JT5GEF54098,Ford,Expedition4WD
1371,44911,2015,32154,Saint Augustine, FL,1FT8W3BT7FED56094,Ford,Super
1372,15200,2007,109715,Virgina Beach, VA,1FMFK20587LA00621,Ford,Expedition
1373,16995,2011,96936,Fairbanks, AK,1FMJU1J55BEF31446,Ford,Expedition4WD
1374,42998,2015,27724,Waldorf, MD,1FTEW1EG8FFB44952,Ford,F-1504WD
1375,32792,2014,44311,Grenada, MS,1FTFW1EF8EKE17194,Ford,F-1504WD
1376,15975,2011,60566,Gainesville, FL,2FMHK6DT7BBD24757,Ford,Flex4dr
1377,39500,2012,54124,Bethlehem, PA,1FTFW1R63CFB62485,Ford,F-1504WD
1378,24994,2012,26814,Union City, TN,1FT7X2B65CEB57439,Ford,Super
1379,38500,2013,46443,Cedar Falls, IA,1FT7W2B66DEB22607,Ford,Super
1380,30900,2013,32069,Fayetteville, NC,1FTFW1ETXDFB96422,Ford,F-150SuperCrew
1381,28998,2013,44464,West Bend, WI,1FTFW1EFXDFD24040,Ford,F-150SuperCrew
1382,23838,2009,80962,Beaumont, TX,1FTPW14V59FB31332,Ford,F-1504WD
1383,42000,2014,55430,Fenton, MI,1FT7W2BT7EEA58391,Ford,Super
1384,33550,2015,43030,Spartanburg, SC,1FTFW1EG9FFA09709,Ford,F-1504WD
1385,43995,2013,76928,Phoenix, AZ,1FTFW1R64DFC43528,Ford,F-150SuperCrew
1386,39995,2017,2617,Klamath Falls, OR,1FTEW1EFXHFA34852,Ford,F-150Platinum
1387,37991,2015,27935,Rockwall, TX,1FTEW1CPXFKE84825,Ford,F-1502WD
1388,42880,2013,59389,Thomasville, GA,1FT8W3DT0DEA30146,Ford,Super
1389,52995,2016,24671,Valdese, NC,1FT7W2BT4GEA87768,Ford,Super
1390,45988,2015,17323,Georgetown, TX,1FTFW1EG7FFC53360,Ford,F-1504WD
1391,49588,2016,21682,Leesburg, GA,1FTEW1EG3GFA18967,Ford,F-1504WD
1392,51528,2017,3498,Watertown, NY,1FTEW1EF2HFB47386,Ford,F-150XLT
1393,43887,2013,64303,Winston Salem, NC,1FT8W3BT5DEB03028,Ford,Super
1394,54489,2017,7806,Charlotte, NC,1FTEW1EG4HFA28232,Ford,F-150Lariat
1395,18294,2016,23884,Savannah, MO,2GKFLSEK7G6351002,GMC,TerrainAWD
1396,20000,2016,10461,Louisville, KY,2GKALMEK5G6249445,GMC,TerrainFWD
1397,14000,2011,56882,Schenectady, NY,2CTFLTEC0B6291877,GMC,TerrainAWD
1398,14500,2013,84346,Palm Harbor, FL,2GKFLWE38D6256380,GMC,TerrainFWD
1399,22695,2017,26582,Tucson, AZ,2GKALPEK1H6132576,GMC,TerrainFWD
1400,15120,2012,70924,Fruitland Park, FL,2GKALWEK4C6282958,GMC,TerrainFWD
1401,16990,2013,54716,Chillicothe, OH,2GKALSEK1D6217043,GMC,TerrainFWD
1402,10000,2010,122954,Independence, MO,2CTFLGEW9A6366088,GMC,TerrainAWD
1403,14795,2013,99075,Macon, GA,2GKALMEK6D6372599,GMC,TerrainFWD
1404,23783,2015,32310,Clinton, MO,2GKFLXE38F6279148,GMC,TerrainAWD
1405,10995,2006,86462,Billings, MT,1GTEC19X26Z197261,GMC,Sierra
1406,18750,2013,58483,Birch Run, MI,1GKKRNEDXDJ175139,GMC,AcadiaFWD
1407,17999,2014,86777,Bastrop, TX,1GKKRSKD4EJ263317,GMC,AcadiaFWD
1408,11100,2010,128325,Midlothian, VA,1GKLVMED1AJ118656,GMC,AcadiaAWD
1409,21158,2014,50206,Savannah, GA,1GKKRNED9EJ262497,GMC,AcadiaFWD
1410,18268,2011,61779,Beaumont, TX,1GKKRREDXBJ394770,GMC,AcadiaFWD
1411,10500,2010,158509,Ogden, UT,1GKLVMED9AJ177941,GMC,AcadiaAWD
1412,12988,2004,84884,Gladstone, OR,1GTEK19T54Z221243,GMC,Sierra
1413,27349,2016,35143,Loveland, CO,1GTH6CE32G1195819,GMC,CanyonExtended
1414,22988,2014,78249,Mt. Juliet, TN,1GKKVRKD7EJ121404,GMC,AcadiaAWD
1415,24995,2013,64184,Alcoa, TN,1GTR2VE70DZ310493,GMC,Sierra
1416,23575,2012,75129,Lockport, NY,3GTP2VE79CG265561,GMC,Sierra
1417,17998,2008,99222,White Bear Lake, MN,1GKFK66868J239373,GMC,Yukon
1418,22995,2012,113244,Fayetteville, AR,3GTP2VE73CG244639,GMC,Sierra
1419,25988,2012,68422,Orlando, FL,3GTP2XE28CG105803,GMC,Sierra
1420,16988,2006,95420,Boise, ID,1GKEC13Z76R151150,GMC,Yukon4dr
1421,23495,2012,104650,Houston, TX,3GTP2WE76CG242714,GMC,Sierra
1422,21000,2011,123931,Zedulon, NC,3GTP1XE28BG118987,GMC,Sierra
1423,25999,2014,84623,Rogers, MN,1GKKVTKD6EJ290360,GMC,AcadiaAWD
1424,33979,2015,36896,Denton, TX,3GTP1VEC7FG434173,GMC,Sierra
1425,23600,2010,94800,Danville, KY,1GKUKEEF9AR195152,GMC,YukonAWD
1426,37793,2014,28365,Elmhurst, IL,3GTU2WEJ5EG375644,GMC,Sierra
1427,35000,2014,65386,Independence, MO,3GTU2WEC5EG336604,GMC,Sierra
1428,40488,2016,37826,Cornelius, NC,3GTU2NEC4GG246440,GMC,Sierra
1429,37978,2014,47949,Katy, TX,3GTU2WEC5EG300962,GMC,Sierra
1430,43225,2017,2258,East Hartford, CT,1GKKNXLS1HZ124533,GMC,AcadiaAWD
1431,20888,2012,61783,Carlisle, PA,1GKKVRED7CJ127213,GMC,AcadiaAWD
1432,41444,2016,48755,Culpeper, VA,1GKS2BKC9GR126874,GMC,Yukon4WD
1433,24988,2014,76084,Friendswood, TX,1GKKRTKD4EJ352813,GMC,AcadiaFWD
1434,22017,2007,112433,Newport, NC,3GTEK13M97G524516,GMC,Sierra
1435,31991,2016,22997,Sugar Land, TX,1GTG6CE30G1172339,GMC,CanyonCrew
1436,19995,2007,89889,Naples, FL,1GKFK668X7J406378,GMC,Yukon
1437,48942,2015,37379,Columbia, KY,1GT423C82FF117003,GMC,Sierra
1438,49985,2017,20012,Addison, TX,1GKS2BKC6HR172373,GMC,Yukon4WD
1439,37425,2014,32160,Sioux Falls, SD,3GTU2VECXEG306380,GMC,Sierra
1440,49728,2015,54927,Northbrook, IL,1GT120E8XFF675762,GMC,Sierra
1441,60998,2016,11521,McKenna, WA,1GT42YE81GF281550,GMC,Sierra
1442,45774,2017,16175,Anaheim, CA,1GKS1BKC1HR132298,GMC,Yukon2WD
1443,55009,2015,46927,South Jordan, UT,1GKS2JKJ0FR593172,GMC,Yukon
1444,68225,2017,101,Lafayette, IN,1GKS2HKJ1HR360266,GMC,Yukon
1445,57988,2016,34675,Pasadena, TX,1GKS2HKJ3GR127486,GMC,Yukon
1446,13150,2014,18161,Dublin, OH,19XFB2F55EE265885,Honda,Civic
1447,14500,2015,13569,Pompton Plains, NJ,19XFB2F5XFE016936,Honda,Civic
1448,13500,2014,35688,Westminster, MD,19XFB2F59EE200943,Honda,Civic
1449,12795,2014,33820,Loma Linda, CA,2HGFB2F58EH543412,Honda,Civic
1450,12750,2014,22397,Louisville, KY,19XFB2F58EE247011,Honda,Civic
1451,13977,2015,22714,Manassas, VA,19XFB2F53FE208523,Honda,Civic
1452,14300,2014,29287,Hazelwood, MO,19XFB2F53EE060680,Honda,Civic
1453,15000,2015,26511,Peoria, IL,19XFB2F8XFE043869,Honda,Civic
1454,15975,2016,15411,North Salt Lake, UT,19XFC2F59GE034259,Honda,Civic
1455,12999,2014,47954,Centennial, CO,19XFB2F52EE012474,Honda,Civic
1456,13984,2014,31617,Valencia, CA,19XFB2F55EE267412,Honda,Civic
1457,12392,2013,59581,Prescott, AZ,19XFB2F5XDE078107,Honda,Civic
1458,15997,2015,38099,Harrisonburg, VA,2HGFB2F8XFH562491,Honda,Civic
1459,7995,2010,31710,Branford, CT,JHMZE2H50AS028714,Honda,Insight5dr
1460,13579,2015,57636,Memphis, TN,19XFB2F89FE069203,Honda,Civic
1461,13975,2014,51079,Cincinnati, OH,2HGFB2F59EH544648,Honda,Civic
1462,16000,2015,34190,Stockton, CA,2HGFB2F57FH534475,Honda,Civic
1463,11369,2012,41646,Colorado Springs, CO,2HGFG3B53CH560208,Honda,Civic
1464,7988,2012,64240,Lauderdale Lakes, FL,2HGFG3B84CH503249,Honda,Civic
1465,10988,2012,92207,Fort Collins, CO,2HGFB2F96CH540363,Honda,Civic
1466,19465,2016,15893,Hopkins, MN,19XFC1F75GE003022,Honda,Civic
1467,17990,2016,30271,Shreveport, LA,19XFC2F76GE016030,Honda,Civic
1468,8477,2008,76988,San Jose, CA,1HGFA46548L000032,Honda,Civic
1469,12995,2016,40469,Boise, ID,JHMGK5H79GX000229,Honda,FitEX
1470,18995,2017,3585,Orlando, FL,1HGCR2F30HA151024,Honda,Accord
1471,17500,2016,21169,Cathedral City, CA,1HGCR2F35GA138624,Honda,Accord
1472,16990,2015,12679,Manahawkin, NJ,1HGCR2F37FA260903,Honda,Accord
1473,19590,2016,24936,Sterling, VA,19XFC1F37GE017663,Honda,Civic
1474,13500,2015,35764,Houston, TX,3HGGK5G57FM719678,Honda,FitLX
1475,15600,2014,34048,Poughkeepsie, NY,1HGCR2F31EA071839,Honda,Accord
1476,21408,2017,4084,Corpus Christi, TX,1HGCR2F37HA181671,Honda,Accord
1477,15995,2014,30914,Houston, TX,1HGCR2F31EA200629,Honda,Accord
1478,17994,2014,11478,West Caldwell, NJ,1HGCR2F55EA149671,Honda,Accord
1479,17500,2014,18341,Cambridge, MA,1HGCR2F5XEA154431,Honda,Accord
1480,14900,2014,34452,Rahway, NJ,1HGCR2F78EA255291,Honda,Accord
1481,15577,2014,68678,Sandy, UT,19XFB2F97EE082328,Honda,Civic
1482,18738,2017,14038,Montclair, CA,1HGCR2F37HA026571,Honda,Accord
1483,10950,2013,69857,Grand Prairie, TX,2HGFG3B81DH523069,Honda,Civic
1484,16345,2015,25667,Allentown, PA,1HGCR2F57FA275015,Honda,Accord
1485,13995,2015,41550,Orlando, FL,3HGGK5H81FM709866,Honda,FitEX-L
1486,14995,2014,33338,Bronx, NY,1HGCR3F86EA040703,Honda,Accord
1487,14500,2015,33113,Pasadena, CA,3HGGK5H56FM722912,Honda,FitLX
1488,7495,2008,114433,El Paso, TX,1HGFA16518L036694,Honda,Civic
1489,16000,2016,11709,Los Angeles, CA,JHMGK5H56GS008364,Honda,FitLX
1490,12988,2013,43383,Vero Beach, FL,1HGCR2F32DA159006,Honda,Accord
1491,14986,2014,38769,Langhorne, PA,1HGCR2F37EA215152,Honda,Accord
1492,16995,2016,35890,Duluth, GA,1HGCR2F33GA051658,Honda,Accord
1493,14900,2014,28198,Yonkers, NY,1HGCR2F38EA290314,Honda,Accord
1494,17816,2014,16435,Sterling, VA,1HGCR2F38EA266613,Honda,Accord
1495,7490,2010,115000,Farmers Branch, TX,19XFA1F31AE036240,Honda,Civic
1496,21991,2017,6,Schaumburg, IL,2HGFC1F33HH651513,Honda,Civic
1497,9400,2012,107598,Hazlet, NJ,19XFB2F55CE324365,Honda,Civic
1498,16997,2015,21028,Fort Worth, TX,1HGCR2F36FA256373,Honda,Accord
1499,6991,2008,110387,Cockeysville, MD,1HGFA168X8L085757,Honda,Civic
1500,18589,2015,20241,American Fork, UT,1HGCR2F36FA211689,Honda,Accord
1501,14588,2015,60495,Westport, CT,1HGCR2F36FA048574,Honda,Accord
1502,9000,2014,108526,Chesapeake, VA,JHMZF1D64ES000006,Honda,CR-ZEX
1503,14999,2015,35056,Miami, FL,1HGCR2F58FA065460,Honda,Accord
1504,16941,2015,22805,Durham, NC,1HGCR2F36FA038613,Honda,Accord
1505,17500,2016,16689,Florence, KY,1HGCR2F37GA109027,Honda,Accord
1506,14995,2013,34255,Duluth, GA,1HGCR2F38DA145286,Honda,Accord
1507,15251,2013,23445,Schaumburg, IL,1HGCR2F84DA083414,Honda,Accord
1508,15445,2015,41587,Las Vegas, NV,1HGCR2F33FA222200,Honda,Accord
1509,7981,2009,116717,Austin, TX,2HGFA16539H518615,Honda,Civic
1510,10747,2012,57843,North Huntingdon, PA,2HGFG3B8XCH557297,Honda,Civic
1511,14981,2014,36347,Fort Lauderdale, FL,1HGCR2F56EA290698,Honda,Accord
1512,12977,2015,45442,Greenfield, MA,3HGGK5H5XFM752561,Honda,FitLX
1513,18447,2017,1400,Temecula, CA,JHMGK5H8XHS016544,Honda,FitEX-L
1514,5495,2007,113411,Jourdanton, TX,1HGFA16887L090356,Honda,Civic
1515,14653,2015,55990,Norman, OK,1HGCR2F31FA175135,Honda,Accord
1516,20000,2016,21587,Boone, NC,19XFC1F76GE020380,Honda,Civic
1517,14477,2013,42707,Covington, KY,1HGCR2E56DA060448,Honda,Accord
1518,12862,2015,57147,Wichita, KS,3HGGK5H87FM713761,Honda,FitEX
1519,10650,2014,105015,Salt Lake City, UT,1HGCR2F30EA048536,Honda,Accord
1520,5500,2006,117992,Palatine, IL,1HGFA16836L028782,Honda,Civic
1521,15000,2014,47632,McKinney, TX,1HGCR2F32EA273248,Honda,Accord
1522,9697,2012,61243,Sanford, FL,JHMGE8H57CC024134,Honda,FitAutomatic
1523,11994,2014,29727,Seffner, FL,1HGCT1B3XEA014766,Honda,Accord
1524,13897,2014,48753,Lafayette, IN,2HGFG3B88EH526746,Honda,Civic
1525,14700,2015,32532,Pasadena, CA,3HGGK5H89FM709016,Honda,FitEX
1526,15000,2015,19007,College Park, MD,3HGGK5H58FM716464,Honda,FitLX
1527,15980,2016,24218,Augusta, ME,JHMGK5H75GX032921,Honda,FitEX
1528,16000,2014,16113,Louisville, KY,1HGCR2F38EA232526,Honda,Accord
1529,13995,2014,40274,Miami Gardens, FL,1HGCR2F55EA299523,Honda,Accord
1530,15278,2014,34646,Cartersville, GA,1HGCR2F38EA273951,Honda,Accord
1531,19181,2016,18169,St. Augustine, FL,1HGCR2F3XGA237407,Honda,Accord
1532,10795,2009,65289,Ankeny, IA,1HGCP26849A023338,Honda,Accord
1533,12652,2013,42591,Roseville, CA,JHMGE8H50DC032030,Honda,FitSport
1534,7995,2011,83183,Hampstead, MD,2HGFG1B66BH519521,Honda,Civic
1535,12997,2012,49052,Augusta, GA,1HGCP2F67CA009973,Honda,Accord
1536,22852,2017,1000,San Antonio, TX,19XFC1F39HE021070,Honda,Civic
1537,17500,2015,19140,Clermont, FL,1HGCR2F78FA089842,Honda,Accord
1538,13799,2013,34150,Roswell, GA,1HGCR2F39DA146933,Honda,Accord
1539,9500,2011,82870,Jacksonville, FL,1HGCP2F33BA019114,Honda,Accord
1540,16235,2015,32581,Springfield, PA,1HGCR2F33FA018805,Honda,Accord
1541,13995,2014,36834,Nashville, TN,2HGFG3B86EH526373,Honda,Civic
1542,5995,2006,100509,Stafford, VA,1HGFA16556L045475,Honda,Civic
1543,7421,2007,102304,Salt Lake City, UT,2HGFA16597H505414,Honda,Civic
1544,18756,2015,34063,Wilkes-Barre, PA,2HGFB6E56FH702714,Honda,Civic
1545,7333,2008,102501,North Huntingdon, PA,2HGFA16888H331444,Honda,Civic
1546,15900,2014,31113,Pompton Plains, NJ,1HGCR2F32EA176373,Honda,Accord
1547,5995,2007,88555,Nicholasville, KY,2HGFG12847H518442,Honda,Civic
1548,13900,2016,35783,El Cerrito, CA,3HGGK5H54GM700764,Honda,FitLX
1549,14986,2014,33850,Berwyn, IL,1HGCR2F35EA259117,Honda,Accord
1550,16800,2014,6718,Long Island City, NY,1HGCR2F3XEA285714,Honda,Accord
1551,15995,2014,48967,Ewing, NJ,2HGFB6E54EH705299,Honda,Civic
1552,14999,2014,28411,Miami Gardens, FL,1HGCR2F51EA288941,Honda,Accord
1553,8998,2012,112964,Saint Charles, IL,1HGCP2F31CA197475,Honda,Accord
1554,15981,2015,40067,Jacksonville, FL,1HGCR2F33FA248215,Honda,Accord
1555,13995,2014,50054,Hackettstown, NJ,1HGCR2E56EA272638,Honda,Accord
1556,16955,2014,17770,Langhorne, PA,1HGCR2F32EA306054,Honda,Accord
1557,17682,2015,15014,Concord, NC,1HGCR2F31FA154527,Honda,Accord
1558,16443,2015,26957,Mesa, AZ,1HGCR2F32FA066666,Honda,Accord
1559,15999,2014,35379,Highland Park, IL,1HGCR2F58EA167226,Honda,Accord
1560,14900,2014,30325,Rahway, NJ,1HGCR2F85EA234472,Honda,Accord
1561,16495,2015,31035,McDonough, GA,1HGCR2F30FA077066,Honda,Accord
1562,14990,2015,25592,Philadelphia, PA,3HGGK5H52FM743465,Honda,FitLX
1563,17896,2015,17826,Loma Linda, CA,1HGCR2E73FA169860,Honda,Accord
1564,11500,2010,48887,Estero, FL,1HGCP2F7XAA164071,Honda,Accord
1565,6797,2008,108871,Hampstead, MD,2HGFA16578H523766,Honda,Civic
1566,15400,2014,27259,Phoenix, AZ,2HGFG3B82EH524510,Honda,Civic
1567,15000,2015,24838,Tampa, FL,3HGGK5H8XFM703791,Honda,FitEX
1568,15000,2014,35868,Highland, IN,1HGCR2F37EA189880,Honda,Accord
1569,16000,2014,31998,Bellevue, NE,1HGCR2F39EA194854,Honda,Accord
1570,13988,2014,45275,Baltimore, MD,1HGCR2F36EA293440,Honda,Accord
1571,14991,2015,49723,Aurora, CO,1HGCR2F37FA205898,Honda,Accord
1572,16795,2014,10575,Tenafly, NJ,1HGCR2F59EA229829,Honda,Accord
1573,15695,2015,38246,Duarte, CA,1HGCR2F32FA134464,Honda,Accord
1574,18900,2015,23373,Hermosa Beach, CA,19XFB5F56FE000220,Honda,Civic
1575,17500,2015,22567,Bellevue, NE,1HGCR2F32FA198987,Honda,Accord
1576,16499,2015,19744,West Park, FL,1HGCR2F38FA061293,Honda,Accord
1577,15990,2014,13701,Costa Mesa, CA,2HGFG3B02EH508303,Honda,Civic
1578,16332,2015,37015,Franklin, TN,1HGCR2F35FA269664,Honda,Accord
1579,17787,2016,22677,Yorkville, NY,1HGCR2F3XGA173174,Honda,Accord
1580,5220,2006,111622,Monroeville, PA,1HGFA15596L122320,Honda,Civic
1581,15968,2016,18036,North Richland Hills, TX,JHMGK5H78GS005628,Honda,FitEX
1582,16000,2014,37330,Lexington, SC,1HGCR2F31EA247062,Honda,Accord
1583,15300,2014,42596,Hazelwood, MO,1HGCR2F30EA289254,Honda,Accord
1584,15663,2015,18973,Davie, FL,3HGGK5H81FM757304,Honda,FitEX-L
1585,17890,2015,18337,Ventura, CA,1HGCR2F31FA000142,Honda,Accord
1586,6488,2008,135006,Springfield, FL,1HGFA16578L075743,Honda,Civic
1587,15991,2015,35766,Riverside, CA,1HGCR2F54FA125265,Honda,Accord
1588,9694,2012,74643,Birmingham, AL,2HGFG3B53CH544297,Honda,Civic
1589,14777,2014,28398,Florence, KY,2HGFG3B82EH526970,Honda,Civic
1590,8990,2012,94306,North Hampton, NH,1HGCP2F36CA066154,Honda,Accord
1591,13988,2016,44644,Swanzey, NH,JHMGK5H59GX000066,Honda,FitLX
1592,16595,2015,19629,Burbank, IL,1HGCR2F35FA008373,Honda,Accord
1593,7500,2008,70884,Branford, CT,2HGFG11608H537037,Honda,Civic
1594,15786,2014,25406,DAYTON, OH,1HGCR2F33EA125013,Honda,Accord
1595,18590,2016,3987,Cincinnati, OH,2HGFC4B0XGH305616,Honda,Civic
1596,7765,2009,70816,North Lauderdale, FL,2HGFG12899H537782,Honda,Civic
1597,15823,2013,60000,Austin, TX,2HGFB6E59DH701554,Honda,Civic
1598,15499,2014,32231,Spartanburg, SC,1HGCR2F53EA178960,Honda,Accord
1599,16192,2014,65097,Hurricane, WV,2HGFB6E59EH702513,Honda,Civic
1600,8995,2012,73726,Reno, NV,JHMGE8H33CC023383,Honda,FitAutomatic
1601,16000,2014,35785,Frisco, TX,1HGCR2F32EA254389,Honda,Accord
1602,15498,2014,34469,El Monte, CA,1HGCR2F37EA207407,Honda,Accord
1603,15000,2013,35380,Delaware, OH,1HGCR2F37DA273129,Honda,Accord
1604,15611,2014,30867,RESEDA, CA,1HGCR2F38EA221512,Honda,Accord
1605,19970,2016,30043,Brookfield, WI,19XFC1F87GE039075,Honda,Civic
1606,14998,2014,39964,Gardena, CA,1HGCR2F31EA286265,Honda,Accord
1607,17595,2016,32610,Duluth, GA,1HGCR2F37GA070178,Honda,Accord
1608,8390,2009,66958,Alpharetta, GA,1HGCP26359A196841,Honda,Accord
1609,17430,2015,17745,Nanuet, NY,1HGCR2F57FA095503,Honda,Accord
1610,17545,2015,21307,Davie, FL,1HGCR2F33FA003656,Honda,Accord
1611,17988,2016,8013,Manassas, VA,2HGFC4B00GH304572,Honda,Civic
1612,16991,2016,4287,San Francisco, CA,JHMGK5H5XGX040835,Honda,FitLX
1613,10911,2012,73877,Chicago, IL,1HGCP2F34CA002176,Honda,Accord
1614,13995,2014,53352,Valdese, NC,1HGCR2F3XEA249604,Honda,Accord
1615,13997,2014,42477,Marietta, GA,1HGCR2F33EA127814,Honda,Accord
1616,15825,2015,42996,Winston Salem, NC,1HGCR2F55FA045053,Honda,Accord
1617,20495,2017,1485,Orlando, FL,1HGCR2F51HA163135,Honda,Accord
1618,15495,2014,39889,Hopkins, MN,1HGCR2F77EA091676,Honda,Accord
1619,16533,2015,28520,Spartanburg, SC,1HGCR2F52FA262334,Honda,Accord
1620,12995,2011,40217,Orlando, FL,5KBCP3F84BB004176,Honda,Accord
1621,16500,2014,29753,Frederick, MD,1HGCR2F73EA236194,Honda,Accord
1622,9995,2008,51047,Tinley Park, IL,1HGCP36818A050761,Honda,Accord
1623,15481,2014,36630,Roseville, CA,1HGCR2F31EA294401,Honda,Accord
1624,14997,2015,50372,McDonough, GA,1HGCR2F39FA215347,Honda,Accord
1625,20999,2016,17751,Wait Park, MN,19XFC1F85GE042377,Honda,Civic
1626,9997,2012,70062,Harrisburg, PA,2HGFG3B51CH549420,Honda,Civic
1627,10719,2010,60335,Duluth, GA,2HGFG1B81AH511407,Honda,Civic
1628,17950,2016,8534,Lawndale, CA,2HGFC4B03GH301231,Honda,Civic
1629,17500,2014,27131,San Antonio, TX,1HGCR2F78EA204289,Honda,Accord
1630,17250,2015,20553,Eatontown, NJ,1HGCR2F77FA051454,Honda,Accord
1631,14649,2015,54612,Lakeville, MN,1HGCR2F77FA189916,Honda,Accord
1632,20000,2016,39301,Cathedral City, CA,2HGFC1F30GH636501,Honda,Civic
1633,6645,2009,128942,Paramus, NJ,2HGFA16869H336045,Honda,Civic
1634,15999,2014,31619,Whittier, CA,1HGCR2F38EA272721,Honda,Accord
1635,16498,2014,24636,Loves Park, IL,1HGCR2F5XEA054037,Honda,Accord
1636,16988,2014,28244,Yuba City, CA,1HGCR2F39EA053623,Honda,Accord
1637,15900,2014,31953,Countryside, IL,1HGCR2F58EA168666,Honda,Accord
1638,14480,2014,39254,Hoover, AL,1HGCR2F30EA261521,Honda,Accord
1639,18500,2016,40600,Pompano Beach, FL,19XFC1F97GE012810,Honda,Civic
1640,9540,2013,71969,Middletown, NY,JHMGE8H51DC052240,Honda,FitSport
1641,14798,2014,35453,Carrollton, TX,1HGCR2F32EA168239,Honda,Accord
1642,17277,2015,29804,Salisbury, MD,1HGCR2F7XFA068877,Honda,Accord
1643,14700,2015,31612,BROCKTON, MA,3HGGK5H85FM774574,Honda,FitEX
1644,17000,2015,35266,Brooklyn Park, MN,1HGCR2E55FA146708,Honda,Accord
1645,15934,2014,33302,Jamaica, NY,1HGCR2F54EA243802,Honda,Accord
1646,7292,2006,90854,Delavan, WI,1HGFA16516L046283,Honda,Civic
1647,18993,2016,24389,Brooklyn Center, MN,1HGCR2F54GA099266,Honda,Accord
1648,16500,2016,8352,Louisville, KY,JHMGK5H74GX035406,Honda,FitEX
1649,12750,2014,60195,Union City, GA,1HGCR2F3XEA104336,Honda,Accord
1650,6358,2007,118053,Las Vegas, NV,1HGFA15587L125159,Honda,Civic
1651,14000,2014,45150,Mt. Prospect, IL,1HGCR2F34EA300353,Honda,Accord
1652,19409,2016,11938,Highland Park, IL,2HGFC3B70GH352242,Honda,Civic
1653,15000,2015,17837,Louisville, KY,3HGGK5H8XFM704245,Honda,FitEX
1654,12094,2013,66714,Milwaukee, WI,1HGCR2F37DA122825,Honda,Accord
1655,9991,2010,75622,Jacksonville, FL,1HGCP2F34AA024157,Honda,Accord
1656,13668,2015,36275,Jacksonville, FL,3HGGK5H56FM726913,Honda,FitLX
1657,9990,2012,76278,North Palm Beach, FL,1HGCP2F61CA008804,Honda,Accord
1658,14997,2015,41793,Daphne, AL,1HGCR2F31FA228111,Honda,Accord
1659,16900,2015,30118,Hemet, CA,1HGCR2F31FA167326,Honda,Accord
1660,15500,2014,27163,Elizabeth, NJ,1HGCR2F54EA052350,Honda,Accord
1661,6987,2008,94001,Sarasota, FL,2HGFG12658H516067,Honda,Civic
1662,9500,2012,99175,Plainfield, IN,1HGCP2F38CA109246,Honda,Accord
1663,18359,2016,19766,Cocoa, FL,1HGCR2F40GA061024,Honda,Accord
1664,15492,2014,42846,Reno, NV,1HGCR2F34EA158067,Honda,Accord
1665,17288,2014,26184,Torrance, CA,1HGCR2F33EA265692,Honda,Accord
1666,14994,2014,33274,Pelham, AL,1HGCR2F38EA286666,Honda,Accord
1667,17995,2016,24175,Clinton, NC,1HGCR2F30GA098422,Honda,Accord
1668,17979,2014,40320,Indianapolis, IN,2HGFB6E52EH705947,Honda,Civic
1669,14994,2014,33297,Seffner, FL,1HGCR2F71EA290884,Honda,Accord
1670,15750,2014,24200,Harrisburg, NC,1HGCR2F31EA251175,Honda,Accord
1671,18498,2016,18209,Dover, DE,1HGCR2F38GA063420,Honda,Accord
1672,16888,2015,37000,Port Richey, FL,1HGCR2F54FA008138,Honda,Accord
1673,18377,2015,13187,Roswell, GA,1HGCR2F54FA149324,Honda,Accord
1674,15473,2015,50862,Temecula, CA,1HGCR2F33FA176867,Honda,Accord
1675,15691,2014,36344,Signal Hill, CA,1HGCR2F33EA244910,Honda,Accord
1676,14500,2014,48150,Eatontown, NJ,1HGCR2F54EA294068,Honda,Accord
1677,6988,2006,114239,Escondido, CA,1HGFA16806L108590,Honda,Civic
1678,8999,2012,125586,Mechanicsburg, PA,19XFB2F88CE320708,Honda,Civic
1679,4999,2008,134966,Fredericksburg, VA,2HGFA16548H519237,Honda,Civic
1680,19999,2016,32950,San Angelo, TX,2HGFC1F76GH633136,Honda,Civic
1681,15995,2014,32554,Shelton, CT,1HGCR2F32EA010970,Honda,Accord
1682,11495,2013,43316,Middleton, OH,JHMGE8H39DC078583,Honda,FitAutomatic
1683,19997,2017,9051,Cookeville, TN,1HGCR2F36HA004707,Honda,Accord
1684,14995,2016,26991,Blackwood, NJ,JHMGK5H77GX010001,Honda,FitEX
1685,16264,2014,29850,Jamaica, NY,1HGCR2F72EA304548,Honda,Accord
1686,18774,2016,20952,Salisbury, MD,1HGCR2F32GA035399,Honda,Accord
1687,7900,2005,48516,Boulder, CO,1HGEM22605L046796,Honda,Civic
1688,14275,2014,42390,North Salt Lake, UT,1HGCR2F31EA161315,Honda,Accord
1689,6981,2010,88096,Austin, TX,JHMGE8G21AS007260,Honda,Fit5dr
1690,15986,2014,33656,Las Vegas, NV,1HGCR2F30EA132856,Honda,Accord
1691,14999,2014,70212,Franklin, TN,2HGFB2F91EH516829,Honda,Civic
1692,13500,2014,64750,Princeton, NJ,1HGCR2F75EA220644,Honda,Accord
1693,16690,2015,35917,Norwood, MA,1HGCR2F51FA020263,Honda,Accord
1694,17250,2015,37395,Dublin, OH,1HGCR2F58FA161590,Honda,Accord
1695,9696,2011,71778,Greenvale, NY,1HGCP2F66BA139404,Honda,Accord
1696,13995,2014,42235,Rio Linda, CA,2HGFG3B85EH523173,Honda,Civic
1697,15599,2015,29627,Riverside, CA,2HGFG3B57FH528513,Honda,Civic
1698,5995,2007,114551,Philadelphia, PA,1HGFA16847L007652,Honda,Civic
1699,10480,2012,67410,Pleasanton, CA,2HGFG3B87CH543535,Honda,Civic
1700,7990,2012,133531,Yorktown, VA,19XFB2F81CE059101,Honda,Civic
1701,8900,2012,90020,Ephrata, PA,1HGCP2F42CA076298,Honda,Accord
1702,18688,2015,4198,Swanzey, NH,2HGFG4A57FH705469,Honda,Civic
1703,10995,2014,82020,Meriden, CT,1HGCR2F38EA255630,Honda,Accord
1704,17843,2015,19258,Edmonds, WA,1HGCR2F36FA057534,Honda,Accord
1705,16999,2016,3054,Farmington, NM,JHMGK5H7XGS008465,Honda,FitEX
1706,11000,2012,82340,Frisco, TX,1HGCP2F60CA243615,Honda,Accord
1707,15348,2015,38838,Yonkers, NY,1HGCR2F34FA055832,Honda,Accord
1708,6750,2006,83011,Dublin, OH,2HGFG11686H579744,Honda,Civic
1709,17991,2016,4560,Jacksonville, Fl,JHMGK5H74GS015167,Honda,FitEX
1710,10769,2013,61992,Milwaukie, OR,JHMGE8H31DC083244,Honda,FitAutomatic
1711,10988,2013,52412,Jackson, TN,JHMGE8H54DC051194,Honda,FitSport
1712,15277,2014,30566,Alpharetta, GA,1HGCR2F57EA059874,Honda,Accord
1713,16000,2014,31684,Marion, IL,1HGCR2F39EA206789,Honda,Accord
1714,16989,2015,27792,Union, NJ,1HGCR2F77FA089492,Honda,Accord
1715,8900,2012,105282,Georgetown, TX,JHMZE2H78CS000129,Honda,InsightCVT
1716,18981,2016,9212,Concord, NC,2HGFC4B08GH304643,Honda,Civic
1717,17750,2016,18002,Olive Branch, MS,1HGCR2F33GA118341,Honda,Accord
1718,9996,2012,97859,Harrisburg, PA,1HGCP2F32CA237286,Honda,Accord
1719,5990,2007,114784,Tampa, FL,1HGFA15517L125116,Honda,Civic
1720,9000,2009,45692,Bow, NH,JHMGE87489S040012,Honda,Fit5dr
1721,11997,2013,45873,Reading, PA,JHMGE8G54DC031190,Honda,FitSport
1722,13500,2013,39820,Austin, TX,2HGFG3B84DH506718,Honda,Civic
1723,16999,2015,40619,Warrenton, VA,1HGCR2F76FA027260,Honda,Accord
1724,12999,2014,58101,Miami Gardens, FL,1HGCR2F76EA229580,Honda,Accord
1725,4900,2004,92165,West Allis, WI,JHMES95664S007688,Honda,CivicManual
1726,17491,2014,10642,Renton, WA,1HGCR2F31EA116018,Honda,Accord
1727,6995,2007,102776,Baton Rouge, LA,1HGFA16507L027225,Honda,Civic
1728,17973,2015,31528,Omaha, NE,2HGFG4A54FH701458,Honda,Civic
1729,14880,2014,45295,Covington, LA,1HGCR2F7XEA112892,Honda,Accord
1730,16768,2014,36060,Baltimore, MD,1HGCR2F52EA184667,Honda,Accord
1731,6575,2009,90009,Atlanta, GA,2HGFG12889H512212,Honda,Civic
1732,18694,2015,37266,Cockeysville, MD,2HGFB6E50FH703826,Honda,Civic
1733,19500,2016,10556,Jacksonville, NC,1HGCR2F33GA132840,Honda,Accord
1734,15500,2014,45599,Frisco, TX,1HGCR2F38EA117456,Honda,Accord
1735,16980,2015,28073,Mobile, AL,1HGCR2F31FA036283,Honda,Accord
1736,19995,2016,7894,Dallas, TX,1HGCR2F35GA041035,Honda,Accord
1737,15497,2015,58903,Brooklyn Center, MN,1HGCR2F76FA061165,Honda,Accord
1738,11290,2010,64927,Torrance, CA,1HGCP2F3XAA130824,Honda,Accord
1739,18988,2016,17818,Raleigh, NC,1HGCR2F38GA162402,Honda,Accord
1740,14988,2014,34288,Union City, GA,1HGCR2F55EA265260,Honda,Accord
1741,17365,2016,36971,Rock Hill, SC,1HGCR2F38GA118383,Honda,Accord
1742,15000,2014,34158,Louisville, KY,1HGCR2F3XEA215145,Honda,Accord
1743,15250,2015,15885,Louisville, KY,3HGGK5H82FM718768,Honda,FitEX
1744,14850,2012,28196,Webster, TX,1HGCP2F42CA232825,Honda,Accord
1745,12783,2014,76618,Lansing, IL,1HGCR2F36EA111719,Honda,Accord
1746,13999,2015,27963,Longwood, FL,3HGGK5H82FM752189,Honda,FitEX
1747,16000,2015,34960,Brunswick, GA,1HGCR2F39FA219091,Honda,Accord
1748,10349,2010,61452,Livonia, MI,1HGCP2F41AA042852,Honda,Accord
1749,16399,2014,28332,Shelton, CT,1HGCR2F35EA179137,Honda,Accord
1750,15000,2013,44880,Naples, FL,1HGCR2F50DA047886,Honda,Accord
1751,16500,2015,37181,Cincinnati, OH,1HGCR2F3XFA055334,Honda,Accord
1752,7990,2012,105424,San Jose, CA,JHMZE2H3XCS001943,Honda,InsightCVT
1753,14975,2014,34608,North Salt Lake, UT,1HGCR2F30EA149513,Honda,Accord
1754,17500,2014,14038,Brooklyn, NY,1HGCR2F58EA256472,Honda,Accord
1755,5299,2009,145382,Owasso, OK,1HGFA165X9L012461,Honda,Civic
1756,6988,2008,111766,Jacksonville, FL,2HGFA16598H356486,Honda,Civic
1757,15440,2014,42888,Selma, TX,1HGCR2F58EA244144,Honda,Accord
1758,16495,2015,29112,Brooklyn, NY,1HGCR2F52FA005836,Honda,Accord
1759,15700,2015,20465,Countryside, IL,3HGGK5H81FM713402,Honda,FitEX
1760,20488,2016,18014,San Rafael, CA,2HGFC1F46GH652520,Honda,Civic
1761,14994,2014,34451,Seffner, FL,1HGCR2F85EA091586,Honda,Accord
1762,16900,2014,28276,Dallas, TX,1HGCR2F34EA264454,Honda,Accord
1763,22450,2016,16041,Fayetteville, NC,19XFC1F99GE031102,Honda,Civic
1764,17578,2015,24953,Davie, FL,1HGCR2F58FA136463,Honda,Accord
1765,14498,2014,40469,Scottsdale, AZ,1HGCR2F36EA171077,Honda,Accord
1766,17991,2015,25913,Peoria, AZ,1HGCR2F7XFA006041,Honda,Accord
1767,16795,2015,32391,Shelton, CT,1HGCR2F34FA074770,Honda,Accord
1768,17850,2015,16995,Reading, PA,1HGCR2F36FA059171,Honda,Accord
1769,8277,2009,88609,Cockeysville, MD,1HGCP26329A042359,Honda,Accord
1770,19444,2015,33309,East Stroudsburg, PA,2HGFB6E50FH703714,Honda,Civic
1771,12500,2011,56765,Bellevue, WA,1HGCP2F66BA141069,Honda,Accord
1772,16989,2015,35211,Sanford, FL,1HGCR2F58FA253914,Honda,Accord
1773,19573,2015,30423,Prescott, AZ,2HGFB6E5XFH710122,Honda,Civic
1774,16600,2014,44732,Wichita Falls, TX,1HGCR2F51EA264400,Honda,Accord
1775,14977,2015,42495,Akron, OH,2HGFG3B07FH518147,Honda,Civic
1776,18985,2015,24612,Golden Valley, MN,1HGCR2F88FA177461,Honda,Accord
1777,16923,2015,33100,Birmingham, AL,1HGCR2F34FA088183,Honda,Accord
1778,20191,2017,2168,RESEDA, CA,2HGFC3B36HH360165,Honda,Civic
1779,16598,2014,31985,Raynham, MA,1HGCR2F53EA127684,Honda,Accord
1780,7995,2005,47663,Toms River, NJ,1HGCM56765A023837,Honda,Accord
1781,11400,2010,43534,Louisville, KY,1HGCP2F70AA009836,Honda,Accord
1782,8999,2007,38271,Cambridge, MA,JHMGD38467S031293,Honda,Fit5dr
1783,17111,2015,29484,Delray Beach, FL,1HGCR2F53FA214373,Honda,Accord
1784,17500,2015,32400,Cathedral City, CA,1HGCR2F32FA128230,Honda,Accord
1785,16495,2014,33649,New Braunfels, TX,1HGCR2F53EA059015,Honda,Accord
1786,16999,2015,15603,Draper, UT,2HGFG4A59FH706011,Honda,Civic
1787,8000,2010,101575,Prescott, AZ,2HGFG1B63AH523847,Honda,Civic
1788,8499,2007,58045,Concord, NC,2HGFG11867H577056,Honda,Civic
1789,8999,2012,132136,Kingsport, TN,19XFB2F53CE006308,Honda,Civic
1790,17481,2015,23468,Fort Worth, TX,1HGCR2F36FA253067,Honda,Accord
1791,15597,2014,48015,Westport, CT,1HGCR2F32EA242016,Honda,Accord
1792,18988,2015,8502,Cincinnati, OH,1HGCR2F37FA156962,Honda,Accord
1793,17659,2014,18107,West Caldwell, NJ,1HGCR2F5XEA129142,Honda,Accord
1794,15987,2014,40702,Dawsonville, GA,1HGCR2E57EA307185,Honda,Accord
1795,16000,2014,33667,Tampa, FL,1HGCR2F70EA161972,Honda,Accord
1796,17999,2016,28353,Whittier, CA,1HGCR2F35GA005359,Honda,Accord
1797,14995,2016,28117,Victor, NY,3HGGK5G77GM702849,Honda,FitEX
1798,11094,2010,65183,Port Richey, FL,1HGCP2F42AA142734,Honda,Accord
1799,9100,2009,59848,McKinney, TX,JHMGE87499S038673,Honda,Fit5dr
1800,11882,2011,56322,Leesburg, FL,1HGCP2F45BA048607,Honda,Accord
1801,17000,2015,26693,Hazelwood, MO,1HGCR2F58FA050778,Honda,Accord
1802,15777,2015,34024,Arlington, VA,1HGCR2F36FA011363,Honda,Accord
1803,14990,2015,35883,Bend, OR,3HGGK5H51FM707265,Honda,FitLX
1804,20300,2017,1398,DAYTON, OH,1HGCR2F37HA082056,Honda,Accord
1805,15887,2014,72336,Richardson, TX,2HGFB6E59EH703452,Honda,Civic
1806,13495,2013,70244,Lees Summit, MO,1HGCR2F35DA010699,Honda,Accord
1807,17319,2014,22282,Annapolis, MD,1HGCR2F51EA235527,Honda,Accord
1808,14495,2014,49803,Hackettstown, NJ,1HGCR2F59EA294650,Honda,Accord
1809,14900,2014,36065,Elizabeth, NJ,1HGCR2F50EA243005,Honda,Accord
1810,14750,2015,22612,Louisville, KY,3HGGK5H89FM713776,Honda,FitEX
1811,15787,2015,17744,Irvine, CA,3HGGK5H82FM747574,Honda,FitEX-L
1812,14995,2014,34948,Austin, TX,1HGCR2F35EA151385,Honda,Accord
1813,16400,2014,28129,Alexandria, VA,1HGCR2F33EA164751,Honda,Accord
1814,6995,2010,93331,Newhall, CA,2HGFG1B68AH537744,Honda,Civic
1815,16250,2014,33836,Norwood, MA,1HGCR2F53EA056079,Honda,Accord
1816,13680,2011,70438,Mount Laurel, NJ,2HGFA5E57BH702454,Honda,Civic
1817,11900,2010,45193,Harmarville, PA,1HGCP2F35AA007206,Honda,Accord
1818,16980,2015,34235,Augusta, ME,1HGCR2F85FA107416,Honda,Accord
1819,14441,2015,36151,Olive Branch, MS,3HGGK5H87FM707734,Honda,FitEX
1820,15793,2014,42530,Rockwall, TX,1HGCR2F5XEA292700,Honda,Accord
1821,17160,2015,28107,Springfield, PA,1HGCR2F35FA087978,Honda,Accord
1822,18000,2016,33606,Frisco, TX,1HGCR2F30GA091213,Honda,Accord
1823,8894,2011,125705,Indianapolis, IN,19XFA1F50BE045921,Honda,Civic
1824,17500,2014,23211,Branford, CT,1HGCR2F56EA246569,Honda,Accord
1825,13248,2015,68285,North Richland Hills, TX,2HGFG3B8XFH505690,Honda,Civic
1826,19695,2015,25245,Glendale, CA,2HGFB6E58FH708658,Honda,Civic
1827,15950,2014,40979,Citrus Heights, CA,1HGCR2F74EA199186,Honda,Accord
1828,8999,2013,74834,Rancho Cucamonga, CA,JHMGE8H36DC031138,Honda,FitAutomatic
1829,9900,2013,65254,Charlotte, NC,JHMGE8H37DC068098,Honda,FitAutomatic
1830,17999,2016,26203,Fort Worth, TX,1HGCR2F31GA138801,Honda,Accord
1831,16203,2013,54363,Sandusky, OH,2HGFB6E57DH707708,Honda,Civic
1832,15277,2014,39782,Nanuet, NY,1HGCR2F31EA096661,Honda,Accord
1833,13806,2013,47263,Bow, NH,1HGCR2F36DA235908,Honda,Accord
1834,19063,2016,26289,Burlingame, CA,1HGCR2F39GA008040,Honda,Accord
1835,16500,2014,35359,Branford, CT,1HGCR2F5XEA237261,Honda,Accord
1836,18750,2016,35715,Virginia Beach, VA,1HGCR2F58GA153037,Honda,Accord
1837,17982,2016,15701,Miami, FL,1HGCR2F32GA005576,Honda,Accord
1838,10998,2011,54940,Austin, TX,JHMGE8H64BS007773,Honda,Fit5dr
1839,16698,2014,36862,Brooklyn Center, MN,1HGCR2F58EA283302,Honda,Accord
1840,9866,2012,76708,Williamsburg, VA,JHMGE8H52CS002998,Honda,FitAutomatic
1841,5991,2009,148961,Union City, GA,2HGFA16959H502533,Honda,Civic
1842,18000,2014,49121,Frisco, TX,2HGFB6E54EH705268,Honda,Civic
1843,16293,2014,36168,Norman, OK,1HGCR2F54EA262544,Honda,Accord
1844,15900,2014,34717,Laurel, MD,1HGCR2F37EA252444,Honda,Accord
1845,15978,2015,48678,Chandler, AZ,1HGCR2F35FA019163,Honda,Accord
1846,19795,2017,13785,Oakland, CA,1HGCR2F34HA019173,Honda,Accord
1847,15852,2014,24253,Miami, FL,1HGCR2F34EA285398,Honda,Accord
1848,11995,2013,62816,Marietta, GA,1HGCR2F51DA232626,Honda,Accord
1849,9995,2013,73473,Escondido, CA,JHMGE8H30DC002489,Honda,FitAutomatic
1850,18900,2016,15300,Auburn, AL,1HGCR2F34GA156015,Honda,Accord
1851,16600,2014,15981,Fredricksburg, VA,1HGCR2F89EA284890,Honda,Accord
1852,12380,2015,53170,Bloomfield, NJ,3HGGK5H57FM727486,Honda,FitLX
1853,20000,2016,5724,Denver, CO,1HGCR2F36GA078613,Honda,Accord
1854,16900,2016,6551,El Cerrito, CA,JHMGK5H75GX022034,Honda,FitEX
1855,15700,2014,41893,Avondale, AZ,1HGCR2F34EA287989,Honda,Accord
1856,17500,2015,65609,Cathedral City, CA,19XFB2F99FE207802,Honda,Civic
1857,14500,2015,35399,Houston, TX,3HGGK5H83FM717645,Honda,FitEX
1858,12299,2013,55647,Montclair, CA,2HGFG4A57DH701662,Honda,Civic
1859,16309,2013,30162,Naples, FL,1HGCR2F84DA125418,Honda,Accord
1860,18500,2015,11136,Houston, TX,1HGCR2F36FA266188,Honda,Accord
1861,6500,2009,112927,Holiday, FL,1HGCP26449A174953,Honda,Accord
1862,12650,2012,57289,San Jose, CA,1HGCP2F37CA059472,Honda,Accord
1863,15999,2015,46769,Freehold, NJ,1HGCR2F51FA114580,Honda,Accord
1864,20988,2016,27523,Raleigh, NC,19XFC2F77GE014318,Honda,Civic
1865,15242,2015,41041,Montclair, CA,1HGCR2F38FA009954,Honda,Accord
1866,15199,2014,32963,Hialeah, FL,1HGCR2F57EA211118,Honda,Accord
1867,16899,2014,25703,Cincinnati, OH,1HGCR2F54EA252192,Honda,Accord
1868,7948,2010,85548,South Portland, ME,2HGFG1B8XAH526133,Honda,Civic
1869,12210,2014,63991,Jersey City, NJ,2HGFG3B50EH515245,Honda,Civic
1870,14984,2015,29242,Henderson, NV,3HGGK5H57FM723275,Honda,FitLX
1871,17104,2014,17565,Coral Springs, FL,1HGCR3F81EA040219,Honda,Accord
1872,17488,2015,28611,Raleigh, NC,1HGCR2F37FA003109,Honda,Accord
1873,5950,2010,86161,Carmel, IN,JHMGE8G4XAS015181,Honda,Fit5dr
1874,4980,2010,115129,Pacoima, CA,JHMZE2H51AS035283,Honda,Insight5dr
1875,18999,2015,38270,Davis, CA,2HGFB6E58FH708742,Honda,Civic
1876,15299,2014,31856,Miami, FL,1HGCR2F50EA114066,Honda,Accord
1877,11477,2012,93250,Sterling, VA,2HGFB6E58CH703827,Honda,Civic
1878,17988,2015,22635,Raleigh, NC,1HGCR2F36FA150960,Honda,Accord
1879,9016,2007,57175,Tallahassee, FL,1HGCM664X7A070500,Honda,Accord
1880,16988,2015,40671,Hampton, VA,1HGCR2F34FA060061,Honda,Accord
1881,15800,2014,39479,Pompton Plains, NJ,1HGCR2F72EA075871,Honda,Accord
1882,14999,2014,31948,West Covina, CA,2HGFG4A51EH701416,Honda,Civic
1883,15977,2015,31586,Beaverton, OR,3HGGK5H88FM737969,Honda,FitEX
1884,17513,2015,28832,Kansas City, KS,1HGCR2F51FA221662,Honda,Accord
1885,16500,2014,24262,McKinney, TX,1HGCR2F33EA279737,Honda,Accord
1886,18750,2016,27919,Forest City, NC,19XFC1F34GE004210,Honda,Civic
1887,19490,2016,16990,Chicago, IL,19XFC1F74GE001679,Honda,Civic
1888,13988,2015,18298,Lilburn, GA,3HGGK5H53FM729753,Honda,FitLX
1889,7253,2009,107594,Cincinnati, OH,1HGFA16569L019245,Honda,Civic
1890,15307,2015,24109,Fredericksburg, VA,3HGGK5H86FM722693,Honda,FitEX
1891,13591,2015,67494,Columbus, OH,1HGCR2F34FA039131,Honda,Accord
1892,15495,2014,23197,Frankfort, KY,1HGCR2F3XEA030464,Honda,Accord
1893,19500,2015,29989,Fayetteville, NC,2HGFB6E51FH705763,Honda,Civic
1894,14988,2015,16107,Swanzey, NH,3HGGK5G87FM731792,Honda,FitEX
1895,14895,2014,37669,Loma Linda, CA,1HGCR2F35EA286916,Honda,Accord
1896,6995,2010,88719,Hackettstown, NJ,2HGFG1B65AH504815,Honda,Civic
1897,9695,2012,100680,BRODHEADSVILLE, PA,2HGFB2F87CH306899,Honda,Civic
1898,17291,2015,45625,San Rafael, CA,2HGFB2F91FH547810,Honda,Civic
1899,13987,2015,42265,Baltimore, MD,1HGCR2F30FA130140,Honda,Accord
1900,15092,2014,28276,RESEDA, CA,1HGCR2F38EA220442,Honda,Accord
1901,12770,2013,18905,Emmaus, PA,JHMGE8H51DC007136,Honda,FitSport
1902,15500,2015,52826,Westminster, MD,1HGCR2F7XFA068863,Honda,Accord
1903,13500,2015,70687,Manassas, VA,1HGCR2F71FA016991,Honda,Accord
1904,17298,2015,12758,Cary, NC,1HGCR2F38FA030643,Honda,Accord
1905,19250,2016,16087,Virginia Beach, VA,1HGCR2F56GA193021,Honda,Accord
1906,15218,2014,28719,Columbus, MS,1HGCR2F36EA285337,Honda,Accord
1907,17400,2015,22194,McKinney, TX,1HGCR2F30FA165969,Honda,Accord
1908,15395,2014,23017,Merrillville, IN,2HGFG3B83EH526265,Honda,Civic
1909,7991,2011,84172,Irving, TX,2HGFG1B60BH518817,Honda,Civic
1910,15735,2014,25663,Davie, FL,1HGCR2F36EA232878,Honda,Accord
1911,10899,2012,56552,Avon, IN,2HGFG3B81CH517867,Honda,Civic
1912,16148,2014,17538,Columbus, MS,1HGCR2F35EA243306,Honda,Accord
1913,16000,2014,26476,Hazelwood, MO,1HGCR2F33EA293962,Honda,Accord
1914,16788,2014,20533,Westport, CT,1HGCR2F39EA282187,Honda,Accord
1915,16188,2015,13377,North Plainfield, NJ,1HGCR2F3XFA059402,Honda,Accord
1916,15709,2015,35065,Riverdale, UT,1HGCR2F3XFA008675,Honda,Accord
1917,10295,2013,87632,Lexington, KY,1HGCR2F34DA187034,Honda,Accord
1918,15000,2014,22627,Louisville, KY,1HGCR2F35EA037984,Honda,Accord
1919,14950,2012,17629,Chattanooga, TN,1HGCP2F41CA188090,Honda,Accord
1920,17700,2015,18506,Saco, ME,1HGCR2F59FA118912,Honda,Accord
1921,17999,2016,13825,Fort Worth, TX,1HGCR2F34GA103900,Honda,Accord
1922,15900,2014,63723,Cathedral City, CA,19XFB2F90EE045198,Honda,Civic
1923,15990,2016,28009,Plantation, FL,1HGCR2F30GA128695,Honda,Accord
1924,13997,2014,35749,Freehold, NJ,1HGCR2F3XEA264622,Honda,Accord
1925,15997,2014,32918,Brooklyn Center, MN,1HGCR2F57EA246788,Honda,Accord
1926,12488,2012,52417,Grapevine, TX,2HGFG3B86CH524278,Honda,Civic
1927,13999,2014,34822,Miami, FL,1HGCR2F51EA196096,Honda,Accord
1928,17200,2014,15100,Colorado Springs, CO,1HGCR2F38EA006728,Honda,Accord
1929,18784,2016,36602,Concord, NC,19XFC1F35GE027608,Honda,Civic
1930,16213,2014,24348,Germantown, MD,1HGCR2F34EA270724,Honda,Accord
1931,7999,2009,71340,Denville, NJ,1HGCP26449A174418,Honda,Accord
1932,13995,2014,34912,Bethlehem, PA,1HGCR2F30EA176310,Honda,Accord
1933,13184,2012,43439,Mesa, AZ,1HGCP2F33CA099192,Honda,Accord
1934,13750,2015,22607,Louisville, KY,3HGGK5H59FM731507,Honda,FitLX
1935,9300,2012,74286,Carmel, IN,1HGCP2F70CA092509,Honda,Accord
1936,14886,2015,53585,Lemon Grove, CA,1HGCR2F39FA242046,Honda,Accord
1937,12999,2014,46022,Mesa, AZ,2HGFG3B52EH506921,Honda,Civic
1938,14988,2014,36306,Garden Grove, CA,JHMZF1D62ES000943,Honda,CR-ZEX
1939,12695,2015,59235,Doral, FL,1HGCR2E52FA114072,Honda,Accord
1940,14400,2015,18882,Los Angeles, CA,3HGGK5H57FM740044,Honda,FitLX
1941,14499,2014,28917,Stanton, CA,1HGCR2F58EA047278,Honda,Accord
1942,15232,2015,45290,Norman, OK,1HGCR2F30FA008832,Honda,Accord
1943,11985,2012,43876,Roslyn, PA,1HGCP2F33CA237135,Honda,Accord
1944,21595,2016,6435,Batavia, OH,2HGFC1F43GH657996,Honda,Civic
1945,14573,2015,23117,Cary, NC,3HGGK5H5XFM726672,Honda,FitLX
1946,14812,2015,49382,Reno, NV,1HGCR2F32FA003082,Honda,Accord
1947,6990,2010,117560,Raleigh, NC,19XFA1E6XAE028875,Honda,Civic
1948,14865,2015,25410,Hopkins, MN,3HGGK5H84FM754610,Honda,FitEX
1949,16300,2016,37541,Cincinnati, OH,1HGCR2F33GA190995,Honda,Accord
1950,9990,2013,51500,Abilene, TX,JHMGE8H33DC059821,Honda,FitAutomatic
1951,11300,2012,68407,Frederick, MD,1HGCP2F34CA099637,Honda,Accord
1952,21000,2017,9751,Phoenix, AZ,19XFC1F74HE006740,Honda,Civic
1953,19976,2017,4098,Portland, OR,1HGCR2F37HA052913,Honda,Accord
1954,15112,2014,38466,Germantown, MD,1HGCR2F38EA301831,Honda,Accord
1955,16398,2014,25433,Roseville, CA,1HGCR2F36EA252693,Honda,Accord
1956,18891,2016,12613,Roseville, CA,1HGCR2F32GA211786,Honda,Accord
1957,13995,2014,35774,Philadelphia, PA,1HGCR2F34EA292254,Honda,Accord
1958,16791,2015,18252,Signal Hill, CA,2HGFG3B86FH524382,Honda,Civic
1959,14826,2014,34843,Chicago, IL,1HGCR2F35EA257819,Honda,Accord
1960,16430,2015,23108,Nanuet, NY,1HGCR2F35FA059288,Honda,Accord
1961,13986,2014,35904,Windsor Locks, CT,1HGCR2F30EA229376,Honda,Accord
1962,14897,2015,51747,Chester, VA,2HGFG3B84FH514532,Honda,Civic
1963,14000,2014,35749,Lancaster, PA,1HGCR2F35EA038388,Honda,Accord
1964,16695,2016,31961,Orlando, FL,1HGCR2F32GA090449,Honda,Accord
1965,15499,2015,36130,Wait Park, MN,1HGCR2F39FA004262,Honda,Accord
1966,11998,2013,51287,Roswell, GA,1HGCR2F3XDA164793,Honda,Accord
1967,13977,2015,35978,Gulfport, MS,3HGGK5H50FM723635,Honda,FitLX
1968,20799,2016,20089,Killeen, TX,19XFC1F92GE022418,Honda,Civic
1969,15990,2015,20443,South Easton, MA,1HGCR2F35FA021317,Honda,Accord
1970,14999,2016,40979,West Palm Beach, FL,1HGCR2F38GA005811,Honda,Accord
1971,10995,2012,51242,Somerville, MA,2HGFG3B50CH522855,Honda,Civic
1972,16995,2014,9603,Brooklyn, NY,1HGCR2F78EA274178,Honda,Accord
1973,10872,2009,44644,Nashua, NH,1HGCP26459A187565,Honda,Accord
1974,17250,2015,37893,Norcross, GA,2HGFB6E56FH700249,Honda,Civic
1975,11389,2014,73932,Sanford, FL,1HGCR2F33EA179654,Honda,Accord
1976,13994,2014,35971,Seffner, FL,1HGCR2F57EA235029,Honda,Accord
1977,15300,2014,42061,Slidell, LA,1HGCR2F3XEA142391,Honda,Accord
1978,16160,2015,31340,Newport News, VA,1HGCR2F39FA038654,Honda,Accord
1979,11935,2013,43146,Warrenville, SC,JHMGE8G52DC002223,Honda,FitSport
1980,12999,2013,19452,Franklin, TN,JHMGE8H35DC082369,Honda,FitAutomatic
1981,12399,2012,51288,Corona, CA,1HGCP2F68CA203928,Honda,Accord
1982,21787,2017,7647,North Richland Hills, TX,2HGFC1F32HH630118,Honda,Civic
1983,15999,2014,19308,Bedford, OH,1HGCR2F39EA199147,Honda,Accord
1984,15100,2014,35854,Poughkeepsie, NY,1HGCR2F32EA306426,Honda,Accord
1985,15999,2014,21685,Bristol, WI,1HGCR2F39EA256575,Honda,Accord
1986,15641,2016,16651,North Richland Hills, TX,JHMGK5H76GX002505,Honda,FitEX
1987,14500,2015,14644,Louisville, KY,3HGGK5H58FM711569,Honda,FitLX
1988,13590,2014,41919,Bronx, NY,1HGCR2F54EA205938,Honda,Accord
1989,6450,2007,100259,Plano, TX,1HGFA16517L137099,Honda,Civic
1990,21794,2016,8068,Dixon, CA,19XFC1F35GE205100,Honda,Civic
1991,14522,2014,40538,Columbus, MS,1HGCR2F3XEA285437,Honda,Accord
1992,15890,2015,52323,Chantilly, VA,1HGCR2F57FA055230,Honda,Accord
1993,16889,2015,25266,Temecula, CA,1HGCR2F39FA153075,Honda,Accord
1994,8491,2012,89367,Garden Grove, CA,2HGFG3B58CH551780,Honda,Civic
1995,14495,2014,31083,Decatur, GA,1HGCR2F53EA128754,Honda,Accord
1996,15994,2014,35043,Newport News, VA,1HGCR2F75EA253093,Honda,Accord
1997,14860,2015,21972,Springfield, PA,3HGGK5H53FM713553,Honda,FitLX
1998,18500,2016,8860,Clermont, FL,2HGFC4B03GH313346,Honda,Civic
1999,14999,2014,32493,Morrow, GA,1HGCR2F50EA131501,Honda,Accord
2000,18555,2016,9235,Bristol, WI,1HGCR2F30GA086772,Honda,Accord`